import axios from "axios";
import React, { useEffect, useState } from "react";

const CommissionAmountCalculator = (props) => {
  const [CommissionData, setCommissionData] = useState();

  const GetCommission = async () => {
    await axios
      .post(`commission/getBxiCommissionByOrderId`, {
        OrderId: props?.OrderData?.OrderId,
      })
      .then((res) => {
        setCommissionData(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetCommission();
  }, [props]);

  let invoiceTotalAmount =
    CommissionData?.Commission?.CommissionPrice +
    CommissionData?.Commission?.CommissionGST +
    props?.OrderData?.INRDetails?.TDS;

  return <div>{invoiceTotalAmount ? invoiceTotalAmount.toFixed(2) : "--"}</div>;
};

export default CommissionAmountCalculator;
