import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../Company/Company.Modules.css";
import { Delete, Edit } from "@mui/icons-material";
import { Divider, IconButton, Modal, Button, Typography } from "@mui/material";
import FigmaIcon from "../../assets/figma-icon.png";
import CategoryDropdown from "../Layouts/CategoryDropdown";
import BxiCoinIcon from "../../assets/BXI-coin-icon.svg";
import BXIToken from "../../assets/BXIToken.svg";
import CommonDelete from "../CommonActions/CommonDelete";
import { useParams } from "react-router-dom";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import { GetTokenFunc } from "../../utils/GetToken";
import CompanyTypeComp from "../AllRecords/CompanyTypeComp";
import Searchbar from "../../common/Searchbar";

const Wishlist = () => {
  const { id } = useParams();
  const [ids, setIds] = useState();
  const [Wishlist, setWishlist] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchData, setSearchData] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const wishlistData = async () => {
    await axios
      .get(`/api/v1/admin/GetAllWishlistOfCompany/${id}?search=${searchData}`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setWishlist(res.data);
        setLoader(false);
      })
      .catch((err) => {});
  };
  const DeleteWishList = async () => {
    await axios
      .delete(`/api/v1/admin/AdminDeleteWishlist/${id}?productId=${ids}`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        wishlistData();
      })
      .catch((err) => {});
  };
  useEffect(() => {
    wishlistData();
    setLoader(true);
  }, [searchData]);

  const handleDelete = () => {
    // Implement your delete logic here
    DeleteWishList();
    setIsDeleteModalOpen(false);
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div>
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px" }}
      >
        <Box className="page-heading">
          <h5>Wishlist</h5>
          <Typography>
            Here is a list of all the products that the user Wishlisted.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box className="products-filters-row">
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              Total Products : {Wishlist?.ProductLength}
            </Typography>
          </Box>
          <Searchbar onSendData={handleChildData} />
        </Box>
        <div>
          <table className="common-table">
            {loader ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 5,
                  width: "100%",
                  // mr: '150%',
                  ml: "500px",
                }}
              >
                <PageLoader />
              </Box>
            ) : (
              <>
                {Wishlist?.wishArray?.length <= 0 ? (
                  <Box sx={setBox}>
                    <img
                      src={recyclebin}
                      alt="recycle"
                      style={{ height: "auto", width: "23%" }}
                    />
                    <Typography sx={empty}>Data is empty</Typography>
                  </Box>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <td width={"20%"} align="left">
                          Product Name
                        </td>
                        <td width={"10%"}>Company Name</td>
                        <td width={"10%"}>Category</td>
                        <td width={"10%"}>Location</td>
                        <td width={"10%"}>Quantity</td>
                        <td width={"10%"}>Amount</td>
                        <td width={"10%"}> Date</td>
                        <td width={"10%"}>Type</td>
                        <td width={"10%"}>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Wishlist?.wishArray?.map((x, i) => {
                        return (
                          <tr>
                            <td align="left" className="product-title-colum">
                              <img
                                src={
                                  x?.ProductImages?.at(0)?.url ||
                                  x?.VoucherImages?.at(0)?.url
                                }
                                alt=""
                                style={{ width: "35px", height: "35px" }}
                              />
                              <Box className="product-title-box">
                                <label>{x?.ProductName}</label>
                                <span>Product ID : #{x?._id} </span>
                              </Box>
                            </td>
                            <td
                              className="font-12-400"
                              style={{ color: "rgba(27, 33, 45, 1)" }}
                            >
                              {x?.SellerCompanyName}{" "}
                            </td>
                            <td
                              className="font-12-400"
                              style={{ color: "rgba(27, 33, 45, 1)" }}
                            >
                              {x?.ProductCategoryName ? (
                                x?.ProductCategoryName
                              ) : (
                                <CompanyTypeComp
                                  companyTypeId={x?.ProductType}
                                />
                              )}
                            </td>

                            <td className="font-12-400">
                              {x?.LocationDetails?.city
                                ? x?.LocationDetails?.city
                                : x?.City
                                ? x?.City
                                : "--"}
                            </td>
                            <td className="font-12-400">
                              {x?.ProductsVariantions.at(0)?.MaxOrderQuantity}
                            </td>
                            <td className="font-12-400">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "5px",
                                }}
                              >
                                <img
                                  src={BXIToken}
                                  alt=""
                                  style={{ height: "18px", width: "18px" }}
                                />
                                {x?.ProductsVariantions?.at(
                                  0
                                )?.PricePerUnit?.toFixed(2)}
                              </Box>
                            </td>
                            <td className="font-12-400">
                              {" "}
                              {new Date(x?.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  timeZone: "UTC",
                                }
                              )}
                            </td>
                            <td className="font-12-400">
                              {x?.ListingType ? x?.ListingType : "--"}
                            </td>
                            <td>
                              <IconButton aria-label="delete" style={iconStyle}>
                                <Delete
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setIds(x?._id);
                                  }}
                                />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </>
            )}
          </table>
        </div>
      </Box>
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={handleDelete}
        text="Are you sure you want to delete this Wishlist item?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
    </div>
  );
};

export default Wishlist;
const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const DeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ModalHeaderStyle = {
  color: "#6B7A99",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  my: 2,
};

const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  mt: 2,
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};
