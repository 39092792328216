import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Divider, Paper } from "@mui/material";
import * as Styles from "../../common/Styles";
import { useGetSignzyData } from "../../Hooks/ExternalApiData/useGetExternalApiData";
import PageLoader from "../CommonActions/PageLoader";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import Searchbar from "../../common/Searchbar";

function SignzyData() {
  const navigate = useNavigate();

  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const {
    data: getSignzyData,
    isError: getSignzyDataError,
    isLoading: getSignzyDataLoading,
    refetch: getSignzyDataRefetch,
  } = useGetSignzyData(currentPage, searchData);

  useEffect(() => {
    if (getSignzyData && getSignzyData?.totalPages !== undefined) {
      setInitialPaginationCount(getSignzyData?.totalPages);
    }
  }, [getSignzyData?.totalPages]);

  useEffect(() => {
    getSignzyDataRefetch();
  }, [currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/allexternalapi");
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <Box className="page-heading">
            <h5>All Signzy(s) Data</h5>
            <Typography>
              View all company onboarding status as per Signzy verification and
              approval.
            </Typography>
          </Box>
        </Box>
        <Divider />{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "97%",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              Total Company :{getSignzyData?.totalCount || 0}
            </Typography>
          </Box>
          <Searchbar onSendData={handleChildData} />
        </Box>
        <Box
          sx={{
            background: "rgba(250, 251, 252, 1)",
            p: 2,
            mt: 2,
            width: "100%",
          }}
        >
          <Box
            className="products-filters-row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: getSignzyDataLoading
                ? "transparent"
                : "rgba(250, 251, 252, 1)",
              borderRadius: 5,
            }}
          >
            {getSignzyDataLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  ml: "500px",
                  mt: 20,
                }}
              >
                <PageLoader />
              </Box>
            ) : (
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      p: 2,
                    }}
                  >
                    <Grid
                      item
                      xl={0.5}
                      lg={0.5}
                      md={0.5}
                      sm={0.5}
                      xs={0.5}
                      sx={Styles.textHead}
                    >
                      No.
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={4}
                      sx={{
                        ...Styles.textHead,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      Company Name
                    </Grid>
                    <Grid
                      align="center"
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      sx={Styles.textHead}
                    >
                      Category
                    </Grid>
                    <Grid
                      align="center"
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      sx={Styles.textHead}
                    >
                      Date
                    </Grid>

                    <Grid
                      align="center"
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      sx={Styles.textHead}
                    >
                      Status
                    </Grid>
                    <Grid
                      align="center"
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={Styles.textHead}
                    >
                      View
                    </Grid>
                  </Grid>
                  {/* <Divider /> */}

                  {getSignzyData?.allSignzyDetails?.map((x, i) => {
                    return (
                      <Grid
                        container
                        sx={{
                          p: 2,
                          mt: 0,
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid rgba(245, 245, 245, 1)",
                        }}
                        key={x}
                      >
                        <Grid
                          item
                          xl={0.5}
                          lg={0.5}
                          md={0.5}
                          sm={0.5}
                          xs={0.5}
                          sx={Styles.textHead}
                        >
                          {i + 1}
                        </Grid>
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                          sx={{
                            ...headText,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {x?.CompanyName ? x?.CompanyName : "--"}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{ ...Styles.textHead }}
                        >
                          {x?.Category ? x?.Category : "--"}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{ ...Styles.textHead }}
                        >
                          {formatDate(x?.Date ? x?.Date : "--")}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{ ...Styles.textHead }}
                        >
                          <Box
                            sx={{
                              background:
                                x?.Status === "ONBOARDING_COMPLETE"
                                  ? "#D9FFE9"
                                  : x?.Status === "PAYMENT"
                                  ? "#FFF1E5"
                                  : x?.Status === "TNC"
                                  ? "#FFF1E5"
                                  : x?.Status === "UNDER_REVIEW"
                                  ? "#FFEFEF"
                                  : "#FFEFEF",
                              borderRadius: 1.5,
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  x?.Status === "ONBOARDING_COMPLETE"
                                    ? "var(--Green-2, #27AE60)"
                                    : x?.Status === "PAYMENT"
                                    ? "#F2994A"
                                    : x?.Status === "TNC"
                                    ? "#F2994A"
                                    : x?.Status === "UNDER_REVIEW"
                                    ? "var(--Red, #EB5757)"
                                    : "var(--Red, #EB5757)",
                                fontFamily: "poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                fontStyle: "normal",
                                lineHeight: "30px",
                              }}
                            >
                              {x?.Status === "ONBOARDING_COMPLETE"
                                ? "Onboarding Completed"
                                : x?.Status === "PAYMENT"
                                ? "payment"
                                : x?.Status === "TNC"
                                ? "terms & co."
                                : x?.Status === "UNDER_REVIEW"
                                ? "Attempt"
                                : "Attempt"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={Styles.textHead}
                        >
                          <Visibility
                            onClick={() => {
                              navigate(
                                `/admindashboard/companylanding/${x?._id}`,
                                {
                                  state: {
                                    Signzy: true,
                                  },
                                }
                              );
                            }}
                            sx={{
                              cursor: "pointer",
                              color: "#445FD2",
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: {
                        xl: "100%",
                        lg: "100%",
                        md: "100%",
                        sm: "100%",
                        xs: "100%",
                      },
                      mx: "auto",
                    }}
                    mt={2}
                  >
                    <Stack>
                      <StyledPagination
                        size="medium"
                        count={initialPaginationCount}
                        color="primary"
                        showFirstButton
                        showLastButton
                        page={currentPage}
                        onChange={handlePageChange}
                        strokeWidth={currentPage}
                      />
                    </Stack>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default SignzyData;

const headText = {
  color: "var(--text-color-text-1, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};
const buddge = {
  display: "inline-flex",
  height: 40,
  padding: "16px 20px 16px 15px",
  justifyContent: "center",
  alignItems: "center",
  gap: 0,
  borderRadius: 10,
  border: "1px solid #E6E9EE",
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
