import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Dialog,
  Button,
  Paper,
  Modal,
} from "@mui/material";
import "./Adminhandles.Modules.css";
import { Delete, Edit, Password } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  useFetchAdminMember,
  useDeleteAdminMembetr,
  useUpdateAdminMember,
  useUpdateAdminMemberPassword,
} from "./Hooks";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ADMIN_ROLES, ADMIN_ACCESS } from "./Admin_Roles";
import "./Adminhandles.Modules.css";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import { Divider } from "@material-ui/core";
// import { Button } from "@mui/base";
import { toast, ToastContainer } from "react-toastify";
import Searchbar from "../../common/Searchbar";
const Memberdetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [rightsArr, setRigthtsArr] = React.useState([]);
  const [adminUserId, setAdminUserId] = React.useState(null);
  const [adminUser, setAdminUser] = React.useState({});
  const [openPAssword, setOpenPAssword] = React.useState(false);
  const [searchData, setSearchData] = useState("");
  const [UpdatePassWordData, setUpdatePasswordData] = React.useState({});

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handleClosePasswordModal = () => {
    setOpenPAssword(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const {
    data: AdminMemberData,
    isLoading: AdminMemberLoading,
    refetch: adminMemberRefetch,
  } = useFetchAdminMember(searchData);
  const {
    mutate: deleteAdminMutate,
    isLoading: deletLoading,
    isError: deleteAdminError,
  } = useDeleteAdminMembetr();
  const {
    mutate: AdminUpdateMutate,
    isError,
    isLoading,
  } = useUpdateAdminMember();
  const {
    mutate: AdminUpdatePassMutate,
    isError: UpdatePassError,
    isLoading: UpdatePassLoading,
  } = useUpdateAdminMemberPassword();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        email: z.string().email(),
        role: z.string().min(3),
        rights: z.string().optional(2),
      })
    ),
  });

  const FetchSingleUser = async (id) => {
    await axios
      .get(`/api/v1/admin/admin-manage/${id}`, {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setValue("email", res?.data?.user?.email);
        setValue("role", res?.data?.user?.role);
        setRigthtsArr(res?.data?.user?.acess);
        setAdminUser(res?.data?.user);
      })
      .catch((err) => {});
  };
  const UpdateAdminMember = () => {
    AdminUpdateMutate(
      {
        email: getValues()?.email,
        role: getValues()?.role,
        acess: rightsArr,
        id: adminUser._id,
      },
      {
        onSuccess: (res) => {
          adminMemberRefetch();
          handleClose();
          setAdminUser({});
        },
        onError: () => (err) => {},
      }
    );
  };

  const UpdateAdminPass = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(UpdatePassWordData?.password)) {
      return toast.error(
        "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    if (UpdatePassWordData?.password !== UpdatePassWordData?.confirmpassword) {
      return toast.error("Password and Confirm Password must be same", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    AdminUpdatePassMutate(
      {
        password: UpdatePassWordData?.password,
        id: UpdatePassWordData?.id,
      },
      {
        onSuccess: (res) => {
          handleClosePasswordModal();
        },
        onError: () => (err) => {},
      }
    );
  };

  useEffect(() => {
    adminMemberRefetch(searchData);
  }, [searchData]);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box className="page-heading-section" sx={{ justifyContent: "start" }}>
        <Box className="page-heading">
          <h5>Members</h5>
          <Typography>View the summary of Member dashboard.</Typography>
        </Box>
        {/* <Tabs
          className="page-filters-tabs"
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab
            sx={{
              '&.Mui-selected': {
                color: 'rgba(68, 95, 210, 1) !important',
                border: '1px solid rgba(68, 95, 210, 1) !important',
              },
              textTransform: 'capitalize',
            }}
            className="inner-tab-label"
            label="Active"
          />
          <Tab
            sx={{
              '&.Mui-selected': {
                color: 'rgba(68, 95, 210, 1) !important',
                border: '1px solid rgba(68, 95, 210, 1) !important',
              },
              textTransform: 'capitalize',
            }}
            className="inner-tab-label"
            label="Inactive"
          />
        </Tabs> */}
      </Box>
      {/* <hr className="horizontal-line" /> */}
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box className="transperent-badge">
          Total Member : {AdminMemberData?.data?.totalCount}
        </Box>
        <Searchbar onSendData={handleChildData} />
        {/* <Box className="transperent-badge">12 Oct 2023</Box> */}
      </Box>

      <Box sx={{ background: "rgba(250, 251, 252, 1)", my: 2, p: 2 }}>
        <table className="common-table">
          <thead>
            <tr>
              <td width={"13%"} align="left">
                Member Name
              </td>
              <td width={"13%"}>Email</td>
              <td width={"13%"}>Role</td>
              <td width={"13%"}>Access</td>
              <td width={"11%"}>Log In</td>
              {/* <td width={"11%"}>Log Out</td> */}
              <td width={"13%"}>Date</td>
              <td width={"13%"}>Action</td>
            </tr>
          </thead>
          <tbody>
            {AdminMemberData?.data?.users?.map((x, i) => {
              return (
                <tr>
                  <td
                    align="left"
                    className="font-12-500 border-bottom"
                    style={{ color: "#4C4C4C" }}
                  >
                    {x?.name}
                  </td>
                  <td className="font-12-500 border-bottom">{x?.email}</td>
                  <td className="font-12-500 border-bottom">{x?.role}</td>
                  <td className="font-12-500 border-bottom" align="left">
                    <ul>
                      {!x?.acess
                        ? "--"
                        : [...new Set(x?.acess)].map((permission) => (
                            <li key={permission}>{permission}</li>
                          ))}
                    </ul>
                  </td>
                  <td className="font-12-500 border-bottom">
                    {x.lastLogin
                      ? new Date(x?.lastLogin).toLocaleString()
                      : "---"}
                  </td>
                  {/* <td className="font-12-500 border-bottom">3 : 45 Pm</td> */}
                  <td className="font-12-500 border-bottom">
                    {new Date(x?.createdAt).toDateString()}
                  </td>
                  <td className="border-bottom">
                    <IconButton aria-label="delete" style={iconStyle}>
                      <Delete
                        onClick={() => {
                          let alert = window.confirm(
                            "Are you sure you want to delete this member?"
                          );
                          if (alert) {
                            deleteAdminMutate(x?._id, {
                              onSuccess: (data) => {},
                              onError: (error) => {},
                            });
                          } else {
                            console.log("not deleted");
                          }
                        }}
                      />
                    </IconButton>

                    <IconButton aria-label="edit" style={iconStyle}>
                      <Password
                        onClick={() => {
                          setOpenPAssword(true);
                          setUpdatePasswordData({
                            ...UpdatePassWordData,
                            id: x?._id,
                          });
                        }}
                      />
                    </IconButton>

                    <IconButton aria-label="edit" style={iconStyle}>
                      <Edit
                        onClick={() => {
                          // setAdminUserId(x?._id);
                          handleClickOpen();
                          // AdminUserRefetch();
                          FetchSingleUser(x?._id);
                        }}
                      />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            padding: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "center",
            }}
          >
            Edit Admin Handle
            {/* {AdminUserData} */}
          </Typography>
          <Box className="fields-box">
            <Box>
              <label>Member Email</label>
              <input placeholder="Email" {...register("email")} />
              {/* {errors.name && (
                <Typography sx={ErrorStyle}>{errors.name.message}</Typography>
              )} */}
            </Box>
          </Box>
          <Box className="fields-box full-width">
            <Box>
              <label>Roles : {adminUser?.role} </label>
              <div>
                <select {...register("role")}>
                  <option value={adminUser?.role}>{adminUser?.role}</option>
                  {ADMIN_ROLES.map((role) => {
                    return <option value={role.value}>{role.name}</option>;
                  })}
                </select>
              </div>
              {errors.rights && (
                <Typography sx={ErrorStyle}>{errors.rights.message}</Typography>
              )}
            </Box>
          </Box>
          <Box className="fields-box full-width">
            <Box>
              <label>Rights </label>
              <div>
                <select
                  {...register("rights")}
                  onChange={(e) => {
                    if (e.target.value === "") return;
                    if (rightsArr.includes(e.target.value)) return;
                    setRigthtsArr([...rightsArr, e.target.value]);
                  }}
                >
                  <option value=""></option>
                  {Object.keys(ADMIN_ACCESS).map((key, value) => {
                    return (
                      <option value={ADMIN_ACCESS[key]}>
                        {ADMIN_ACCESS[key]}
                      </option>
                    );
                  })}
                </select>
              </div>
              {errors.rights && (
                <Typography sx={ErrorStyle}>{errors.rights.message}</Typography>
              )}
              {
                <Box>
                  {rightsArr?.map((right) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {right}
                        </Typography>
                        <Typography
                          sx={{
                            // color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setRigthtsArr(
                              rightsArr.filter((item) => item !== right)
                            );
                          }}
                        >
                          X
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              }
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{
              mx: "auto",
            }}
            onClick={UpdateAdminMember}
          >
            Update
          </Button>
        </Box>
      </Dialog>
      <Modal open={openPAssword} onClose={handleClosePasswordModal}>
        <Box sx={PasswordModalstyle}>
          <Typography>Change Password</Typography>

          <Box className="fields-box">
            <Box>
              <label>New Password</label>
              <input
                placeholder="new password"
                onChange={(e) => {
                  setUpdatePasswordData({
                    ...UpdatePassWordData,
                    password: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>
          <Box className="fields-box">
            <Box>
              <label>Password Confirmation</label>
              <input
                placeholder="rewrite the password"
                onChange={(e) => {
                  setUpdatePasswordData({
                    ...UpdatePassWordData,
                    confirmpassword: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              mx: "auto",
              justifyContent: "center",
              gap: "10px",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              type="submit"
              sx={{
                mx: "auto",
              }}
              onClick={UpdateAdminPass}
            >
              Update
            </Button>
            <Button
              // onClick={handleClosePasswordModal}
              variant="outlined"
              sx={{
                mx: "auto",
              }}
              onClick={() => {
                setUpdatePasswordData({});
                handleClosePasswordModal();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};

export default Memberdetails;
const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};
const ErrorStyle = {
  color: "red",
  fontSize: "12px",
  fontWeight: "bold",
  mt: "5px",
};

const PasswordModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  boxShadow: 12,
  p: 2,
  borderRadius: 2,
};
