import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";

const fetchCompaniesAllApprovals = async (
  id,
  queryType,
  searchData,
  currentPage
) => {
  const response = await axios.get(
    `/api/v1/admin/approvals/get-requests/${id}?${queryType}=${queryType}&search=${searchData}&page=${currentPage}`,
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
  return response.data;
};

export const useFetchCompaniesRequests = (
  id,
  queryType,
  searchData,
  currentPage
) => {
  return useQuery("ApprovalRequests", () =>
    fetchCompaniesAllApprovals(id, queryType, searchData, currentPage)
  );
};
const Getcreditrequests = async () => {
  const response = await axios.get(`/api/v1/admin/wallet/get-credit-requests`, {
    headers: {
      authorization: `bearer ${GetTokenFunc()}`,
    },
  });
  return response.data;
};

export const useGetcreditrequests = () => {
  return useQuery("Getcreditrequests", () => Getcreditrequests());
};

const UpdateApproval = async (id) => {
  await axios.post(
    `/api/v1/admin/approvals/update-request/${id}`,
    {
      AdminAcceptence: true,
    },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useUpdateApproval = () => {
  return useMutation(UpdateApproval);
};
