import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Modal,
  Button,
  Tabs,
  Tab,
  Divider,
  Stack,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import BXICOIN from "../../assets/GenieImg/BXICOIN.png";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import FigmaIcon from "../../assets/figma-icon.png";
import * as Styles from "../../common/Styles";
import filter from "../../assets/filter.png";
import axios from "axios";
import BingoDealsEdit from "../CommonActions/BingoDealsEdit.jsx";
import { useFetchData } from "../../Hooks/GenieData/useUpdateData";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import { GetTokenFunc } from "../../utils/GetToken";

import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Popup from "../Layouts/Popup/Popup";
import Searchbar from "../../common/Searchbar.jsx";
const GenieBingoDeals = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState();
  const [Id, setId] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [dataTypes, setDataTypes] = useState("Product");
  const [initialPaginationCount, setInitialPaginationCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "0px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "0px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const {
    data: getBingoDealsData,
    isError: bingoDealsError,
    isLoading: bingoDealsLoading,
    refetch: bingoDealsRefetch,
  } = useFetchData(dataTypes, currentPage, searchData);

  useEffect(() => {
    // bingoDealsRefetch();
    setInitialPaginationCount(getBingoDealsData?.data?.totalPages);
  }, [currentPage, dataTypes, getBingoDealsData]);

  useEffect(() => {
    bingoDealsRefetch();
  }, [dataTypes, currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleEditSave = (inputValues) => {
    setDataId(inputValues);
    setIsEditModalOpen(false);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);

  const DeleteAddToCardProduct = async () => {
    await axios
      .post(
        `/api/v1/admin/AdminCompanyDetails/DeleteAddToCardProduct?ProductId=${Id}`,
        {},
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const ProductDataRender = () => {
    return (
      <Paper elevation={0}>
        {getBingoDealsData?.data?.products?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : (
          <Box mt={2} sx={{ background: "#FAFBFC", p: 0.5 }}>
            <Grid
              container
              sx={{
                p: 1,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Product Name
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Email
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Contact number
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Category
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Location
              </Grid>

              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Price
              </Grid>
              <Grid
                item
                xl={0.5}
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
                sx={Styles.textHead}
              >
                Quantity
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Action
              </Grid>
            </Grid>

            {getBingoDealsData?.data?.products?.map((x, i) => {
              return (
                <Grid container sx={Styles.containerStyles}>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    align="left"
                    className="product-title-colum"
                  >
                    {/* <img
                      src={x?.img}
                      style={{
                        width: "30px",
                        height: "auto",
                      }}
                    /> */}
                    <Box sx={{ ...Styles.headProductText, ml: 1 }}>
                      {x?.ProductName ? x?.ProductName : "--"}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.subTextData}
                  >
                    {x?.CompanyName ? x?.CompanyName : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      ...Styles.subTextData,
                    }}
                  >
                    {x?.EmailId ? x?.EmailId : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.subTextData}
                  >
                    {x?.PhoneNo ? x?.PhoneNo : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.Category ? x?.Category : "--"}
                  </Grid>

                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.Location ? x?.Location : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={numText}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "3px",
                        justifyContent: "center",
                      }}
                    >
                      {x?.Price ? (
                        <>
                          <img
                            src={BXICOIN}
                            alt=""
                            style={{
                              width: "18px",
                              height: "auto",
                            }}
                          />
                          {x?.Price.toFixed(2)}
                        </>
                      ) : (
                        "--"
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.subTextData}
                  >
                    {x?.Quantity ? x?.Quantity : "--"}
                  </Grid>

                  <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                    <IconButton
                      aria-label="delete"
                      style={{ color: "rgba(68, 95, 210, 1)" }}
                    >
                      <Delete
                        onClick={() => {
                          setOpenDelete(true);
                          setId(x?._id);
                        }}
                      />
                    </IconButton>
                    {/*   <IconButton
                      aria-label="edit"
                      style={{ color: 'rgba(68, 95, 210, 1)' }}
                      onClick={() => {
                        setIsEditModalOpen(true);
                        setDataId(x);
                      }}
                    >
                      <Edit />
                    </IconButton> */}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
        <Modal open={openDelete} onClose={handleDeleteClose}>
          <Box sx={DeleteStyle}>
            <Typography sx={ModalHeaderStyle}>
              Are you sure want to delete this data?
            </Typography>
            <Box sx={ButtonBoxStyle}>
              <Button
                onClick={() => {
                  DeleteAddToCardProduct();
                  setOpenDelete(false);
                }}
                variant="contained"
                sx={{
                  ...ButtonStyle,
                  color: "#000",
                  background: "#fff",
                  "&:hover": {
                    background: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                    color: "#000",
                  },
                }}
              >
                Delete
              </Button>
              <Button
                onClick={handleDeleteClose}
                variant="contained"
                color="primary"
                sx={{
                  ...ButtonStyle,
                  // background: 'rgba(68, 95, 210, 1)',
                  color: "#fff",
                  "&:hover": {
                    background: "rgba(68, 95, 210, 1)",
                    color: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
    );
  };

  const VoucherDataRender = () => {
    return (
      <Paper elevation={0}>
        {getBingoDealsData?.data?.voucher?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : (
          <Box mt={2} sx={{ background: "#FAFBFC", p: 0.5 }}>
            <Grid
              container
              sx={{
                p: 1,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Product Name
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Email
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Contact number
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Category
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Location
              </Grid>

              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Price
              </Grid>
              <Grid
                item
                xl={0.5}
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
                sx={Styles.textHead}
              >
                Quantity
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Action
              </Grid>
            </Grid>

            {getBingoDealsData?.data?.voucher?.map((x, i) => {
              return (
                <Grid container sx={Styles.containerStyles}>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    align="left"
                    className="product-title-colum"
                  >
                    <Box sx={{ ...Styles.headProductText, ml: 1 }}>
                      {x?.ProductName ? x?.ProductName : "--"}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.subTextData}
                  >
                    {x?.CompanyName ? x?.CompanyName : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.EmailId ? x?.EmailId : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.subTextData}
                  >
                    {x?.PhoneNo ? x?.PhoneNo : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.Category ? x?.Category : "--"}
                  </Grid>

                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.Location ? x?.Location : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={numText}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "3px",
                        justifyContent: "center",
                      }}
                    >
                      {x?.Price ? (
                        <>
                          <img
                            src={BXICOIN}
                            alt=""
                            style={{
                              width: "18px",
                              height: "auto",
                            }}
                          />
                          {x?.Price}
                        </>
                      ) : (
                        "--"
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.subTextData}
                  >
                    {x?.Quantity ? x?.Quantity : "--"}
                  </Grid>

                  <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                    <IconButton
                      aria-label="delete"
                      style={{ color: "rgba(68, 95, 210, 1)" }}
                    >
                      <Delete
                        onClick={() => {
                          setOpenDelete(true);
                          setId(x?._id);
                        }}
                      />
                    </IconButton>
                    {/*     <IconButton
                      aria-label="edit"
                      style={{ color: 'rgba(68, 95, 210, 1)' }}
                      onClick={() => {
                        setIsEditModalOpen(true);
                        setDataId(x);
                      }}
                    >
                      <Edit />
                    </IconButton> */}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
        <Modal open={openDelete} onClose={handleDeleteClose}>
          <Box sx={DeleteStyle}>
            <Typography sx={ModalHeaderStyle}>
              Are you sure want to delete this data?
            </Typography>
            <Box sx={ButtonBoxStyle}>
              <Button
                onClick={() => {
                  DeleteAddToCardProduct();
                  setOpenDelete(false);
                }}
                variant="contained"
                sx={{
                  ...ButtonStyle,
                  color: "#000",
                  background: "#fff",
                  "&:hover": {
                    background: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                    color: "#000",
                  },
                }}
              >
                Delete
              </Button>
              <Button
                onClick={handleDeleteClose}
                variant="contained"
                color="primary"
                sx={{
                  ...ButtonStyle,
                  // background: 'rgba(68, 95, 210, 1)',
                  color: "#fff",
                  "&:hover": {
                    background: "rgba(68, 95, 210, 1)",
                    color: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
    );
  };

  const MediaDataRender = () => {
    return (
      <Paper elevation={0}>
        {getBingoDealsData?.data?.media?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : (
          <Box mt={2} sx={{ background: "#FAFBFC", p: 0.5 }}>
            <Grid
              container
              sx={{
                p: 1,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Product Name
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Email
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Contact number
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Category
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Location
              </Grid>

              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Price
              </Grid>
              <Grid
                item
                xl={0.5}
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
                sx={Styles.textHead}
              >
                Quantity
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                sx={Styles.textHead}
              >
                Action
              </Grid>
            </Grid>

            {getBingoDealsData?.data?.media?.map((x, i) => {
              return (
                <Grid container sx={Styles.containerStyles}>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    align="left"
                    className="product-title-colum"
                  >
                    <Box sx={{ ...Styles.headProductText, ml: 1 }}>
                      {x?.ProductName ? x?.ProductName : "--"}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.subTextData}
                  >
                    {x?.CompanyName ? x?.CompanyName : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.EmailId ? x?.EmailId : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.subTextData}
                  >
                    {x?.PhoneNo ? x?.PhoneNo : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.Category ? x?.Category : "--"}
                  </Grid>

                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.Location ? x?.Location : "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={numText}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "3px",
                        justifyContent: "center",
                      }}
                    >
                      {x?.Price ? (
                        <>
                          <img
                            src={BXICOIN}
                            alt=""
                            style={{
                              width: "18px",
                              height: "auto",
                            }}
                          />
                          {x?.Price}
                        </>
                      ) : (
                        "--"
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.subTextData}
                  >
                    {x?.Quantity ? x?.Quantity : "--"}
                  </Grid>

                  <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                    <IconButton
                      aria-label="delete"
                      style={{ color: "rgba(68, 95, 210, 1)" }}
                    >
                      <Delete
                        onClick={() => {
                          setOpenDelete(true);
                          setId(x?._id);
                        }}
                      />
                    </IconButton>
                    {/*  <IconButton
                      aria-label="edit"
                      style={{ color: 'rgba(68, 95, 210, 1)' }}
                      onClick={() => {
                        setIsEditModalOpen(true);
                        setDataId(x);
                      }}
                    >
                      <Edit />
                    </IconButton> */}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
        <Modal open={openDelete} onClose={handleDeleteClose}>
          <Box sx={DeleteStyle}>
            <Typography sx={ModalHeaderStyle}>
              Are you sure want to delete this data?
            </Typography>
            <Box sx={ButtonBoxStyle}>
              <Button
                onClick={() => {
                  DeleteAddToCardProduct();
                  setOpenDelete(false);
                }}
                variant="contained"
                sx={{
                  ...ButtonStyle,
                  color: "#000",
                  background: "#fff",
                  "&:hover": {
                    background: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                    color: "#000",
                  },
                }}
              >
                Delete
              </Button>
              <Button
                onClick={handleDeleteClose}
                variant="contained"
                color="primary"
                sx={{
                  ...ButtonStyle,
                  // background: 'rgba(68, 95, 210, 1)',
                  color: "#fff",
                  "&:hover": {
                    background: "rgba(68, 95, 210, 1)",
                    color: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "600px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px" }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/genie");
            }}
          >
            <KeyboardBackspaceIcon />
          </Box>

          <Box className="page-heading">
            <h5>Bingo Deals</h5>
            <Typography>Explore Bingo Cart Deals of our Members.</Typography>
          </Box>
        </Box>
        <Searchbar width={"45%"} onSendData={handleChildData} />

        <Tabs
          className="page-filters-tabs"
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab
            key="Product"
            onClick={() => {
              bingoDealsRefetch();
              setDataTypes("Product");
              setCurrentPage(1);
            }}
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Product"
          />
          <Tab
            key="Voucher"
            onClick={() => {
              bingoDealsRefetch();
              setDataTypes("Voucher");
              setCurrentPage(1);
            }}
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Voucher"
          />
          <Tab
            key="Media"
            onClick={() => {
              bingoDealsRefetch();
              setDataTypes("Media");
              setCurrentPage(1);
            }}
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Media"
          />
        </Tabs>
        {/*  <Box sx={{ ml: 'auto' }}>
          <Box
            sx={Styles.filtersDesign}
            onClick={() => {
              handleEditButtonClick();
            }}
          >
            <img
              src={filter}
              alt=""
              style={{ height: 'auto', width: '15px' }}
            />
            Filters
          </Box>{' '}
          <Popup
            openPopup={isOpen}
            popupTitle="Filters"
            popupContent={content}
            handleClose={() => {
              setIsOpen(false);
            }}
          />
          </Box> */}
      </Box>
      <Divider />
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        {bingoDealsLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
              width: "100%",
              // mr: '150%',
              ml: "500px",
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <>
            {activeTab === 0 && <div>{ProductDataRender()}</div>}
            {activeTab === 1 && <div>{VoucherDataRender()}</div>}
            {activeTab === 2 && <div>{MediaDataRender()}</div>}
          </>
        )}
      </Box>

      <BingoDealsEdit
        key={dataId?._id}
        open={isEditModalOpen}
        onClose={handleEditClose}
        onSave={() => {
          handleEditSave(dataId);
        }}
        text="Product Data Edit"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        fieldOne="Price"
        fieldTwo="Quantity"
        productName={dataId?.ProductName}
        initialProductData={dataId}
        BingoDealsRefetch={bingoDealsRefetch}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        mt={0.8}
      >
        <Stack spacing={2}>
          <StyledPagination
            size="small"
            count={initialPaginationCount}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default GenieBingoDeals;
const numText = {
  color: "var(--text-color-text-3, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};
const DeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};
const ModalHeaderStyle = {
  color: "#6B7A99",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  my: 2,
};
const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  mt: 2,
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};
