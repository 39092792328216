import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Modal,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import "../Company/Company.Modules.css";
import { Delete, Edit } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import FigmaIcon from "../../assets/figma-icon.png";
import CategoryDropdown from "../Layouts/CategoryDropdown";
import BXIToken from "../../assets/BXIToken.svg";
import BxiCoinIcon from "../../assets/BXI-coin-icon.svg";
import CommonDelete from "../CommonActions/CommonDelete";
import { StyledPagination } from "../../common/StyledPagination";
import axios from "axios";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import {
  useFetchInvoice,
  useFetchSaleInvoice,
} from "../../Hooks/Invoice/useGetInvoice";
import { useRemoveinvoice } from "../../Hooks/Invoice/useRemoveinvoice";
import { useParams } from "react-router-dom";
import PageLoader from "../CommonActions/PageLoader";
import Searchbar from "../../common/Searchbar";

const Transactions = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sellCurrentPage, setSellCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [initialSellPaginationCount, setInitialSellPaginationCount] = useState(
    sellCurrentPage || 1
  );

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const {
    data: invoiceData,
    isError: invoiceError,
    isLoading: invoiceLoading,
    refetch: invoiceRefetch,
  } = useFetchInvoice(id, currentPage, searchData);

  const {
    data: saleData,
    isError: saleError,
    isLoading: saleLoading,
    refetch: invoiceSaleRefetch,
  } = useFetchSaleInvoice(id, sellCurrentPage, searchData);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const { data: mutateRemoveInvoice, mutate: removefrominvoice } =
    useRemoveinvoice(id);

  useEffect(() => {
    if (invoiceData && invoiceData?.TotalPages !== undefined) {
      setInitialPaginationCount(invoiceData?.TotalPages);
    }
  }, [invoiceData?.TotalPages]);
  useEffect(() => {
    if (saleData && saleData?.TotalPages !== undefined) {
      setInitialSellPaginationCount(saleData?.TotalPages);
    }
  }, []);
  useEffect(() => {
    invoiceSaleRefetch();
    invoiceRefetch();
  }, [sellCurrentPage, currentPage, searchData]);

  useEffect(() => {
    setSellCurrentPage(1);
    setCurrentPage(1);
  }, [searchData]);
  const handleDelete = (id) => {
    removefrominvoice(id, {
      onSuccess: (res) => {
        setIsDeleteModalOpen(false);
        setDeleteProductId(null);
        invoiceRefetch();
      },
      onError: (err) => {},
    });
    invoiceRefetch();
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleSellPageChange = (event, page) => {
    setSellCurrentPage(page);
  };

  const renderPurchaseItems = () => {
    return (
      <div>
        {invoiceLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              ml: "500px",
              mt: 20,
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <table className="common-table">
            <thead>
              <tr>
                <td width={"20%"} align="left">
                  Product Name
                </td>
                <td width={"10%"}>Seller Name</td>
                <td width={"10%"}>Category</td>
                <td width={"10%"}>Coins Amount</td>
                <td width={"10%"}>INR Amount</td>
                <td width={"10%"}>Date</td>
                <td width={"10%"}>Order Id</td>
                <td width={"10%"}>Status</td>
              </tr>
            </thead>
            <tbody>
              {invoiceData === "No Data Found" ? (
                <Typography
                  sx={{
                    textAlign: "center",
                    width: "500%",
                    mt: 5,
                  }}
                >
                  <img
                    src={recyclebin}
                    alt="recycle"
                    style={{ height: "auto", width: "23%" }}
                  />
                  <Typography sx={empty}>Data is empty</Typography>
                </Typography>
              ) : (
                invoiceData?.data?.map((x, i) => {
                  let TotalAdditionalCostWtihoutGSTInBXI = 0;
                  let TotalPriceWithoutGST = 0;

                  x?.PurchaseOrderData?.ProductData?.map((el, idx) => {
                    if (x?.IsVoucher) {
                      TotalAdditionalCostWtihoutGSTInBXI +=
                        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
                    } else {
                      TotalAdditionalCostWtihoutGSTInBXI +=
                        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
                    }
                    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                  });
                  return (
                    <tr>
                      <td align="left" className="product-title-colum">
                        <img
                          src={
                            x?.PurchaseOrderData?.ProductData?.at(0)
                              ?.ProductImage
                          }
                          alt=""
                          style={{ width: "35px", height: "35px" }}
                        />
                        <Box className="product-title-box">
                          <label>
                            {
                              x?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductName
                            }
                          </label>
                          <span>
                            Product ID : #
                            {
                              x?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductId
                            }{" "}
                          </span>
                        </Box>
                      </td>
                      <td className="font-12-500">
                        {x?.PurchaseOrderData?.SellerDetails?.SellerCompanyName}
                      </td>
                      <td className="font-12-500">
                        {
                          x?.PurchaseOrderData?.ProductData?.[0]
                            ?.ProductTypeName
                        }
                      </td>
                      <td className="black-text font-14-600-Kumbh">
                        <img
                          src={BXIToken}
                          alt=""
                          style={{ height: "15px", width: "15px" }}
                        />
                        {(
                          (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                          (TotalPriceWithoutGST || 0) -
                          x?.INRDetails?.TDS
                        ).toFixed(2)}
                      </td>

                      <td className="black-text font-14-600-Kumbh">
                        {x?.INRDetails?.TotalInrToPay
                          ? x?.INRDetails?.TotalInrToPay.toFixed(2)
                          : "--"}
                      </td>
                      <td className="date-time-label">
                        <label>
                          {new Date(x?.createdAt).toLocaleDateString("en-GB", {
                            timeZone: "UTC",
                          })}
                        </label>
                        <br />
                      </td>
                      <td
                        className="font-14-500"
                        style={{ color: "rgba(120, 119, 139, 1)" }}
                      >
                        {x?.orderData?.OrderId
                          ? x?.orderData?.OrderId
                          : x?.OrderId
                          ? x?.OrderId
                          : x?.PINumber}
                      </td>
                      <td className="font-14-500">
                        <Typography
                          sx={{
                            width: "100%",
                            background: x?.escrowSellerReleaseResponse?.success
                              ? "#D9FFE9"
                              : "#FFEFEF",
                            borderRadius: 1.5,
                            color: x?.escrowSellerReleaseResponse?.success
                              ? "#27AE60"
                              : "#F2994A",

                            fontFamily: "poppins",
                            fontWeight: 500,
                            fontSize: "12px",
                            fontStyle: "normal",
                            lineHeight: "30px",
                          }}
                        >
                          {x?.escrowSellerReleaseResponse?.success
                            ? "Release Called"
                            : "Payment Done"}
                        </Typography>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handlePageChange}
              strokeWidth={currentPage}
            />
          </Stack>
        </Box>
      </div>
    );
  };

  const renderSaleItems = () => {
    return (
      <div>
        <table className="common-table">
          <thead>
            <tr>
              <td width={"20%"} align="left">
                Product Name
              </td>
              <td width={"10%"}>Buyer Name</td>
              <td width={"10%"}>Category</td>
              <td width={"10%"}>Coins Amount</td>
              <td width={"10%"}>INR Amount</td>
              <td width={"10%"}>Date</td>
              <td width={"10%"}>Order Id</td>
              <td width={"10%"}>Status</td>
            </tr>
          </thead>
          <tbody>
            {saleData === "No Data Found" ? (
              <Typography
                sx={{
                  textAlign: "center",
                  width: "500%",
                  mt: 5,
                }}
              >
                <img
                  src={recyclebin}
                  alt="recycle"
                  style={{ height: "auto", width: "23%" }}
                />
                <Typography sx={empty}>Data is empty</Typography>
              </Typography>
            ) : (
              saleData?.data?.map((x, i) => {
                let TotalAdditionalCostWtihoutGSTInBXI = 0;
                let TotalPriceWithoutGST = 0;

                x?.PurchaseOrderData?.ProductData?.map((el, idx) => {
                  if (x?.IsVoucher) {
                    TotalAdditionalCostWtihoutGSTInBXI +=
                      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
                  } else {
                    TotalAdditionalCostWtihoutGSTInBXI +=
                      el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
                  }
                  TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                });
                return (
                  <tr>
                    <td align="left" className="product-title-colum">
                      <img
                        src={
                          x?.PurchaseOrderData?.ProductData?.at(0)?.ProductImage
                        }
                        alt=""
                        style={{ width: "35px", height: "35px" }}
                      />
                      <Box className="product-title-box">
                        <label>
                          {
                            x?.PurchaseOrderData?.ProductData?.at(0)
                              ?.ProductName
                          }
                        </label>
                        <span>
                          Product ID : #
                          {
                            x?.PurchaseOrderData?.ProductData?.at(0)
                              ?.ProductTypeId
                          }{" "}
                        </span>
                      </Box>
                    </td>
                    <td className="font-12-500">
                      {x?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
                    </td>
                    <td className="font-12-500">
                      {x?.PurchaseOrderData?.ProductData.at(0)?.ProductTypeName}
                    </td>
                    <td className="black-text font-14-600-Kumbh">
                      <img
                        src={BXIToken}
                        alt=""
                        style={{ height: "15px", width: "15px" }}
                      />
                      {(
                        (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                        (TotalPriceWithoutGST || 0) -
                        x?.INRDetails?.TDS
                      ).toFixed(2)}
                    </td>

                    <td className="black-text font-14-600-Kumbh">
                      {x?.INRDetails?.TotalInrToPay
                        ? x?.INRDetails?.TotalInrToPay.toFixed(2)
                        : "--"}
                    </td>
                    <td className="date-time-label">
                      <label>
                        {new Date(x?.createdAt).toLocaleDateString("en-GB", {
                          timeZone: "UTC",
                        })}
                      </label>
                      <br />
                    </td>
                    <td
                      className="font-14-500"
                      style={{ color: "rgba(120, 119, 139, 1)" }}
                    >
                      {x?.orderData?.OrderId
                        ? x?.orderData?.OrderId
                        : x?.OrderId
                        ? x?.OrderId
                        : x?.PINumber}
                    </td>
                    <td className="font-12-500">
                      {" "}
                      <Typography
                        sx={{
                          width: "100%",
                          background: x?.escrowSellerReleaseResponse?.success
                            ? "#D9FFE9"
                            : "#FFEFEF",
                          borderRadius: 1.5,
                          color: x?.escrowSellerReleaseResponse?.success
                            ? "#27AE60"
                            : "#F2994A",

                          fontFamily: "poppins",
                          fontWeight: 500,
                          fontSize: "12px",
                          fontStyle: "normal",
                          lineHeight: "30px",
                        }}
                      >
                        {x?.escrowSellerReleaseResponse?.success
                          ? "Release Called"
                          : "Payment Done"}
                      </Typography>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialSellPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={sellCurrentPage}
              onChange={handleSellPageChange}
              strokeWidth={sellCurrentPage}
            />
          </Stack>
        </Box>
      </div>
    );
  };

  return (
    <div>
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px" }}
      >
        <Box className="page-heading">
          <h5>Transaction Details</h5>
          <Typography>
            View the detailed transactions for all activities.
          </Typography>
        </Box>
        <Tabs
          className="page-filters-tabs"
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Purchase"
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Sale"
          />
        </Tabs>
        <Searchbar width={"55%"} onSendData={handleChildData} />
      </Box>
      <Divider />
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box
          className="products-filters-row"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5%",
          }}
        >
          <Box className="total-products-badge">
            {activeTab === 0 ? (
              <Box sx={buddge}>
                <Typography sx={textBudge}>
                  {invoiceData instanceof Array ? (
                    <>Total Products: {invoiceData.length}</>
                  ) : (
                    <>Total Products: {invoiceData?.TotalCount || 0}</>
                  )}
                </Typography>
              </Box>
            ) : (
              <Box sx={buddge}>
                <Typography sx={textBudge}>
                  {invoiceData instanceof Array ? (
                    <>Total Products: {saleData?.length}</>
                  ) : (
                    <>Total Products: {saleData.TotalCount || 0}</>
                  )}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {activeTab === 0 && <div>{renderPurchaseItems()}</div>}
        {activeTab === 1 && <div>{renderSaleItems()}</div>}
      </Box>
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={() => {
          handleDelete(deleteProductId);
        }}
        text="Are you sure you want to delete this transaction data?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
    </div>
  );
};

export default Transactions;

const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const DeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ModalHeaderStyle = {
  color: "#6B7A99",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  my: 2,
};

const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  mt: 2,
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
  width: "150px",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
