import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Grid, Paper, Tab, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import LeftArrow from "../../../assets/Images/payment/LeftArrow.png";
import BreadCrumbHeader from "../../Marketplace/viewProducts/BreadCrumbHeader";
import CarouselForProductHorizontal from "../../Marketplace/viewProducts/CarouselForProductHorizontal";
import CarouselforApperal from "../../Marketplace/viewProducts/CarouselforApperal";
import CommaSeprator from "../../../common/CommaSeparator";
import FeatureName from "../../Marketplace/viewProducts/FeatureName";
import useGetCompanyTypeData from "../../../Hooks/CompanyData/useGetCompanyTypeData";
import { useGetCompanyDetails } from "../../Marketplace/viewProducts/Auth";
import Weight from "../../../assets1/Images/Weight.svg";
import BXITokenIcon from "../../../assets1/Stack of Coins.svg";

const options = { day: "2-digit", month: "short", year: "numeric" };

function DiscountedPrice({
  regularPrice,
  discountPrice,
  GetProductByIdData,
  percentage,
}) {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const formattedDiscountPercent = discountPercent.toFixed(2);
  const gstPrice = (discountPrice * percentage) / 100;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          width: "650px",
          mt: "0px",
          marginBottom: "-11px",
          gap: "10px",
          // bgcolor: "red",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "15px",
            lineHeight: "10px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#DC3737",
          }}
        >
          -{formattedDiscountPercent}%
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "10px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#6B7A99",
          }}
        >
          &nbsp;
          <CommaSeprator Price={discountPrice} /> &nbsp;
          <img
            src={BXITokenIcon}
            style={{
              width: "13px",
              height: "auto",
              marginTop: "-4px",
            }}
            alt="BXI Token"
          />{" "}
          + <CommaSeprator Price={gstPrice} /> ₹
          <span
            style={{
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            GST
          </span>
        </Typography>
        {GetProductByIdData?.ProductsVariantions.at(0)?.unit ? (
          <Typography sx={{ ...fetchValue, pb: 1 }}>
            Per {GetProductByIdData?.ProductsVariantions.at(0)?.unit} Per{" "}
            {GetProductByIdData?.ProductsVariantions.at(0)?.Timeline}
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "15px",
            }}
          >
            <Typography sx={fetchValue}>
              Per Day
              {/* {GetProductByIdData?.ProductsVariantions.at(0)?.Timeline} */}
            </Typography>
            <Typography sx={fetchValue}>
              Per insertion
              {/* {GetProductByIdData?.ProductsVariantions.at(0)?.Timeline} */}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "11px",
          lineHeight: "25px",
          letterSpacing: "0.06em",
          textTransform: "capitalize",

          color: "#6B7A99",
          textDecoration: "line-through",
        }}
      >
        MRP: <CommaSeprator Price={regularPrice} />
      </Typography>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "10px",
          textTransform: "capitalize",
          color: "#6B7A99",
        }}
      >
        Exclusive of all taxes
      </Typography>
    </div>
  );
}

const ElectronicProductPreview = () => {
  let { id } = useParams();
  const [value, setValue] = React.useState("1");
  const [GetProductByIdData, setGetProductByIdData] = useState();

  const [ProductFeatures, setProfuctFeatures] = useState([]);

  const [WishlistData, setWishlistData] = useState();
  const [color, setColor] = useState("");

  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductByIdData(res?.data);
        setStoreVariationData(res?.data?.ProductsVariantions[0]?._id);
        setProfuctFeatures(res?.data?.ProductFeatures);
      });
  }

  const technicalinfo = [
    {
      name: "Before Weight",
      img: Weight,
      val: GetProductByIdData?.ProductTechInfo?.WeightBeforePackingPerUnit,
      measure: GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit,
    },
  ];

  const navigate = useNavigate();

  const { data: CompanyData } = useGetCompanyDetails();
  const {
    data: CompanyTypeData,
    // isLoading: CompanyTypeDataLoading,
    // error: CompanyTypeDataError,
    // refetch: CompanyTypeDataRefetch,
  } = useGetCompanyTypeData(CompanyData?.data?.companyType);

  const [storeVariationData, setStoreVariationData] = useState();

  let ColorData = {};

  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (index === 0) {
      ColorData = item;
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ImageDataArray = GetProductByIdData?.ProductImages;

  useEffect(() => {
    ColorData = {};
  }, [color, storeVariationData]);

  let NewdataArray = [];

  for (let i = 0; i < GetProductByIdData?.ProductsVariantions?.length; i++) {
    let ProductColor = GetProductByIdData?.ProductsVariantions[i].ProductColor;
    let finddata = NewdataArray.find((d) => d.ProductColor === ProductColor);
    if (finddata) {
      finddata.ProductVariations.push(
        GetProductByIdData?.ProductsVariantions[i]
      );
    } else {
      NewdataArray.push({
        ProductColor: GetProductByIdData?.ProductsVariantions[i].ProductColor,
        ProductVariations: [GetProductByIdData?.ProductsVariantions[i]],
      });
    }
  }
  const naviagte = useNavigate();

  async function fetWishlistData() {
    await axios
      .get("wishlist/get_wishlist_product", {
        withCredentials: true,
      })
      .then((res) => {
        setWishlistData(res?.data);
      });
  }
  useEffect(() => {
    fetWishlistData();
  }, []);

  useEffect(() => {
    GetProductByid();
  }, []);

  let samplestate = false;
  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (item.SampleAvailability) {
      return (samplestate = true);
    } else {
      return (samplestate = false);
    }
  });

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "transparent",
          boxShadow: "none",
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <BreadCrumbHeader MainText={GetProductByIdData?.ProductCategoryName} />

        <Grid container sx={HeaderContainerStyle}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={PageHeader}>
              <Typography sx={AppBarTypoStyle}>
                {GetProductByIdData?.ProductName}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* ***** Carasoul ***** */}
        <Box
          sx={{
            display: "flex",
            my: {
              xl: "0px",
              lg: "0px",
              md: "5px",
              sm: "10px",
              xs: "20px",
            },
            width: "100%",
          }}
        >
          <CarouselforApperal ImageDataArray={ImageDataArray} />
        </Box>

        <TabContext value={value} variant="fullwidth">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              mt: {
                xl: "40px",
                lg: "40px",
                md: "30px",
                sm: "20px",
                xs: "10px",
              },
            }}
          >
            <TabList
              onChange={handleChange}
              sx={{ width: "95%" }}
              variant="fullWidth"
            >
              <Tab label="Description" value="1" sx={tabTexts} />
              <Tab label="Product Information" value="2" sx={tabTexts} />
              <Tab label="Technical Information" value="3" sx={tabTexts} />
              <Tab label="Key Features" value="4" sx={tabTexts} />
            </TabList>
          </Box>

          <TabPanel value="1">
            {/* Description */}
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography sx={TypographyTitleText}>
                    {GetProductByIdData?.ProductSubtitle ||
                      GetProductByIdData?.ProductSubtittle}
                  </Typography>
                  <Typography sx={DescriptionAnswerText}>
                    {GetProductByIdData?.ProductDescription}
                  </Typography>

                  <Typography sx={{ ...semi, color: "#156DB6" }}>
                    {/* {GetProductByIdData?.ProductName} */}
                    Product Information
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      mx: "auto",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "5px",
                    }}
                  >
                    <DiscountedPrice
                      regularPrice={
                        GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.PricePerUnit
                      }
                      discountPrice={
                        GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.DiscountedPrice
                      }
                      percentage={
                        GetProductByIdData?.ProductsVariantions?.at(0)?.GST
                      }
                      GetProductByIdData={GetProductByIdData}
                      // regularPrice={10000}
                      // discountPrice={5000}
                    />
                  </Box>

                  <Box
                    mt={4}
                    sx={{
                      width: "100%",
                      mx: "auto",
                    }}
                  >
                    <Grid container sx={{ width: "90%" }}>
                      {GetProductByIdData?.medianame ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>Brand Name</Typography>
                          <Typography
                            sx={{ ...fetchValue, wordBreak: "break-all" }}
                          >
                            {GetProductByIdData?.medianame}
                          </Typography>
                        </Grid>
                      ) : null}
                      {GetProductByIdData?.multiplexScreenName ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>Name</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.multiplexScreenName}
                          </Typography>
                        </Grid>
                      ) : null}

                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.offerningbrandat ? (
                          <>
                            <Typography sx={tableHeader}>
                              {" "}
                              Offering At
                            </Typography>
                            <Typography
                              sx={{ ...fetchValue, wordBreak: "break-all" }}
                            >
                              {GetProductByIdData?.offerningbrandat}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              sx={{ ...tableHeader, whiteSpace: "nowrap" }}
                            >
                              {" "}
                              Position of the Ad ?
                            </Typography>
                            <Typography
                              sx={{
                                ...fetchValue,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {GetProductByIdData?.adPosition}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 4, width: "90%" }}>
                      {GetProductByIdData?.ProductsVariantions.at(0)
                        ?.location ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>Ad Type</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.ProductsVariantions.at(0)
                              ?.location ||
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.adType}
                          </Typography>
                        </Grid>
                      ) : null}
                      {GetProductByIdData?.ProductsVariantions.at(0)?.Type ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>Type</Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.Type
                            }
                          </Typography>
                        </Grid>
                      ) : null}

                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.ProductsVariantions.at(0)?.unit ? (
                          <>
                            <Typography sx={tableHeader}>Unit</Typography>
                            <Typography sx={fetchValue}>
                              Per{" "}
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.unit
                              }
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={tableHeader}>
                              Release Details
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.releasedetails
                              }
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.Timeline ? (
                          <>
                            <Typography sx={tableHeader}> Timeline</Typography>
                            <Typography sx={fetchValue}>
                              Per{" "}
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.Timeline
                              }
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={tableHeader}> Edition</Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.edition
                              }
                            </Typography>
                          </>
                        )}
                      </Grid>
                      {GetProductByIdData?.ProductsVariantions.at(0)
                        ?.repetition ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <>
                            <Typography sx={tableHeader}>
                              {" "}
                              Repetition
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.repetition
                              }
                            </Typography>
                          </>
                        </Grid>
                      ) : null}
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Dimension Size</Typography>
                        <Typography sx={fetchValue}>
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.dimensionSize
                          }
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>GST</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)?.GST} %
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>HSN</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)?.HSN}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 4, width: "90%" }}>
                      {/* <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                    <Typography sx={tableHeader}>Price</Typography>
                    <Typography sx={fetchValue}>
                      {
                        GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.PricePerUnit
                      }
                    </Typography>
                  </Grid> */}
                      {/* <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                    <Typography sx={tableHeader}>
                      {" "}
                      Product Id Type
                    </Typography>
                    <Typography sx={fetchValue}>
                      {
                        GetProductByIdData?.ProductsVariantions.at(0)
                          ?.productIdType
                      }
                    </Typography>
                  </Grid> */}

                      {GetProductByIdData?.ProductsVariantions.at(0)
                        ?.minOrderQuantitytimeline ? (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Min - Max Order Quantity Timeline
                          </Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.ProductsVariantions.at(0)
                              ?.minOrderQuantitytimeline
                              ? `${
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.minOrderQuantitytimeline
                                } - ${
                                  GetProductByIdData?.ProductsVariantions?.at(0)
                                    ?.maxOrderQuantitytimeline
                                }`
                              : "N/A"}{" "}
                            {""} /{" "}
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.Timeline
                            }
                          </Typography>
                        </Grid>
                      ) : null}
                      {/* <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}>
                          Max Order Quantity Timeline
                        </Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)
                            ?.maxOrderQuantitytimeline
                            ? GetProductByIdData?.ProductsVariantions.at(0)
                                ?.maxOrderQuantitytimeline
                            : "N/A"}{" "}
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.Timeline
                          }
                        </Typography>
                      </Grid> */}
                      {GetProductByIdData?.ProductSubCategory ===
                      "643cda0c53068696706e3951" ? null : (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Min - Max Order Quantity Unit
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minOrderQuantityunit
                            }{" "}
                            -
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxOrderQuantityunit
                            }
                            /{" "}
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.unit
                            }
                          </Typography>
                        </Grid>
                      )}

                      {/* <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}>
                          Max Order Quantity Unit
                        </Typography>
                        <Typography sx={fetchValue}>
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.maxOrderQuantityunit
                          }{" "}
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.unit
                          }
                        </Typography>
                      </Grid> */}
                      {GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.minTimeslotSeconds ? (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Min - Max Timeslot
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minTimeslotSeconds
                            }{" "}
                            -
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxTimeslotSeconds
                            }
                            / Seconds {""}{" "}
                          </Typography>
                        </Grid>
                      ) : null}
                      {GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.seatingCapacity ? (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Seating Capacity
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.seatingCapacity
                            }
                          </Typography>
                        </Grid>
                      ) : null}

                      {/* <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}>Max Timeslot</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)
                            ?.maxTimeslotSeconds
                            ? GetProductByIdData?.ProductsVariantions.at(0)
                                ?.maxTimeslotSeconds + " Sec"
                            : "N/A"}{" "}
                        
                        </Typography>
                      </Grid> */}
                    </Grid>
                    <Grid container sx={{ mt: 5, width: "90%" }}>
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}>Region</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.GeographicalData?.region}
                        </Typography>
                      </Grid>
                      {GetProductByIdData?.GeographicalData?.state ===
                        undefined ||
                      GetProductByIdData?.GeographicalData?.state === null ||
                      GetProductByIdData?.GeographicalData?.state ===
                        "" ? null : (
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          <Typography sx={tableHeader}> State</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.state}
                          </Typography>
                        </Grid>
                      )}
                      {GetProductByIdData?.GeographicalData?.city ===
                        undefined ||
                      GetProductByIdData?.GeographicalData?.city === null ||
                      GetProductByIdData?.GeographicalData?.city ===
                        "" ? null : (
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          <Typography sx={tableHeader}>City</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.city}
                          </Typography>
                        </Grid>
                      )}
                      {GetProductByIdData?.GeographicalData?.landmark ===
                        undefined ||
                      GetProductByIdData?.GeographicalData?.landmark === null ||
                      GetProductByIdData?.GeographicalData?.landmark ===
                        "" ? null : (
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          <Typography sx={tableHeader}> Landmark</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.landmark}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    {/* {GetProductByIdData &&
                  GetProductByIdData?.ProductsVariantions?.map(
                    (el, idx) => {
                      return (
                        <Grid
                          container
                          sx={{
                            textAlign: "center",
                            maxHeight: "60px",
                            height: "60px",
                            overflow: "auto",
                            "::-webkit-scrollbar": {
                              display: "block",
                            },
                            "::-webkit-scrollbar-thumb": {
                              dynamic: "#8d8e90",
                              height: "8px",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <Typography sx={tableData}>
                              {el.medianame}
                            </Typography>
                          </Grid>

                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Typography sx={tableData}>
                              {el.MinOrderQuantity}
                            </Typography>
                          </Grid>

                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Typography sx={tableData}>
                              {el.MaxOrderQuantity}
                            </Typography>
                          </Grid>

                          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <Typography sx={tableData}>
                              {" "}
                              {
                                GetProductByIdData.ProductsVariantions[0]
                                  .GST
                              }
                              &nbsp;%
                            </Typography>
                          </Grid>

                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Typography sx={tableData}>
                              {el.ProductIdType}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }
                  )} */}
                  </Box>

                  {GetProductByIdData?.OtherCost &&
                  GetProductByIdData?.OtherCost?.length !== 0 ? (
                    <Box mt={2}>
                      <Typography
                        sx={{
                          ...product,
                          fontWeight: 600,
                          fontSize: "18px",
                          lineHeight: "30px",
                        }}
                      >
                        Additional Cost
                      </Typography>
                      {GetProductByIdData?.OtherCost?.length === 0
                        ? ""
                        : GetProductByIdData?.OtherCost?.map((cost) => {
                            const newValue = cost?.CostPrice.toFixed(2);
                            return (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "60px",
                                    mt: 1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: "160px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...fetchValue,
                                      }}
                                    >
                                      {" "}
                                      {cost?.ReasonOfCost}{" "}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      minWidth: "160px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...fetchValue,
                                      }}
                                    >
                                      HSN - {cost?.AdCostHSN}{" "}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      minWidth: "160px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...fetchValue,
                                      }}
                                    >
                                      GST - {cost?.AdCostGST} %
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      minWidth: "160px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...fetchValue,
                                      }}
                                    >
                                      {cost?.AdCostApplicableOn === "All"
                                        ? "One Time Cost"
                                        : "Per Unit"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      gap: "5px",
                                      minWidth: "160px",
                                      display: "flex",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...fetchValue,
                                      }}
                                    >
                                      {newValue}
                                    </Typography>
                                    <Typography>
                                      {cost.currencyType === "BXITokens" ? (
                                        <Box
                                          component="img"
                                          src={BXITokenIcon}
                                          alt="token"
                                          sx={{
                                            height: "auto",
                                            width: "15px",
                                            marginTop: "6px",
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          sx={{
                                            fontSize: "20px",
                                            ml: 1,
                                          }}
                                        >
                                          ₹
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              </>
                            );
                          })}
                    </Box>
                  ) : null}

                  {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks
                    .length === 0 ? null : (
                    <>
                      <Box sx={{ mt: 3 }}>
                        <Typography sx={cost}>Remarks </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "95%",
                            gap: "10px",
                            mt: 1,
                          }}
                        >
                          {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks.map(
                            (item, id) => {
                              return (
                                <>
                                  <Typography sx={otherCostText}>
                                    {id + 1}) {item},{" "}
                                  </Typography>
                                </>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                  <Box mt={4}>
                    <Typography sx={{ ...pack, color: "#156DB6" }}>
                      Technical Information
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography sx={inclusiveheader}>
                        Supporting you would give to buyer
                      </Typography>
                      {GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                        ? Object?.keys(
                            GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                          ).map((el, idx) => {
                            if (
                              GetProductByIdData
                                ?.WhatSupportingYouWouldGiveToBuyer[el] === "on"
                            ) {
                              return (
                                <>
                                  <Typography
                                    sx={{
                                      ...packHead,
                                      color: "#6B7A99",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      display: "flex",
                                      gap: "10px",
                                    }}
                                  >
                                    {" "}
                                    {/* <span>{idx + 1}</span> */}
                                    {el}
                                    {/* {
                                  GetProductByIdData
                                    ?.WhatSupportingYouWouldGiveToBuyer[el]
                                } */}
                                  </Typography>
                                  {/* <Typography> </Typography> */}
                                </>
                              );
                            } else {
                              return null;
                            }
                          })
                        : null}{" "}
                    </Box>

                    <Box>
                      <Typography sx={inclusiveheader}>
                        Dimensions of Ad / Content Needed
                      </Typography>
                      <Box>
                        <Typography sx={dots}>
                          {GetProductByIdData?.Dimensions}
                        </Typography>
                      </Box>
                    </Box>

                    {GetProductByIdData?.UploadLink ? (
                      <Box>
                        <Typography sx={inclusiveheader}>
                          Content Upload Link
                        </Typography>
                        <Box>
                          <a
                            style={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "16px",
                              color: "#445FD2",
                            }}
                          >
                            {GetProductByIdData?.UploadLink}
                          </a>
                          <br />
                        </Box>
                      </Box>
                    ) : null}

                    <Box>
                      <Typography sx={inclusiveheader}>
                        Inventory Available Dates
                      </Typography>
                      <Box sx={{ pt: "0.8%", display: "flex", gap: "10%" }}>
                        <Typography sx={dateMonth}>Start Date</Typography>
                        <Typography sx={dateMonth}>End Date</Typography>
                      </Box>

                      <Box
                        sx={{
                          overflow: "auto",
                          marginRight: "900px",
                          "::-webkit-scrollbar": {
                            display: "flex",
                          },
                          "::-webkit-scrollbar-thumb": {
                            dynamic: "#8d8e90",
                            minHeight: "10px",
                            borderRadius: "8px",
                          },
                          "::-webkit-scrollbar-thumb:vertical": {
                            maxHeight: "10px",
                          },
                          maxHeight:
                            GetProductByIdData?.calender?.length < 10
                              ? "auto"
                              : "200px",
                          height:
                            GetProductByIdData?.calender?.length < 10
                              ? "auto"
                              : "210px",
                        }}
                      >
                        <Stack>
                          <Box sx={{ pt: "0.8%", display: "flex", gap: "10%" }}>
                            <Typography sx={valDateMonth}>
                              {GetProductByIdData?.calender?.map((el, idx) => {
                                return (
                                  <>
                                    <Typography sx={valDateMonth}>
                                      {new Date(
                                        el.startDate
                                      ).toLocaleDateString(
                                        "en-US",
                                        options
                                      )}{" "}
                                    </Typography>
                                  </>
                                );
                              })}
                            </Typography>
                            <Typography sx={{ ...valDateMonth, ml: 10 }}>
                              {GetProductByIdData?.calender?.map((el, idx) => {
                                return (
                                  <>
                                    <Typography sx={valDateMonth}>
                                      {new Date(el.endDate).toLocaleDateString(
                                        "en-US",
                                        options
                                      )}{" "}
                                    </Typography>
                                  </>
                                );
                              })}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>

                  {GetProductByIdData?.tags === null ||
                  GetProductByIdData?.tags === undefined ? null : (
                    <Box
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography sx={inclusiveheader}>Tags</Typography>

                      <Typography
                        sx={{
                          ...packHead,
                          color: "#6B7A99",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {GetProductByIdData?.tags.map((data, id) => {
                          return (
                            <span>
                              {id + 1}) {data},{" "}
                            </span>
                          );
                        })}
                      </Typography>
                    </Box>
                  )}

                  <Box mt={4}>
                    <Typography sx={{ ...pack, color: "#156DB6" }}>
                      Key Features
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Grid
                        container
                        mt={0.5}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        {ProductFeatures?.map((res) => {
                          return (
                            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  textAlign: "start",
                                  flexDirection: "row",
                                  gap: "100px",
                                  mt: 1.5,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "20px",
                                    width: "100%",
                                  }}
                                >
                                  <FeatureName name={res?.name} />
                                  <Box
                                    sx={{
                                      width: "80%",
                                      maxWidth: "825px",
                                      height: "auto",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...packHead, color: "grey" }}
                                    >
                                      {res.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...packVal,
                                      }}
                                    >
                                      {res.description}{" "}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>{" "}
          </TabPanel>
          <TabPanel value="2">
            {/* Price & Availability */}
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography sx={{ ...semi, color: "#156DB6" }}>
                  {/* {GetProductByIdData?.ProductName} */}
                  Product Information
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <DiscountedPrice
                    regularPrice={
                      GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.PricePerUnit
                    }
                    discountPrice={
                      GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.DiscountedPrice
                    }
                    percentage={
                      GetProductByIdData?.ProductsVariantions?.at(0)?.GST
                    }
                    GetProductByIdData={GetProductByIdData}
                    // regularPrice={10000}
                    // discountPrice={5000}
                  />
                </Box>

                <Box
                  mt={4}
                  sx={{
                    width: "100%",
                    mx: "auto",
                  }}
                >
                  <Grid container sx={{ width: "90%" }}>
                    {GetProductByIdData?.medianame ? (
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Brand Name</Typography>
                        <Typography
                          sx={{ ...fetchValue, wordBreak: "break-all" }}
                        >
                          {GetProductByIdData?.medianame}
                        </Typography>
                      </Grid>
                    ) : null}
                    {GetProductByIdData?.multiplexScreenName ? (
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Multiplex Name</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.multiplexScreenName}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      {GetProductByIdData?.offerningbrandat ? (
                        <>
                          <Typography sx={tableHeader}> Offering At</Typography>
                          <Typography
                            sx={{ ...fetchValue, wordBreak: "break-all" }}
                          >
                            {GetProductByIdData?.offerningbrandat}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{ ...tableHeader, whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Position of the Ad ?
                          </Typography>
                          <Typography
                            sx={{
                              ...fetchValue,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {GetProductByIdData?.adPosition}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 4, width: "90%" }}>
                    {GetProductByIdData?.ProductsVariantions.at(0)?.location ? (
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Ad Type</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)
                            ?.location ||
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.adType}
                        </Typography>
                      </Grid>
                    ) : null}
                    {GetProductByIdData?.ProductsVariantions.at(0)?.Type ? (
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Type</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)?.Type}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      {GetProductByIdData?.ProductsVariantions.at(0)?.unit ? (
                        <>
                          <Typography sx={tableHeader}>Unit</Typography>
                          <Typography sx={fetchValue}>
                            Per{" "}
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.unit
                            }
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography sx={tableHeader}>
                            Release Details
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.releasedetails
                            }
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      {GetProductByIdData?.ProductsVariantions.at(0)
                        ?.Timeline ? (
                        <>
                          <Typography sx={tableHeader}> Timeline</Typography>
                          <Typography sx={fetchValue}>
                            Per{" "}
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.Timeline
                            }
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography sx={tableHeader}> Edition</Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.edition
                            }
                          </Typography>
                        </>
                      )}
                    </Grid>
                    {GetProductByIdData?.ProductsVariantions.at(0)
                      ?.repetition ? (
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <>
                          <Typography sx={tableHeader}> Repetition</Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.repetition
                            }
                          </Typography>
                        </>
                      </Grid>
                    ) : null}
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      <Typography sx={tableHeader}>Dimension Size</Typography>
                      <Typography sx={fetchValue}>
                        {
                          GetProductByIdData?.ProductsVariantions.at(0)
                            ?.dimensionSize
                        }
                      </Typography>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      <Typography sx={tableHeader}>GST</Typography>
                      <Typography sx={fetchValue}>
                        {GetProductByIdData?.ProductsVariantions.at(0)?.GST} %
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 4, width: "90%" }}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Typography sx={tableHeader}>
                        {" "}
                        Min - Max Order Quantity Timeline
                      </Typography>
                      <Typography sx={fetchValue}>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.minOrderQuantitytimeline
                          ? `${
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minOrderQuantitytimeline
                            } - ${
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxOrderQuantitytimeline
                            }`
                          : "N/A"}{" "}
                        {""} /{" "}
                        {
                          GetProductByIdData?.ProductsVariantions.at(0)
                            ?.Timeline
                        }
                      </Typography>
                    </Grid>

                    {GetProductByIdData?.ProductSubCategory ===
                    "643cda0c53068696706e3951" ? null : (
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography sx={tableHeader}>
                          {" "}
                          Min - Max Order Quantity Unit
                        </Typography>
                        <Typography sx={fetchValue}>
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.minOrderQuantityunit
                          }{" "}
                          -
                          {
                            GetProductByIdData?.ProductsVariantions?.at(0)
                              ?.maxOrderQuantityunit
                          }
                          /{" "}
                          {GetProductByIdData?.ProductsVariantions.at(0)?.unit}
                        </Typography>
                      </Grid>
                    )}

                    {GetProductByIdData?.ProductsVariantions?.at(0)
                      ?.minTimeslotSeconds ? (
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography sx={tableHeader}>
                          {" "}
                          Min - Max Timeslot
                        </Typography>
                        <Typography sx={fetchValue}>
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.minTimeslotSeconds
                          }{" "}
                          -
                          {
                            GetProductByIdData?.ProductsVariantions?.at(0)
                              ?.maxTimeslotSeconds
                          }
                          / Seconds {""}{" "}
                        </Typography>
                      </Grid>
                    ) : null}
                    {GetProductByIdData?.ProductsVariantions?.at(0)
                      ?.seatingCapacity ? (
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography sx={tableHeader}>
                          {" "}
                          Seating Capacity
                        </Typography>
                        <Typography sx={fetchValue}>
                          {
                            GetProductByIdData?.ProductsVariantions?.at(0)
                              ?.seatingCapacity
                          }
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid container sx={{ mt: 5, width: "90%" }}>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                      <Typography sx={tableHeader}>Region</Typography>
                      <Typography sx={fetchValue}>
                        {GetProductByIdData?.GeographicalData?.region}
                      </Typography>
                    </Grid>
                    {GetProductByIdData?.GeographicalData?.state ===
                      undefined ||
                    GetProductByIdData?.GeographicalData?.state === null ||
                    GetProductByIdData?.GeographicalData?.state ===
                      "" ? null : (
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}> State</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.GeographicalData?.state}
                        </Typography>
                      </Grid>
                    )}
                    {GetProductByIdData?.GeographicalData?.city === undefined ||
                    GetProductByIdData?.GeographicalData?.city === null ||
                    GetProductByIdData?.GeographicalData?.city === "" ? null : (
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}>City</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.GeographicalData?.city}
                        </Typography>
                      </Grid>
                    )}
                    {GetProductByIdData?.GeographicalData?.landmark ===
                      undefined ||
                    GetProductByIdData?.GeographicalData?.landmark === null ||
                    GetProductByIdData?.GeographicalData?.landmark ===
                      "" ? null : (
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Typography sx={tableHeader}> Landmark</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.GeographicalData?.landmark}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                {GetProductByIdData?.OtherCost &&
                GetProductByIdData?.OtherCost?.length !== 0 ? (
                  <Box mt={2}>
                    <Typography
                      sx={{
                        ...product,
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: "30px",
                      }}
                    >
                      Additional Cost
                    </Typography>
                    {GetProductByIdData?.OtherCost?.length === 0
                      ? ""
                      : GetProductByIdData?.OtherCost?.map((cost) => {
                          const newValue = cost?.CostPrice.toFixed(2);
                          return (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "60px",
                                  mt: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    minWidth: "160px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...fetchValue,
                                    }}
                                  >
                                    {" "}
                                    {cost?.ReasonOfCost}{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    minWidth: "160px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...fetchValue,
                                    }}
                                  >
                                    HSN - {cost?.AdCostHSN}{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    minWidth: "160px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...fetchValue,
                                    }}
                                  >
                                    GST - {cost?.AdCostGST} %
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    minWidth: "160px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...fetchValue,
                                    }}
                                  >
                                    {cost?.AdCostApplicableOn === "All"
                                      ? "One Time Cost "
                                      : "Per Unit"}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    gap: "5px",
                                    minWidth: "160px",
                                    display: "flex",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...fetchValue,
                                    }}
                                  >
                                    {newValue}
                                  </Typography>
                                  <Typography>
                                    {cost.currencyType === "BXITokens" ? (
                                      <Box
                                        component="img"
                                        src={BXITokenIcon}
                                        alt="token"
                                        sx={{
                                          height: "auto",
                                          width: "15px",
                                          marginTop: "6px",
                                        }}
                                      />
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: "20px",
                                          ml: 1,
                                        }}
                                      >
                                        ₹
                                      </Typography>
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                  </Box>
                ) : null}

                {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks
                  .length === 0 ? null : (
                  <>
                    <Box sx={{ mt: 3 }}>
                      <Typography sx={cost}>Remarks </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          gap: "10px",
                          mt: 1,
                        }}
                      >
                        {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks.map(
                          (item) => {
                            return (
                              <>
                                <Typography sx={otherCostText}>
                                  {item}
                                </Typography>
                              </>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Box>
              <Typography sx={{ ...pack, color: "#156DB6" }}>
                Technical Information
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <Typography sx={inclusiveheader}>
                  Supporting you would give to buyer
                </Typography>
                {GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                  ? Object?.keys(
                      GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                    ).map((el, idx) => {
                      if (
                        GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer[
                          el
                        ] === "on"
                      ) {
                        return (
                          <>
                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 400,
                                fontSize: "16px",
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              {" "}
                              {/* <span>{idx + 1}</span> */}
                              {el}
                              {/* {
                                  GetProductByIdData
                                    ?.WhatSupportingYouWouldGiveToBuyer[el]
                                } */}
                            </Typography>
                            {/* <Typography> </Typography> */}
                          </>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}{" "}
              </Box>

              <Box>
                <Typography sx={inclusiveheader}>
                  Dimensions of Ad / Content Needed
                </Typography>
                <Box>
                  <Typography sx={dots}>
                    {GetProductByIdData?.Dimensions}
                  </Typography>
                </Box>
              </Box>

              {GetProductByIdData?.UploadLink ? (
                <Box>
                  <Typography sx={inclusiveheader}>
                    Content Upload Link
                  </Typography>
                  <Box>
                    <a
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#445FD2",
                      }}
                    >
                      {GetProductByIdData?.UploadLink}
                    </a>
                    <br />
                  </Box>
                </Box>
              ) : null}

              <Box>
                <Typography sx={inclusiveheader}>
                  Inventory Available Dates
                </Typography>
                <Box sx={{ pt: "0.8%", display: "flex", gap: "10%" }}>
                  <Typography sx={dateMonth}>Start Date</Typography>
                  <Typography sx={dateMonth}>End Date</Typography>
                </Box>

                <Box
                  sx={{
                    overflow: "auto",
                    marginRight: "900px",
                    "::-webkit-scrollbar": {
                      display: "flex",
                    },
                    "::-webkit-scrollbar-thumb": {
                      dynamic: "#8d8e90",
                      minHeight: "10px",
                      borderRadius: "8px",
                    },
                    "::-webkit-scrollbar-thumb:vertical": {
                      maxHeight: "10px",
                    },
                    maxHeight:
                      GetProductByIdData?.calender?.length < 10
                        ? "auto"
                        : "200px",
                    height:
                      GetProductByIdData?.calender?.length < 10
                        ? "auto"
                        : "210px",
                  }}
                >
                  <Stack>
                    <Box sx={{ pt: "0.8%", display: "flex", gap: "10%" }}>
                      <Typography sx={valDateMonth}>
                        {GetProductByIdData?.calender?.map((el, idx) => {
                          return (
                            <>
                              <Typography sx={valDateMonth}>
                                {new Date(el.startDate).toLocaleDateString(
                                  "en-US",
                                  options
                                )}{" "}
                              </Typography>
                            </>
                          );
                        })}
                      </Typography>
                      <Typography sx={{ ...valDateMonth, ml: 10 }}>
                        {GetProductByIdData?.calender?.map((el, idx) => {
                          return (
                            <>
                              <Typography sx={valDateMonth}>
                                {new Date(el.endDate).toLocaleDateString(
                                  "en-US",
                                  options
                                )}{" "}
                              </Typography>
                            </>
                          );
                        })}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              {GetProductByIdData?.tags === null ||
              GetProductByIdData?.tags === undefined ? null : (
                <Box
                  mt={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography sx={inclusiveheader}>Tags</Typography>

                  <Typography
                    sx={{
                      ...packHead,
                      color: "#6B7A99",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {GetProductByIdData?.tags.map((data, id) => {
                      return (
                        <span>
                          {id + 1}) {data},{" "}
                        </span>
                      );
                    })}
                  </Typography>
                </Box>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="4">
            {/* {ProductFeatures?.map((res) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "200px",
                  }}
                >
                  <Box component="img" src={bxifeature} />
                  <Typography sx={{ ...TypographyTitleText }}>
                    {res.name}
                  </Typography>
                  <Typography sx={{ ...TypographyTitleText }}>
                    {res.description}
                  </Typography>
                </Box>
              );
            })} */}
            <Box>
              <Typography sx={{ ...pack, color: "#156DB6" }}>
                Key Features
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid
                  container
                  mt={0.5}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  {ProductFeatures?.map((res) => {
                    return (
                      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                        <Box
                          sx={{
                            // px: 2,
                            display: "flex",
                            // flexWrap: "wrap",
                            textAlign: "start",
                            flexDirection: "row",
                            gap: "100px",
                            mt: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "20px",
                              width: "100%",
                            }}
                          >
                            {/* <Box
                                      component="img"
                                      src={bxifeature}
                                      sx={{ height: "80px", width: "30px" }}
                                    /> */}
                            <FeatureName name={res?.name} />
                            <Box
                              sx={{
                                width: "80%",
                                maxWidth: "825px",
                                height: "auto",
                                wordWrap: "break-word",
                              }}
                            >
                              <Typography sx={{ ...packHead, color: "grey" }}>
                                {res.name}
                              </Typography>
                              <Typography
                                sx={{
                                  ...packVal,
                                }}
                              >
                                {res.description}{" "}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </TabPanel>
        </TabContext>
      </Paper>
    </React.Fragment>
  );
};

export default ElectronicProductPreview;

const mainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  color: "#6B7A99",
  p: 3,
};

const tabTexts = {
  textTransform: "none",
  fontSize: {
    xl: "1rem",
    lg: "1rem",
    md: "1rem",
    sm: "0.8rem",
    xs: "0.8rem",
  },
};

const semiSub = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "40px",
  color: "#6B7A99",
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "12px",
    sm: "12px",
    xs: "12px",
  },
  mt: 2,
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const tableHeader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1.2rem",
    xs: "1rem",
  },
  color: "#6B7A99",
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const tableData = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  color: "#B1B1B1",
  lineHeight: "4rem",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 200,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1.2rem",
    xs: "1rem",
  },
  color: "#6B7A99",
};

const listText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#6B7A99",
};

const pack = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.2rem",
    sm: "1rem",
    xs: "1rem",
  },
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  color: "#6B7A99",
};

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "0.8rem",
    lg: "0.8rem",
    md: "0.8rem",
    sm: "1rem",
    xs: "1rem",
  },
  color: "#ADB8CC",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1rem",
    lg: "1rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  color: "#6B7A99",
};

const uploadBtn = {
  width: "100%",
  background: "rgba(68, 95, 210, 1)",
  "&:hover": {
    background: "rgba(68, 95, 210, 1)",
  },
  color: "#fff",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 18,
  borderRadius: "0.6rem",
  textTransform: "none",
  mb: "4rem",
};

const ProductVariationStyle = {
  width: "auto",
};

const HeaderContainerStyle = { px: "2rem" };
const PageHeader = {
  display: "flex",
  width: "100%",
  py: "20px",
  position: "relative",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
};

const AppBarTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "16px",
    xs: "12px",
  },
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  px: "15%",
  color: "#4D4D4D",
};

const fixGrid = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const chart = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1rem",
    xs: "1rem",
  },
  lineHeight: "5rem",
  color: "#445FD2",
};

const semi = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#4D4D4D",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
};

const tabSubText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "14px",
    xs: "14px",
  },
  width: "95%",
  textAlign: "justify",
  color: "#6B7A99",
};

const fetchValue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "14px",
  color: "#B1B1B1",
  marginTop: "7px",
};

const inclusiveheader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "18px",
    lg: "18px",
    xl: "18px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
  pt: "2%",
};

const valDateMonth = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  color: "#6B7A99",
};
const cost = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 20,
  color: "#6B7A99",
};

const dots = {
  display: "flex",
  gap: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "20px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  textAlign: "justify",
  color: "#6B7A99",
};

const otherCostText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  color: "#6B7A99",
};
const dateMonth = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 16,
  color: "#6B7A99",
};

const DescriptionAnswerText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "12px",
    sm: "10px",
    xs: "8px",
  },
  textAlign: "justify",
  color: "#6B7A99",
  py: {
    xl: "16px",
    lg: "16px",
    md: "12px",
    sm: "10px",
    xs: "8px",
  },
};

const TypographyTitleText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "16px",
    xs: "16px",
  },
  color: "#6B7A99",
  // py: "8px",
};
