import React, { useEffect, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import { Box } from "@material-ui/core";
import { VerticalBarSeries } from "react-vis";
import "/node_modules/react-vis/dist/style.css"; // Remove the leading '/'

const BAR_RADIUS = 15; // Border radius for the top of the bars

const getBarStyle = (bar) => ({
  borderRadius: `${BAR_RADIUS}px ${BAR_RADIUS}px 0 0`, // Apply rounded corners only at the top
});

const CustomDiscreteColorLegend = ({ items }) => {
  return (
    <div
      style={{
        margin: "10px 0",
        display: "flex",
        width: "700px",
        justifyContent: "space-around",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: item.color,
              marginRight: "5px",
              borderRadius: "50%",
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

const TotalSignups = () => {
  const BarSeries = VerticalBarSeries;

  const [hoveredBar, setHoveredBar] = useState(null);
  const [label, setLabel] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleBarHover = (value) => {
    setHoveredBar(value);
  };

  const legends = [{ title: "Total SignUp", color: "#1D4ED8" }];

  useEffect(() => {
    const fetchData = async (selectedFilter) => {
      setIsLoading(true);
      let apiUrl = "";

      // Define your API endpoints based on the selected filter
      switch (selectedFilter) {
        case "last-week":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUpWeek";
          break;
        case "last-month":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUpMonth";
          break;
        case "current-month":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUpCurrentMonth";
          break;
        case "last-three-months":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUp3Months";
          break;
        case "last-six-months":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUp6Months";
          break;
        case "last-year":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUp12Months";
          break;
        case "current-year":
          apiUrl =
            "/api/v1/admin/AdminCompanyDetails/TotalSignUpCurrent12Months";
          break;
        case "last-five-years":
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUpYears";
          break;
        default:
          apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalSignUpWeek";
      }

      try {
        const res = await axios.get(apiUrl, {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        });

        if (res.data.label && res.data.TotalSignUpData) {
          setLabel(res.data.label);
          setData(res.data.TotalSignUpData);
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(filter); // Fetch data when the component mounts and when the filter changes
  }, [filter]);

  const activeCompaniesArray = data?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#1D4ED8",
  }));

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">Total Sign Ups</h3>
        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XYPlot
            height={350}
            width={700}
            stackBy="y"
            margin={{ left: 70, right: 10, top: 10, bottom: 30 }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              tickValues={label?.map((item, index) => index + 1)}
              tickFormat={(v) => label[v - 1]}
            />
            <YAxis tickFormat={(v) => `${v}`} />

            <BarSeries
              barWidth={0.2}
              data={activeCompaniesArray}
              color={"#1D4ED8"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            {hoveredBar && (
              <Hint value={hoveredBar}>
                <div
                  className="grapharrow"
                  style={{ background: `${hoveredBar.color}`, color: "white" }}
                >
                  <p>{hoveredBar.label}</p>
                  <p>{`${hoveredBar.y}`}</p>
                </div>
              </Hint>
            )}
            <CustomDiscreteColorLegend items={legends} />
          </XYPlot>
        </div>
      )}
    </div>
  );
};

export default TotalSignups;
