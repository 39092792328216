import { useQuery } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
// const GetWalletData = () => {
//   return axios.get(
//     '/api/v1/admin/wallet/get-funds-requests/64ca2fe719684000f6672007',
//     {
//       headers: {
//         authorization: `bearer ${GetTokenFunc()}`,
//       },
//     }
//   );
// };

// export const useFetchWallet = () => {
//   return useQuery(['GetWalletDataList'], GetWalletData);
// };
export const useFetchWallet = (id, type, page) => {
  return useQuery(["mainbalance", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/wallet/get-funds-requests/${id}?type=${type}&page=${page}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response;
  });
};

// const GetWalletBalanceData = () => {
//   return axios.get('/api/v1/admin/wallet/get-wallet/64748dd236043f9feac52c11', {
//     headers: {
//       authorization: `bearer ${GetTokenFunc()}`,
//     },
//   });
// };

// export const useFetchWalletBalance = () => {
//   return useQuery(['GetWalletData'], GetWalletBalanceData);
// };

// toal balance
export const useFetchWalletBalance = (id) => {
  return useQuery(["useFetchWalletBalance", id], async () => {
    const response = await axios.get(`/api/v1/admin/wallet/get-wallet/${id}`, {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    });

    return response;
  });
};

export const useFetchWalletSpent = (id) => {
  return useQuery(["useFetchWalletSpent", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/wallet/get-sales-buy-value/${id}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response;
  });
};
// const GetWalletSpentData = () => {
//   return axios.get(
//     '/api/v1/admin/wallet/get-sales-buy-value/64748dd236043f9feac52c11',
//     {
//       headers: {
//         authorization: `bearer ${GetTokenFunc()}`,
//       },
//     }
//   );
// };

// export const useFetchWalletSpent = () => {
//   return useQuery(['GetWalletSpentList'], GetWalletSpentData);
// };

export const useFetchWalletCredit = (id) => {
  return useQuery(["useFetchWalletCredit", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/wallet/get-credit-requests/${id}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response;
  });
};
export const useFetchUSersEscrowBalance = (id) => {
  return useQuery(["useFetchEscrowBalance", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/wallet/get-users-escrow-balance/${id}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response;
  });
};
// const GetWalletCreditData = () => {
//   return axios.get(
//     '/api/v1/admin/wallet/get-credit-requests/64226abc97b700a5ecd3a6a4',
//     {
//       headers: {
//         authorization: `bearer ${GetTokenFunc()}`,
//       },
//     }
//   );
// };

// export const useFetchWalletCredit = () => {
//   return useQuery(['GetWalletCreditList'], GetWalletCreditData);
// };
