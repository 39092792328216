import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Modal,
  Button,
  Tabs,
  Tab,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import BXICOIN from "../../assets/GenieImg/BXICOIN.png";
import * as Styles from "../../common/Styles";
import InputBase from "@mui/material/InputBase";
import axios from "axios";
import { styled, alpha } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GetTokenFunc } from "../../utils/GetToken";
import { Delete, Edit } from "@mui/icons-material";
import CommaSeprator from "../../common/CommaSeparator";
import CompanyTypeComp from "./CompanyTypeComp";
import Searchbar from "../../common/Searchbar";

const Creditline = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [allCredit, setAllCredit] = useState();
  const [searchData, setSearchData] = useState("");
  const [Loader, setLoader] = useState(false);
  const [CreditData, setCreditData] = useState();
  const itemsPerPage = 10;

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const totalItems = allCredit?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginatedData = () => {
    const reversedData = allCredit?.slice().reverse();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return reversedData?.slice(startIndex, endIndex);
  };

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const AllCreditDetails = async () => {
    setLoader(true);
    await axios
      .get(`wallet_funds/get-all-requests?search=${searchData}`)
      .then((res) => {
        setLoader(false);
        setAllCredit(res?.data);
      })
      .catch((err) => {});
  };

  const fetchtransaction = async () => {
    try {
      await axios.get(`wallet/get_all_credit_line_transaction`).then((res) => {
        setCreditData(res?.data?.body);
      });
    } catch (error) {}
  };

  useEffect(() => {
    AllCreditDetails();
    fetchtransaction();
  }, [searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  const calculateSums = (productVariations) => {
    let totalValueSum = 0;
    let maxOrderQtySum = 0;

    productVariations?.forEach((item) => {
      const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

      totalValueSum += currentValue;
      maxOrderQtySum += item?.MaxOrderQuantity;
    });

    return {
      totalValueSum,
      maxOrderQtySum,
    };
  };

  const ProductDataRender = () => {
    return (
      <Paper elevation={0}>
        {getPaginatedData()?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : Loader ? (
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              pl: "45%",
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <Box mt={2} sx={{ background: "#FAFBFC", p: 0.5 }}>
            <Grid
              container
              sx={{
                p: 1,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={0.5}
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No.
              </Grid>
              <Grid
                item
                xl={2.5}
                lg={2.5}
                md={2.5}
                sm={2.5}
                xs={2.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Product Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              <Grid
                item
                xl={0.7}
                lg={0.7}
                md={0.7}
                sm={0.7}
                xs={0.7}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Category
              </Grid>

              <Grid
                item
                xl={0.7}
                lg={0.7}
                md={0.7}
                sm={0.7}
                xs={0.7}
                sx={Styles.textHead}
              >
                Type
              </Grid>
              <Grid
                item
                xl={1.2}
                lg={1.2}
                md={1.2}
                sm={1.2}
                xs={1.2}
                sx={Styles.textHead}
              >
                Requested Amount
              </Grid>

              <Grid
                item
                xl={1.2}
                lg={1.2}
                md={1.2}
                sm={1.2}
                xs={1.2}
                sx={Styles.textHead}
              >
                Eligible Amount
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Approved Amount
              </Grid>
              <Grid
                item
                xl={0.8}
                lg={0.8}
                md={0.8}
                sm={0.8}
                xs={0.8}
                sx={Styles.textHead}
              >
                Issued Date
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Status
              </Grid>
              <Grid
                item
                xl={0.4}
                lg={0.4}
                md={0.4}
                sm={0.4}
                xs={0.4}
                sx={Styles.textHead}
              >
                Edit
              </Grid>
            </Grid>

            {getPaginatedData()?.map((item, idx) => {
              const continuousIdx = (currentPage - 1) * itemsPerPage + idx + 1;

              const credittoken =
                (Number(item?.totalPoints + item?.productId?.ManualPoint) /
                  120) *
                100;

              const sums = calculateSums(item?.productId?.ProductsVariantions);

              const highestValue = sums?.totalValueSum;

              const totalValue = ((highestValue * credittoken) / 100).toFixed();

              // const CreditDataFilter = CreditData?.filter(
              //   (item) => item?._user_id === paramId
              // );

              const productData = CreditData?.filter(
                (product) => product?._request_id?.toString() === item?._id
              );

              return (
                <Grid container sx={Styles.containerStyles}>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    align="center"
                    className="product-title-colum"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {continuousIdx}
                  </Grid>
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    align="left"
                    className="product-title-colum"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "#78778B",
                      }}
                    >
                      <Box
                        sx={{
                          width: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        {item?.productId?.ProductImages?.at(0)?.url ? (
                          <>
                            <img
                              src={item?.productId?.ProductImages?.at(0)?.url}
                              alt="company-logo"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#78778B",
                              }}
                            >
                              {item?.productId?.ProductName
                                ? item?.productId?.ProductName
                                : "--"}

                              <br />
                              {item?.clnumber ? (
                                <span
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Credit_Id: {item?.clnumber}
                                </span>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </>
                        ) : item?.productId?.VoucherImages?.at(0)?.url ? (
                          <>
                            <img
                              src={item?.productId?.VoucherImages?.at(0)?.url}
                              alt="company-logo"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#78778B",
                              }}
                            >
                              {item?.productId?.ProductName
                                ? item?.productId?.ProductName
                                : "--"}
                              <br />
                              {item?.clnumber ? (
                                <span
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Credit_Id: {item?.clnumber}
                                </span>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </>
                        ) : (
                          "--"
                        )}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    <span>
                      {item?.productId?.SellerCompanyName
                        ? item?.productId?.SellerCompanyName
                        : "--"}
                    </span>
                  </Grid>

                  <Grid
                    item
                    xl={0.7}
                    lg={0.7}
                    md={0.7}
                    sm={0.7}
                    xs={0.7}
                    sx={Styles.subTextData}
                  >
                    <CompanyTypeComp
                      companyTypeId={item?.productId?.ProductType}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={0.7}
                    lg={0.7}
                    md={0.7}
                    sm={0.7}
                    xs={0.7}
                    sx={Styles.subTextData}
                  >
                    <span>
                      {item?.productId?.ListingType
                        ? item?.productId?.ListingType
                        : "--"}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={1.2}
                    lg={1.2}
                    md={1.2}
                    sm={1.2}
                    xs={1.2}
                    sx={Styles.subTextData}
                  >
                    <span>
                      {item?.requestedUserAmount ? (
                        <CommaSeprator Price={item?.requestedUserAmount} />
                      ) : (
                        "--"
                      )}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={1.2}
                    lg={1.2}
                    md={1.2}
                    sm={1.2}
                    xs={1.2}
                    sx={Styles.subTextData}
                  >
                    {item?.ProductType === "Media" ? (
                      "--"
                    ) : (
                      <span>
                        <CommaSeprator Price={totalValue} />
                      </span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {item?.approvedAmount ? (
                      <CommaSeprator Price={item?.approvedAmount} />
                    ) : (
                      "--"
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={0.8}
                    lg={0.8}
                    md={0.8}
                    sm={0.8}
                    xs={0.8}
                    sx={Styles.subTextData}
                  >
                    {productData.length > 0
                      ? productData?.map((item, idx) => {
                          return item?._document_type === "onhold" ||
                            item?._document_type === "transaction" ? (
                            <Box>
                              {item?._status === true ? (
                                <span>
                                  {item?.createdAt === item?.updatedAt
                                    ? ""
                                    : formatDate(item?.updatedAt)}
                                </span>
                              ) : (
                                ""
                              )}
                            </Box>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={{
                      ...Styles.subTextData,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "11px",
                        color: "#445fd2",
                        background: "#445fd221",
                        width: "100px",
                        height: "30px",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {item?.requestStatus === "accepted"
                          ? "BXI Accepted"
                          : item?.requestStatus === "pending"
                          ? "Request Raised"
                          : item?.requestStatus === "rejected"
                          ? "BXI Rejected"
                          : null}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={0.4}
                    lg={0.4}
                    md={0.4}
                    sm={0.4}
                    xs={0.4}
                    sx={Styles.subTextData}
                  >
                    <IconButton
                      aria-label="edit"
                      style={{ color: "rgba(68, 95, 210, 1)" }}
                      onClick={() => {
                        // handleEditButtonClick(x._id);
                        // setId(x._id);
                        navigate(
                          `/admindashboard/allproductapprovals/${item?.companyId}`,
                          {
                            state: {
                              Index: "CreditRequest",
                              Navigation: true,
                            },
                          }
                        );
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "600px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px", alignItems: "center" }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/admindashboard/allrecords");
          }}
        >
          <KeyboardBackspaceIcon />
        </Box>
        <Box className="page-heading">
          <h5>All Credit line</h5>
          <Typography>
            Click on Edit & You will be directed to the Application Approval
            Page
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box
          className="products-filters-row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              {allCredit instanceof Object ? (
                <Typography sx={textBudge}>
                  Total Products: {allCredit?.length}
                </Typography>
              ) : (
                <Typography sx={textBudge}>Total Requests: 0</Typography>
              )}
            </Typography>
          </Box>
          <Searchbar onSendData={handleChildData} />
        </Box>
        {/* {CreditlineLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
              width: "100%",
              // mr: '150%',
              ml: "500px",
            }}
          >
            <PageLoader />
          </Box>
        ) : ( */}
        <Box>{ProductDataRender()}</Box>
        {/* )} */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
          mx: "auto",
        }}
        mt={2}
      >
        <Stack>
          <StyledPagination
            size="medium"
            count={totalPages}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default Creditline;

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
