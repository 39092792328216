import React, { useState, useEffect } from "react";
import { Typography, Box, Modal, Stack, Paper, Grid } from "@mui/material";
import filter from "../../assets/filter.png";
import * as Styles from "../../common/Styles";
import axios from "axios";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AllCompanysDetails } from "../../redux/action/Company&User/AllCompanys";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useFetchAllChats } from "../../Hooks/AllRecords/useAllRecords";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import PageLoader from "../CommonActions/PageLoader";
import { useNavigate } from "react-router-dom";
import { Divider } from "@material-ui/core";

const Chats = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "0px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "0px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const [AllCompanyName, setAllCompanyName] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [openView, setOpenView] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const handleViewOpen = () => setOpenView(true);
  const handleViewClose = () => setOpenView(false);

  const handleFilterOpen = () => setOpenFilter(true);
  const handleFilterClose = () => setOpenFilter(false);
  const {
    data: fetchChat,
    isLoading: chatLoading,
    refetch: chatRefetch,
  } = useFetchAllChats(currentPage, selectedCompany);

  useEffect(() => {
    if (fetchChat && fetchChat?.totalCount !== undefined) {
      setInitialPaginationCount(fetchChat?.totalPages);
    }
  }, [fetchChat?.totalCount]);

  const dispatch = useDispatch();
  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllCompanyName(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);
  useEffect(() => {
    chatRefetch();
  }, [selectedCompany, currentPage]);
  const handleCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCompany(selectedValue);
    handleFilterClose();
  };
  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{ justifyContent: "start", gap: "20px", alignItems: "center" }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon />
          </Box>
          <Box className="page-heading">
            <h5>All chat</h5>
            <Typography>Explore all the data by applying filters.</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            background: "rgba(250, 251, 252, 1)",
            p: 2,
            borderTop: "2px solid rgba(245, 245, 245, 1)",
            mt: 2,
          }}
        >
          <Box
            className="products-filters-row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 0,
            }}
          >
            <Box sx={buddge}>
              <Typography sx={textBudge}>
                {fetchChat instanceof Object ? (
                  <Typography sx={textBudge}>
                    Total Chat(s): {fetchChat?.totalCount}
                  </Typography>
                ) : (
                  <Typography sx={textBudge}>Total Chat(s): 0</Typography>
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                width: "40%",
              }}
            >
              <Box sx={Styles.filtersDesign} onClick={handleFilterOpen}>
                <img
                  src={filter}
                  alt=""
                  style={{ height: "auto", width: "15px" }}
                />
                Filters
              </Box>{" "}
            </Box>
          </Box>
          <div
            style={{
              background: chatLoading
                ? "rgba(250, 251, 252, 1)"
                : "transparent",
              padding: "0px",
              borderRadius: "20px",
            }}
          >
            {chatLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  ml: "500px",
                  mt: 20,
                }}
              >
                <PageLoader />
              </Box>
            ) : (
              <React.Fragment>
                <Grid
                  container
                  sx={{
                    p: 2,
                  }}
                >
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    No.
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Company (Sender)
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Company (Receiver)
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Date
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Time
                  </Grid>
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Last Message
                  </Grid>
                </Grid>

                {/* <Divider /> */}

                {fetchChat?.allchats?.map((x, i) => {
                  return (
                    <Grid
                      container
                      sx={{
                        p: 2,
                        borderBottom: "1px solid rgba(208, 213, 221, 0.3)",
                      }}
                    >
                      <Grid
                        item
                        xl={0.5}
                        lg={0.5}
                        md={0.5}
                        sm={0.5}
                        xs={0.5}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            color: "#1B212D",
                            fontFamily: "Poppins",
                            fontSize: 12,
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          {i + 1}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={chatText}>{x?.senderName}</Typography>
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={chatText}>
                          {x?.senderName === "BXI World Pvt Ltd"
                            ? x?.chat?.sendName
                            : x?.chat?.myName}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={chatText}>
                          {new Date(x?.createdAt).toLocaleDateString("en-GB", {
                            timeZone: "UTC",
                          })}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={chatText}>
                          {new Date(x?.createdAt).toLocaleTimeString("en-GB", {
                            timeZone: "UTC",
                          })}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={2.5}
                        lg={2.5}
                        md={2.5}
                        sm={2.5}
                        xs={2.5}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={chatText}>{x?.content}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </React.Fragment>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              mt={0.8}
            >
              <Stack spacing={2}>
                <StyledPagination
                  size="small"
                  count={initialPaginationCount}
                  color="primary"
                  page={currentPage}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                  strokeWidth={currentPage}
                />
              </Stack>
            </Box>
          </div>{" "}
          <Modal
            open={openFilter}
            // onClose={handleFilterClose}
          >
            <Box sx={FilterStyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Filters
                </Typography>
                <Box
                  component="img"
                  src={CloseIcon}
                  sx={{
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                  onClick={() => {
                    handleFilterClose();
                  }}
                />
              </Box>
              <Box className="marketplace-filters">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <select
                    onChange={handleCompanyChange}
                    value={selectedCompany}
                  >
                    <option value={""}>All</option>
                    {AllCompanyName.map((name, index) => (
                      <option key={index} value={name?.companyName}>
                        {name?.companyName}
                      </option>
                    ))}
                  </select>
                  {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                  mt: 2,
                  textTransform: 'none',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleFilterClose();
                  }}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  Cancel
                </Button>
              </Box> */}
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Paper>
    </div>
  );
};

export default Chats;
const chatText = {
  color: "#1B212D",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
};
const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};
const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};
