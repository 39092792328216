import axios from "axios";
import React, { useEffect, useState } from "react";
import { GetTokenFunc } from "../../utils/GetToken";

const CreditDueComp = (props) => {
  const [Creditdue, setCreditdue] = useState();
  const GetCreditduebalance = async (props) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/wallet/get-wallet/${props?.companyId}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      setCreditdue(response.data.body.creditDue);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetCreditduebalance(props);
  }, [props]);

  return <div>{Creditdue?.toFixed(2) || 0}</div>;
};

export default CreditDueComp;
