import { useQuery } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
// let currentPage = 1;

export const useFetchInvoice = (id, currentPage, search) => {
  return useQuery(["invoice", id], async () => {
    const response = await axios.get(
      // `/api/v1/admin/invoice/get-companies-buy-invoices/${id}?page=${currentPage}&pageSize=10`,
      `/api/v1/admin/invoice/get-companies-buy-transactions/${id}?page=${currentPage}&pageSize=10&search=${search}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response.data;
  });
};
export const useFetchInvoiceList = (id, currentPage, search) => {
  return useQuery(["invoice", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/invoice/get-companies-buy-invoices/${id}?page=${currentPage}&pageSize=10&search=${search}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response.data;
  });
};

export const useFetchSaleInvoice = (id, sellCurrentPage, search) => {
  return useQuery(["invoicesell", id], async () => {
    const response = await axios.get(
      // `/api/v1/admin/invoice/get-companies-sell-invoices/${id}?page=${sellCurrentPage}&pageSize=10`,
      `/api/v1/admin/invoice/get-companies-sell-transactions/${id}?page=${sellCurrentPage}&pageSize=10&search=${search}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );
    return response.data;
  });
};
export const useFetchSaleInvoiceList = (id, sellCurrentPage, search) => {
  return useQuery(["invoicesell", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/invoice/get-companies-sell-invoices/${id}?page=${sellCurrentPage}&pageSize=10&search=${search}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );
    return response.data;
  });
};
// const useGetSaleInvoice = () => {
//   return axios.get(
//     '/api/v1/admin/invoice/get-companies-sell-invoices/647209c40751cc3b0c2a641e',
//     {
//       headers: {
//         authorization: `bearer ${GetTokenFunc()}`,
//       },
//     }
//   );
// };

// export const useFetchSaleInvoice = () => {
//   return useQuery(['useGetSaleInvoiceList'], useGetSaleInvoice);
// };
