import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Chip, Grid, Paper, Tab, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LeftArrowForVoucherDetail from "../../../assets1/Images/CommonImages/LeftArrowForVoucherDetail.svg";
import CarasoulForVoucherDetails from "../.././Marketplace/VoucherViewProduct/CarasoulForVoucherDetails";
import CarouselForProductHorizontal from "../.././Marketplace/viewProducts/CarouselForProductHorizontal";
import BreadCrumbHeader from "../../Marketplace/viewProducts/BreadCrumbHeader";

import { useNavigate, useParams } from "react-router-dom";
// import { useGetProductById } from "../../../../Hooks/GetProducts/useGetProductById";
import { useGetProductById } from "../.././Marketplace/VoucherViewProduct/useGetProductById";
import BXITokenIcon from "../../../assets1/BXITokenIcon.png";
import BlankStar from "../../../assets1/BlankStar.png";
import FilledStar from "../../../assets1/FilledStar.png";
import PDFIcon from "../../../assets1/pdficon.png";
import FeatureName from "../.././Marketplace/viewProducts/FeatureName";
import { styles } from "../../Marketplace/VoucherViewProduct/commonStyles";
import useGetCompanyTypeData from "../../../Hooks/CompanyData/useGetCompanyTypeData";

const tableKeys = {
  "Entertainment & Events": [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  FMCG: [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  Textile: [
    { label: "Validity", value: "validityOfVoucherValue" },
    { label: "Min Order QTY", value: "MinOrderQuantity" },
    { label: "Max Order Qty", value: "MaxOrderQuantity" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
  ],
  Electronics: [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  Mobility: [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  Lifestyle: [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  "Office Supply": [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  Hotel: [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  "Airlines Tickets": [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
  QSR: [
    { label: "Price/Voucher", value: "PricePerUnit" },
    { label: "Total QTY", value: "TotalAvailableQty" },
    { label: "HSN", value: "HSN" },
    { label: "GST", value: "GST" },
    { label: "Min", value: "MinOrderQuantity" },
    { label: "Max", value: "MaxOrderQuantity" },
    { label: "Total Uploaded Value", value: "TotalValueUploaded" },
    { label: "Validity", value: "validityOfVoucherValue" },
  ],
};

const VoucherTypeTwo = () => {
  const classes = styles();

  const [value, setValue] = React.useState("1");
  const naviagte = useNavigate();

  const id = useParams().id;
  const [storeTechnicalInfo, setStoreTechnicalInfo] = useState();
  const [storeVariationData, setStoreVariationData] = useState();
  const [GetProductByIdData, setGetProductByIdData] = useState();
  const [ProductData, setProductData] = useState(null);
  const [CompanyData, setCompanyData] = useState();

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const GetCompanyByID = async (data) => {
    try {
      const response = await axios.get(
        `company/get_company/${data?.SellerCompanyId}`,
        {
          withCredentials: true,
        }
      );
      setCompanyData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProductData = async () => {
    await axios
      .get(`product/get_product_byId/${id}`)
      .then((res) => {
        setProductData(res?.data);
        GetCompanyByID(res?.data);
      })
      .catch((err) => {});
  };

  const {
    data: companyTypeData,
    // isLoading: CompanyTypeDataLoading,
    // error: CompanyTypeDataError,
    // refetch: CompanyTypeDataRefetch,
  } = useGetCompanyTypeData(CompanyData?.companyType);

  useEffect(() => {
    getProductData();
  }, [companyTypeData]);

  const [selectedPrice, setSelectedPrice] = useState(null);
  const [distictPrice, setDistictPrice] = useState([]);
  const [filteredProducts, setfilteredProducts] = useState([]);

  useEffect(() => {
    if (ProductData && ProductData.ProductsVariantions) {
      let products = ProductData.ProductsVariantions;
      let distictPrice = [
        ...new Set(products.map((item) => item.PricePerUnit)),
      ];
      setDistictPrice(distictPrice);
      setSelectedPrice(distictPrice[0]);
    }
  }, [ProductData]);

  useEffect(() => {
    if (selectedPrice) {
      let products = ProductData.ProductsVariantions;
      let filteredProducts = selectedPrice
        ? products.filter((item) => item.PricePerUnit === selectedPrice)
        : products;
      setfilteredProducts(filteredProducts);
    }
  }, [selectedPrice]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [one, setone] = React.useState();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/home/message");
  };

  let offerSpecific = 3;
  localStorage.getItem("digitalData") === "Offer Specific"
    ? (offerSpecific = 3)
    : (offerSpecific = 4);

  const displayRatingStar = () => {
    const max_star = 5;
    const filled_star = parseInt(ProductData?.HotelStars);
    const blank_star = parseInt(max_star - filled_star);
    const arrFilledStar = new Array(filled_star).fill(FilledStar);
    const arrBlankStar = new Array(blank_star).fill(BlankStar);
    return (
      <div>
        {arrFilledStar.map((src, index) => (
          <img key={index} src={src} />
        ))}
        {arrBlankStar.map((src, index) => (
          <img key={index} src={src} />
        ))}
      </div>
    );
  };

  const renderCellValue = (row, key) => {
    switch (key.value) {
      case "PricePerUnit":
        return <>{row[key]?.toLocaleString("en-US")}</>;
      case "ProductColor":
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}>
              {" "}
              <input
                value={row[key.value]}
                type="color"
                disabled
                style={{
                  height: "30px",
                  width: "30px",
                  border: "1px",
                }}
              />{" "}
            </Typography>
          </div>
        );

      case "validityOfVoucherValue":
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}>
              {" "}
              {row[key.value]} &nbsp; {row["validityOfVoucherUnit"]}{" "}
            </Typography>
          </div>
        );
      case "TotalValueUploaded":
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}>
              {" "}
              {row[key.value].toLocaleString("en-US")}
            </Typography>
          </div>
        );
      case "MinOrderQuantity":
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}>
              {" "}
              {row[key.value].toLocaleString("en-US")}
            </Typography>
          </div>
        );
      case "MaxOrderQuantity":
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}>
              {" "}
              {row[key.value].toLocaleString("en-US")}
            </Typography>
          </div>
        );
      case "GST":
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}> {row[key.value]} %</Typography>
          </div>
        );
      default:
        return (
          <div
            style={{
              margin: "0px 40px 40px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography sx={CommonTypoStyle10}> {key.label} </Typography>
            <Typography sx={CommonTypoStyle11}> {row[key.value]} </Typography>
          </div>
        );
    }
  };

  const renderPrice = (price) => {
    return (
      <>
        {price.toLocaleString("en-US")}
        &nbsp;
        <img
          src={BXITokenIcon}
          style={{
            width: "20px",
            height: "20px",
          }}
          alt="BXITokenIcon"
        />
      </>
    );
  };

  // ProductData.redemptionType
  const getRedimptionType = () => {
    switch (ProductData?.redemptionType) {
      case "online":
        return "Online";
      case "offline":
        return "Offline";
      case "both":
        return "Online and Offline";
      default:
        return "-";
    }
  };

  return (
    <Paper
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText="GiftCard Voucher" />
      <Typography
        sx={{
          borderBottom: "2px solid rgba(236, 236, 236, 1)",
        }}
      ></Typography>
      <Box
        sx={{
          width: "95%",
          mx: "auto",
          background: "#fff",
          padding: "2rem",
          borderRadius: "20px",
        }}
      >
        <Grid container>
          {/* **************************** CarasoulForVoucherDetails ********************************** */}
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }}>
            {ProductData?.ProductImages &&
            ProductData?.ProductImages.length > 0 ? (
              <CarouselForProductHorizontal
                ImageDataArray={ProductData?.ProductImages}
              />
            ) : (
              ProductData?.VoucherImages &&
              ProductData.VoucherImages.length > 0 && (
                <CarouselForProductHorizontal
                  ImageDataArray={ProductData?.VoucherImages}
                />
              )
            )}
          </Grid>

          <Grid item xl={5} lg={5} md={5} sm={12} xs={12} sx={{ mt: 1 }}>
            <Box
              sx={{
                // background: "pink",
                width: "100%",
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  //   background: "red",
                  paddingTop: {
                    xl: "0rem",
                    lg: "0rem",
                    md: "0rem",
                    sm: "4rem",
                    xs: "4rem",
                  },
                  display: "grid",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "16px",
                      xs: "16px",
                    },
                    color: "#4D4D4D",
                    textAlign: {
                      xl: "left",
                      lg: "left",
                      md: "left",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                >
                  {ProductData?.ProductName}
                </Typography>
                <div
                  style={{
                    margin: "0px 80px 10px 0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    sx={CommonTypoStyle11}
                    style={{ display: "flex" }}
                    className={classes.priceCard}
                  >
                    {distictPrice &&
                      distictPrice.length > 0 &&
                      distictPrice.map((color) => (
                        <Chip
                          key={color}
                          label={renderPrice(color)}
                          style={{
                            background: "none",
                            fontSize: "16px",
                            border: "1px solid #CCCCCC",
                            marginRight: "5px",
                            marginBottom: "10px",
                            cursor: "pointer",
                            borderRadius: "11px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          sx={
                            color == selectedPrice
                              ? { border: "1px solid #445FD2 !important" }
                              : {}
                          }
                          onClick={() => setSelectedPrice(color)}
                        />
                      ))}
                  </Typography>
                </div>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "10px",
                    textTransform: "none",
                    color: "#6B7A99",
                  }}
                >
                  All prices are inclusive of Taxes
                </Typography>{" "}
                <br />
              </Box>
              {companyTypeData?.data?.CompanyTypeName === "Hotel" &&
                ProductData?.HotelStars &&
                displayRatingStar()}
              <Grid
                container
                sx={{
                  py: "2rem",
                }}
              >
                {filteredProducts && filteredProducts.length > 0 && (
                  <>
                    {tableKeys &&
                    tableKeys[companyTypeData?.data?.CompanyTypeName] ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            flexWrap: "wrap",
                          }}
                        >
                          {tableKeys[
                            companyTypeData?.data?.CompanyTypeName
                          ].map((item) => {
                            return renderCellValue(filteredProducts[0], item);
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              margin: "0px 40px 40px 0px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography sx={CommonTypoStyle10}>
                              Validity
                            </Typography>
                            <Typography sx={CommonTypoStyle11}>
                              {
                                ProductData.ProductsVariantions[0][
                                  "validityOfVoucher"
                                ]
                              }{" "}
                              {
                                ProductData.ProductsVariantions[0][
                                  "validityOfVoucherUnit"
                                ]
                              }
                            </Typography>
                          </div>
                          <div
                            style={{
                              margin: "0px 40px 40px 0px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography sx={CommonTypoStyle10}>
                              Min order QTY
                            </Typography>
                            <Typography sx={CommonTypoStyle11}>
                              {
                                ProductData.ProductsVariantions[0][
                                  "MinOrderQuantity"
                                ]
                              }
                            </Typography>
                          </div>
                          <div
                            style={{
                              margin: "0px 40px 40px 0px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography sx={CommonTypoStyle10}>
                              Max order QTY
                            </Typography>
                            <Typography sx={CommonTypoStyle11}>
                              {
                                ProductData.ProductsVariantions[0][
                                  "MaxOrderQuantity"
                                ]
                              }
                            </Typography>
                          </div>
                          <div
                            style={{
                              margin: "0px 40px 40px 0px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography sx={CommonTypoStyle10}>
                              Price per Product
                            </Typography>
                            <Typography sx={CommonTypoStyle11}>
                              {ProductData?.ProductsVariantions[0][
                                "PricePerUnit"
                              ]?.toLocaleString("en-US")}
                            </Typography>
                          </div>
                          <div
                            style={{
                              margin: "0px 40px 40px 0px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <Typography sx={CommonTypoStyle10}>
                              GST per Product
                            </Typography>
                            <Typography sx={CommonTypoStyle11}>
                              {ProductData.ProductsVariantions[0]["GST"]} %
                            </Typography>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: 6 }}>
              <Box
                sx={{
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  typography: "body1",
                }}
              >
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      sx={{ width: "95%" }}
                      variant="fullWidth"
                    >
                      <Tab
                        label={
                          <Typography sx={tabTexts}>Description</Typography>
                        }
                        value="1"
                      />
                      <Tab
                        label={
                          <Typography sx={tabTexts}>
                            Technical Information
                          </Typography>
                        }
                        value="2"
                      />
                      <Tab
                        label={
                          <Typography sx={tabTexts}>Key Features</Typography>
                        }
                        value="3"
                      />
                    </TabList>
                  </Box>
                  <Box>
                    <TabPanel value="1">
                      {/* main container for Desc section starts */}
                      <Box>
                        {/* general info section starts */}
                        <Box style={{ marginBottom: "25px" }}>
                          {/*   <Typography className={classes.sectionTitleColored}>
                          {ProductData?.ProductName}
                    </Typography> */}
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              color: "#6B7A99",
                              fontFamily: "Poppins",
                              lineHeight: "30px",
                            }}
                          >
                            {ProductData?.ProductSubtitle ||
                              ProductData?.ProductSubtittle}
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#6B7A99",
                              fontFamily: "Poppins",
                              lineHeight: "30px",
                            }}
                          >
                            {ProductData?.ProductDescription}
                          </Typography>
                        </Box>
                        {/* general info section ends */}

                        <Box>
                          <Typography className={classes.sectionTitleColored}>
                            Redeemed by buyer
                          </Typography>

                          <Typography
                            style={{
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              color: "#B1B1B1",
                            }}
                          >
                            {ProductData?.redeemedValue}
                          </Typography>
                        </Box>

                        {/* tech info section starts */}
                        <Box
                          style={{ marginBottom: "25px", marginTop: "20px" }}
                        >
                          <Typography className={classes.sectionTitleColored}>
                            Store Details
                          </Typography>

                          {ProductData?.redemptionType &&
                            (ProductData.redemptionType == "offline" ||
                              ProductData.redemptionType == "both") && (
                              <>
                                <Box style={{ marginBottom: "10px" }}>
                                  <Typography className={classes.SpecificTitle}>
                                    Store Address
                                  </Typography>
                                  <Typography className={classes.titleDesc}>
                                    {ProductData?.Address}&nbsp;
                                    {ProductData?.Area}&nbsp;
                                    {ProductData?.Landmark}&nbsp;
                                    {ProductData?.City}&nbsp;
                                    {ProductData?.State}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ width: "650px", marginBottom: "10px" }}
                                >
                                  <table style={{ width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <td className={classes.subTitle}>
                                          Area{" "}
                                        </td>
                                        <td className={classes.subTitle}>
                                          Landmark{" "}
                                        </td>
                                        <td className={classes.subTitle}>
                                          City{" "}
                                        </td>
                                        <td className={classes.subTitle}>
                                          State{" "}
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className={classes.titleDesc}>
                                          {ProductData?.Area}{" "}
                                        </td>
                                        <td className={classes.titleDesc}>
                                          {ProductData?.Landmark}{" "}
                                        </td>
                                        <td className={classes.titleDesc}>
                                          {ProductData?.City}{" "}
                                        </td>
                                        <td className={classes.titleDesc}>
                                          {ProductData?.State}{" "}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Box>

                                {ProductData?.HotelsListUrls &&
                                  ProductData?.HotelsListUrls.length > 0 && (
                                    <Box style={{ marginBottom: "10px" }}>
                                      <Typography
                                        className={classes.SpecificTitle}
                                      >
                                        Store List
                                      </Typography>
                                      <Box
                                        component={"a"}
                                        download="hotelsList"
                                        target="_blank"
                                        href={
                                          ProductData?.HotelsListUrls &&
                                          ProductData?.HotelsListUrls[0]?.url
                                            ? ProductData.HotelsListUrls[0].url
                                            : null
                                        }
                                        sx={CommonTypoStyle2}
                                        style={{
                                          marginTop: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          src={PDFIcon}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            marginRight: "10px",
                                          }}
                                          alt=""
                                        />
                                        click to download
                                      </Box>
                                    </Box>
                                  )}
                              </>
                            )}

                          {ProductData?.redemptionType &&
                            (ProductData.redemptionType == "online" ||
                              ProductData.redemptionType == "both") && (
                              <>
                                <Box style={{ marginBottom: "10px" }}>
                                  <Typography className={classes.SpecificTitle}>
                                    Store Link
                                  </Typography>
                                  <a
                                    style={{
                                      color: "#445FD2",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                    }}
                                    target="_blank"
                                    href={
                                      ProductData?.Link ? ProductData?.Link : ""
                                    }
                                  >
                                    {ProductData?.Link ? ProductData?.Link : ""}
                                  </a>
                                </Box>
                              </>
                            )}

                          <Box style={{ marginBottom: "10px", width: "650px" }}>
                            <table style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  <td className={classes.subTitle}>
                                    Redemption Type
                                  </td>
                                  <td className={classes.subTitle}>
                                    Listed this Product for
                                  </td>

                                  {companyTypeData?.data?.CompanyTypeName ===
                                  "Textile" ? (
                                    <td className={classes.subTitle}>Gender</td>
                                  ) : null}

                                  {companyTypeData?.data?.CompanyTypeName ===
                                    "Airlines Tickets" && (
                                    <td className={classes.subTitle}>
                                      How can it be redeemed?
                                    </td>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className={classes.titleDesc}>
                                    {getRedimptionType()}
                                  </td>
                                  <td className={classes.titleDesc}>
                                    {ProductData?.ListThisProductForAmount}{" "}
                                    {ProductData?.ListThisProductForUnitOfTime}{" "}
                                  </td>
                                  {companyTypeData?.data?.CompanyTypeName ===
                                    "Textile" && (
                                    <td className={classes.titleDesc}>
                                      {ProductData?.gender}
                                    </td>
                                  )}
                                  {companyTypeData?.data?.CompanyTypeName ===
                                    "Airlines Tickets" && (
                                    <td className={classes.titleDesc}>
                                      {ProductData?.redeemedValue &&
                                      ProductData?.redeemedValue == "Both"
                                        ? "Domestic & International"
                                        : ProductData?.redeemedValue}
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </Box>
                        </Box>
                        {/* tech info section ends */}

                        {/* additional section starts */}
                        {ProductData?.OtherCost &&
                          ProductData?.OtherCost.length > 0 && (
                            <Box
                              style={{ marginBottom: "25px", width: "800px" }}
                            >
                              <Typography
                                className={classes.sectionTitleColored}
                              >
                                Additional Cost
                              </Typography>
                              <table style={{ width: "75%" }}>
                                <tbody>
                                  {ProductData.OtherCost.map((item, i) => {
                                    return (
                                      <tr>
                                        <td
                                          width="80px"
                                          className={classes.titleDesc}
                                        >
                                          {item?.AdCostApplicableOn
                                            ? item.AdCostApplicableOn
                                            : null}
                                        </td>
                                        <td className={classes.titleDesc}>
                                          {item?.ReasonOfCost
                                            ? item.ReasonOfCost
                                            : null}
                                        </td>
                                        <td
                                          width="120px"
                                          className={classes.titleDesc}
                                        >
                                          {item?.AdCostHSN ? (
                                            <>HSN - {item.AdCostHSN} </>
                                          ) : null}
                                        </td>
                                        <td
                                          width="100px"
                                          className={classes.titleDesc}
                                        >
                                          {item?.AdCostGST ? (
                                            <>GST - {item.AdCostGST} %</>
                                          ) : (
                                            "GST -  0 %"
                                          )}
                                        </td>
                                        <td
                                          width="130px"
                                          className={classes.titleDesc}
                                        >
                                          {item?.AdCostApplicableOn ? (
                                            <>
                                              {" "}
                                              {item.currencyType} &nbsp;{" "}
                                              {item.CostPrice}
                                            </>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Box>
                          )}
                        {/* additional section ends */}
                      </Box>

                      <Typography className={classes.sectionTitleColored}>
                        Technical Information
                      </Typography>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Inclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.Inclusions}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Exclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.Exclusions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Terms & Conditions
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {/*       {ProductData && ProductData["Terms and Conditions"]} */}
                            {ProductData?.TermConditions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Redemption Steps
                        </Typography>
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.RedemptionSteps}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Tags
                        </Typography>
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            {ProductData?.Tags ? (
                              <>
                                {ProductData?.Tags.map((data, id) => {
                                  return (
                                    <span>
                                      {id + 1}) {data},{" "}
                                    </span>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {ProductData?.ProductTechInfo?.Tags.map(
                                  (data, id) => {
                                    return (
                                      <span>
                                        {id + 1}) {data},{" "}
                                      </span>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography sx={pack}>Key Features</Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid
                            container
                            mt={2}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {ProductData?.ProductFeatures?.map((res) => {
                              return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                                  <Box
                                    sx={{
                                      // px: 2,
                                      display: "flex",
                                      // flexWrap: "wrap",
                                      textAlign: "start",
                                      flexDirection: "row",
                                      gap: "70px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <FeatureName name={res?.name} />
                                      <Box
                                        sx={{
                                          width: "80%",
                                          maxWidth: "825px",
                                          height: "auto",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <Typography
                                          sx={{ ...packHead, color: "grey" }}
                                        >
                                          {res.name}
                                        </Typography>
                                        <Typography sx={packVal}>
                                          {res.description}{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>

                      {/* main container for Desc section ends */}
                    </TabPanel>
                    <TabPanel value="2">
                      <Typography className={classes.sectionTitleColored}>
                        Technical Information
                      </Typography>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Inclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.Inclusions}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Exclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.Exclusions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Terms & Conditions
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.TermConditions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Redemption Steps
                        </Typography>
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {ProductData?.RedemptionSteps}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.SpecificTitle}>
                            Tags
                          </Typography>
                          <Box sx={{ padding: "10px" }}>
                            <Typography sx={dots} className={classes.titleDesc}>
                              {ProductData?.Tags ? (
                                <>
                                  {ProductData?.Tags.map((data, id) => {
                                    return (
                                      <span>
                                        {id + 1}) {data},{" "}
                                      </span>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {ProductData?.ProductTechInfo?.Tags.map(
                                    (data, id) => {
                                      return (
                                        <span>
                                          {id + 1}) {data},{" "}
                                        </span>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value="3">
                      <Box>
                        <Typography sx={pack}>Key Features</Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid
                            container
                            mt={4}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {ProductData?.ProductFeatures?.map((res) => {
                              return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                                  <Box
                                    sx={{
                                      // px: 2,
                                      display: "flex",
                                      // flexWrap: "wrap",
                                      textAlign: "start",
                                      flexDirection: "row",
                                      gap: "70px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <FeatureName name={res?.name} />
                                      <Box
                                        sx={{
                                          width: "80%",
                                          maxWidth: "825px",
                                          height: "auto",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <Typography
                                          sx={{ ...packHead, color: "grey" }}
                                        >
                                          {res.name}
                                        </Typography>
                                        <Typography sx={packVal}>
                                          {res.description}{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default VoucherTypeTwo;

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "0.8rem",
    lg: "0.8rem",
    md: "0.8rem",
    sm: "1rem",
    xs: "1rem",
  },
  color: "#ADB8CC",
};
const pack = {
  fontFamily: "poppins",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "30px",
  marginBottom: 1,
  color: "#156db6",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1rem",
    lg: "1rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  color: "#6B7A99",
};

const HeaderIconStyle = {
  border: "1px solid #8C8C8C",
  borderRadius: "6px",
  padding: {
    xl: "14px",
    lg: "14px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
};

const HeaderTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "14px",
    sm: "12px",
    xs: "12px",
  },
  color: "#6B7A99",
};

const HeaderSubTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "12px",
    sm: "10px",
    xs: "10px",
  },
  color: "#B1B1B1",
};

const HeaderButtonStyle = {
  fontFamily: "Poppins",
  width: {
    xl: "50%",
    lg: "50%",
    md: "70%",
    sm: "80%",
    xs: "80%",
  },
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 14,
  color: "#B1B1B1",
  border: "1px solid #8C8C8C",
  borderRadius: "6px",
};

const VoucherDetailSecondGrid = {
  py: "1rem",
};

const inclusiveheader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
  pt: "2%",
};

const dots = {
  display: "flex",
  gap: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "20px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  textAlign: "justify",
  color: "#6B7A99",
};

const ButtonCss = {
  textTransform: "none",
  width: {
    xl: "100%",
    lg: "100%",
    md: "100%",
    sm: "100%",
    xs: "100%",
  },
  height: "auto",
  maxHeight: "49px",
  minHeight: "49px",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  borderRadius: "8px",
  color: "#FFFFFF",
  background: "#445FD2",
  "&:hover": {
    background: "#445FD2",
  },
};
const RedemptionSteps = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
  pt: "3%",
};

const StoreDetails = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
  pt: "2%",
};

const txt = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "20px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  textAlign: "justify",
  color: "#6B7A99",
  pt: "0.5%",
};

const storelist = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
};

const storelink = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
};

const CommonTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  letterSpacing: "1px",
  color: "#6B7A99",
  width: "auto",
};

const CommonTypoStyle1 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "17px",
    xl: "17px",
  },
  letterSpacing: "1px",
  color: "#6B7A99",
  width: "auto",
  marginTop: "2%",
};

const CommonTypoStyle2 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  marginTop: "2%",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "15px",
    xl: "15px",
  },
  letterSpacing: "1px",
  color: "#6B7A99",
  width: "auto",
};

const CommonTypoStyle3 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  marginTop: "6%",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "15px",
    xl: "15px",
  },
  letterSpacing: "1px",
  color: "#6B7A99",
  width: "auto",
};

const CommonTypoStyle4 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  marginTop: "3%",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "17px",
    xl: "17px",
  },
  letterSpacing: "1px",
  color: "#6B7A99",
  width: "auto",
  marginTop: "2%",
};

const tabTexts = {
  textTransform: "none",
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1rem",
    xs: "1rem",
  },
};

const paraContent = {
  fontFamily: "Poppins",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "15px",
    xl: "15px",
  },
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0em",
};
const CommonTypoStyle10 = {
  fontFamily: "Poppins",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "15px",
    xl: "15px",
  },
  fontWeight: "600",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#6B7A99",
};

const CommonTypoStyle11 = {
  fontFamily: "Poppins",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "15px",
    xl: "15px",
  },
  fontWeight: "600",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#B1B1B1",
};
