import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import StacsOfCoinIcon from "../../../assets/CommonImages/BXIToken.svg";
import CommaSeprator from "./CommaSeparator";

const VoucherOrderSummary = ({ VoucherSummaryData, CompanyData }) => {
  const componentRef = useRef();
  const location = useLocation();

  const itemDetails = [
    {
      item: "Seller Name :",
      value:
        VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
    },
    {
      item: "Address :",
      value:
        VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.Address
          ?.AddressLine,
    },
    {
      item: "GSTIN :",
      value: VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.GSTIN,
    },
    {
      item: "State :",
      value:
        VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.Address?.State,
    },
  ];
  const pror = [
    {
      item: "Purchase Order Number :",
      value: "N/A",
    },
    {
      item: "Purchase Order Date :",
      value: new Date(VoucherSummaryData?.PoDate).toDateString(),
    },
  ];
  let Seller_amount = 0;
  let BXI_amount = 0;
  let Coins = 0;
  if (VoucherSummaryData?.IsVoucher) {
    Seller_amount =
      VoucherSummaryData?.INRDetails?.totalGstAmount +
      VoucherSummaryData?.INRDetails?.totalGstInrOfAdCost +
      VoucherSummaryData?.INRDetails?.totalValueInrOfAdCost;

    BXI_amount =
      VoucherSummaryData?.INRDetails?.totalBxiCommission +
      VoucherSummaryData?.INRDetails?.bxiCommisionGST -
      VoucherSummaryData?.INRDetails?.TDS;

    Coins = VoucherSummaryData?.WalletData?._balance;
  }

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let totaladditionalcostGstinrupee = 0;
  let totaladditionalcostGstinbxi = 0;
  let TotalPriceWithoutGST = 0;
  let GrandTotal = 0;
  let TDS = VoucherSummaryData?.INRDetails?.TDS_PERCENT ?? 0.1;

  VoucherSummaryData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    TotalAdditionalCostWtihoutGSTInBXI +=
      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;

    if (
      el &&
      (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
        el?.AdditionCost?.AdditionCostArrayData?.length > 0 ||
        el?.AdditionalCost?.AdditionCostArrayData?.length > 0)
    ) {
      // Choose the correct array to iterate based on availability
      const additionalCostArray = el?.AdditionalCost?.AdditionCostArrayData;

      // Iterate over the array
      additionalCostArray.forEach((item, index) => {
        // Check currency type and calculate total additional cost accordingly
        if (item.currencyType === "₹") {
          totaladditionalcostGstinrupee +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        } else if (item.currencyType === "BXITokens") {
          totaladditionalcostGstinbxi +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        }
      });
    }

    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;

    GrandTotal +=
      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
      el?.TotalPriceWithoutGST
        ? el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
          el?.TotalPriceWithoutGST
        : el?.TotalPriceInBXI;
  });

  let totalTaxAmount =
    VoucherSummaryData?.INRDetails?.totalGstAmount +
    VoucherSummaryData?.INRDetails?.totalGstInrOfAdCost +
    VoucherSummaryData?.INRDetails?.bxiCommisionGST;

  return (
    <Paper
      sx={{
        bgcolor: "#fff",
        boxShadow: "none",
        p: 3,
        borderRadius: "20px",
        height: "auto",
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "auto",
      }}
      elevation={0}
      ref={componentRef}
    >
      <Box ref={componentRef} sx={{ width: "100%" }}>
        <Grid
          container
          gap={location?.pathname.includes("/invoices/invoicedetail") ? 1 : 8}
          sx={{
            py: "20px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Grid
            item
            xl={
              location?.pathname.includes("/invoices/invoicedetail") ? 5.5 : 12
            }
            lg={
              location?.pathname.includes("/invoices/invoicedetail") ? 5.5 : 12
            }
            md={
              location?.pathname.includes("/invoices/invoicedetail") ? 5.5 : 12
            }
            sm={
              location?.pathname.includes("/invoices/invoicedetail") ? 5.5 : 12
            }
            xs={
              location?.pathname.includes("/invoices/invoicedetail") ? 5.5 : 12
            }
            sx={{
              ...gridstyle,
              width: "830px",
              height: "auto",
              position: "relative",
            }}
          >
            <Box sx={mainbox}>
              <Typography sx={headbox}>
                {" "}
                &nbsp;&nbsp;&nbsp;Supplier Details
              </Typography>
              <Typography
                sx={{
                  ...headbox,
                  fontSize: "14px",
                  float: "right",
                  textAlign: "right",
                  mr: "10px",
                }}
              >
                Order: {VoucherSummaryData?.OrderId}
              </Typography>
            </Box>
            <Box sx={contentbox}>
              {itemDetails?.map((el, idx) => {
                return (
                  <Box sx={mapbox} key={idx}>
                    <Typography sx={elitem}>{el.item}</Typography>
                    <Typography
                      sx={{
                        ...elvalue,
                        width: "85%",
                        textAlign: "left",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.value}
                    </Typography>
                  </Box>
                );
              })}

              <Box sx={{ mt: "20px", width: "90%" }}>
                {pror?.map((el, idx) => {
                  return (
                    <Box
                      sx={{
                        ...mapbox,
                        width: "auto",
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "30px",
                      }}
                      key={idx}
                    >
                      <Typography
                        sx={{
                          ...elitem,
                          textAlign: "left",
                        }}
                      >
                        {el.item}
                      </Typography>
                      <Typography
                        sx={{
                          ...elvalue,
                          width: "auto",
                          textAlign: "left",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {el.value}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Typography
                sx={{
                  ...selername,
                  justifyContent: "flex-end",
                  position: "absolute",
                  mt: 8,
                }}
              >
                Code :{" "}
                {
                  VoucherSummaryData?.PurchaseOrderData?.SellerDetails
                    ?.StateCode
                }
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xl={location?.pathname.includes("/invoices/invoicedetail") ? 6 : 12}
            lg={location?.pathname.includes("/invoices/invoicedetail") ? 6 : 12}
            md={location?.pathname.includes("/invoices/invoicedetail") ? 6 : 12}
            sm={location?.pathname.includes("/invoices/invoicedetail") ? 6 : 12}
            xs={location?.pathname.includes("/invoices/invoicedetail") ? 6 : 12}
            sx={{
              ...gridstyle,
              width: "399px",
              height: "100%",
              minHeight: "380px",
            }}
          >
            <Box sx={headbox2}>
              <Typography sx={detailtext}>Coins + INR Details</Typography>
              <Typography sx={detailtext2}>Details with more info</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
              }}
            >
              <Table sx={{ maxWidth: "85%" }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Particulars
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Basic Value
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        GST (INR)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Total (Coins+INR)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Product Cost */}
                  {VoucherSummaryData?.INRDetails?.totalGstAmount !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Product Cost (Coins){" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              TotalPriceWithoutGST
                                ? TotalPriceWithoutGST
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalGstAmount
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalGstAmount
                                ? VoucherSummaryData?.INRDetails
                                    ?.totalGstAmount + TotalPriceWithoutGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Additional Cost (INR)*/}
                  {/* {VoucherSummaryData?.INRDetails?.totalGstInrOfAdCost +
                    VoucherSummaryData?.INRDetails?.totalValueInrOfAdCost !==
                    0 && ( */}
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderLeft: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "left" }}
                      >
                        Additional Cost (INR)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        {" "}
                        <CommaSeprator
                          Price={
                            VoucherSummaryData?.INRDetails
                              ?.totalValueInrOfAdCost
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            totaladditionalcostGstinrupee
                              ? totaladditionalcostGstinrupee
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        {VoucherSummaryData?.INRDetails
                          ?.totalValueInrOfAdCost ? (
                          <>
                            {VoucherSummaryData?.INRDetails
                              ?.totalValueInrOfAdCost !== 0 && (
                              <CommaSeprator
                                Price={
                                  VoucherSummaryData?.INRDetails
                                    ?.totalValueInrOfAdCost +
                                  totaladditionalcostGstinrupee
                                }
                              />
                            )}
                          </>
                        ) : VoucherSummaryData?.POTotals
                            ?.TotalAdditionalCostInRupee ? (
                          <>
                            {VoucherSummaryData?.POTotals
                              ?.TotalAdditionalCostInRupee !== 0 && (
                              <CommaSeprator
                                Price={
                                  VoucherSummaryData?.POTotals
                                    ?.TotalAdditionalCostInRupee +
                                  totaladditionalcostGstinrupee
                                }
                              />
                            )}
                          </>
                        ) : (
                          "0.00"
                        )}
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* )} */}

                  {/* Additional Cost (Coins)*/}
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderLeft: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "left" }}
                      >
                        Additional Cost (Coins)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI
                              ? TotalAdditionalCostWtihoutGSTInBXI
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            totaladditionalcostGstinbxi
                              ? totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI +
                            totaladditionalcostGstinbxi
                              ? TotalAdditionalCostWtihoutGSTInBXI +
                                totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* BXI Commission */}
                  {VoucherSummaryData?.INRDetails?.totalBxiCommission !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          BXI Commission
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalBxiCommission
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.bxiCommisionGST
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalBxiCommission
                                ? VoucherSummaryData?.INRDetails
                                    ?.totalBxiCommission +
                                  VoucherSummaryData?.INRDetails
                                    ?.bxiCommisionGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            VoucherSummaryData?.INRDetails?.WithoutGSTValue
                              ? VoucherSummaryData?.INRDetails?.WithoutGSTValue
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={totalTaxAmount ? totalTaxAmount : 0.0}
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            VoucherSummaryData?.INRDetails?.WithoutGSTValue +
                            totalTaxAmount
                              ? VoucherSummaryData?.INRDetails
                                  ?.WithoutGSTValue + totalTaxAmount
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "57%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                          //   borderRight: "none",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          TDS @{TDS}%
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.TDS
                                ? VoucherSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "71%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={3}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Payment Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              TotalAdditionalCostWtihoutGSTInBXI +
                              TotalPriceWithoutGST -
                              VoucherSummaryData?.INRDetails?.TDS
                                ? TotalAdditionalCostWtihoutGSTInBXI +
                                  TotalPriceWithoutGST -
                                  VoucherSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Coins
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {VoucherSummaryData?.BuyerChoosedTransportation
                            ?.TransportationType === "notransportation" ||
                          VoucherSummaryData?.IsMedia ||
                          VoucherSummaryData?.IsVoucher ? (
                            <CommaSeprator
                              Price={
                                VoucherSummaryData?.INRDetails?.TotalInrToPay
                                  ? VoucherSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                  : 0.0
                              }
                            />
                          ) : (
                            <>
                              {VoucherSummaryData?.INRDetails?.packagingCost ||
                              VoucherSummaryData?.INRDetails?.InsuranceCost ? (
                                <CommaSeprator
                                  Price={
                                    VoucherSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                      ? VoucherSummaryData?.INRDetails
                                          ?.TotalInrToPay
                                      : 0.0
                                  }
                                />
                              ) : (
                                <CommaSeprator
                                  Price={
                                    VoucherSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                      ? VoucherSummaryData?.INRDetails
                                          ?.TotalInrToPay
                                      : 0.0
                                  }
                                />
                              )}
                            </>
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Seller to BXI
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {VoucherSummaryData?.IsMedia ? (
                            <CommaSeprator
                              Price={
                                VoucherSummaryData?.INRDetails
                                  ?.TotalBxiCommission
                                  ? VoucherSummaryData?.INRDetails
                                      ?.TotalBxiCommission +
                                    VoucherSummaryData?.INRDetails
                                      ?.BxiCommisionGST +
                                    VoucherSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          ) : (
                            <CommaSeprator
                              Price={
                                VoucherSummaryData?.INRDetails
                                  ?.totalBxiCommission
                                  ? VoucherSummaryData?.INRDetails
                                      ?.totalBxiCommission +
                                    VoucherSummaryData?.INRDetails
                                      ?.bxiCommisionGST +
                                    VoucherSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  ...elitem,
                  width: "80%",
                  textAlign: "left",
                }}
              >
                <b>NOTE :-</b>
                <br />
                BXI Commission is always charged on Barter Coins.
                <br />
                {TDS}% TDS is collected in INR on Total Gross Value.
                <br />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Paper>
  );
};

export default VoucherOrderSummary;

const headtext = {
  width: "100%",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "15px",
  color: "#6B7A99",
  opacity: 1,
};

const mainbox = {
  width: "100%",
  height: "45px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const headbox = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const contentbox = {
  width: "100%",
  height: "80%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // gap: "3px",
};

const headbox2 = {
  width: "100%",
  height: "45px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const selername = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  opacity: 0.7,
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const elitem = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const totaltext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const totaltextbox = {
  width: "100%",
  height: "10%",
  borderTop: "1px solid rgba(149, 144, 168, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const elvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const detailtext = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const detailtext2 = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  opacity: 0.4,
};

const totaltextsec = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const inrvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "10px",
  textAlign: "center",
  color: "#6B7A99",
};

const gridstyle = {
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px 10px 0px 0",
};

const HeaderIconStyle = {
  // border: "1px solid #8C8C8C",
  // borderRadius: "6px",
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  opacity: 0.5,
  color: "#8384a1",
  opacity: 1,
};
