import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Grid,
  Tabs,
  Tab,
  Divider,
  Stack,
} from "@mui/material";
import deletimg from "../../assets/Recyclebin/deleteimg.png";
import docs from "../../assets/Recyclebin/docs.png";
import * as Styles from "../../common/Styles";
import DropCategory from "../Layouts/DropCategory";
import TrashHeader from "../Layouts/TrashHeader";
import CommonDelete from "../CommonActions/CommonDelete";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import PageLoader from "../CommonActions/PageLoader";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import { GetTokenFunc } from "../../utils/GetToken";
import Searchbar from "../../common/Searchbar";

const ProductTrash = () => {
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteModalOpenTwo, setIsDeleteModalOpenTwo] = useState(false);
  const handleClose = () => {
    setIsDeleteModalOpen(false);
    setIsDeleteModalOpenTwo(false);
  };

  const [active, setActive] = useState([]);
  const [restoreDelete, setRestoreDelete] = useState();
  const [loader, setLoader] = useState(true);
  const [initialPaginationCount, setInitialPaginationCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [dataTypes, setDataTypes] = useState("Company");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "0px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "0px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const AlldeleteGet = async () => {
    await axios
      .get(
        `/api/v1/admin/AdminCompanyDetails/AllDeletedItems?type=${dataTypes}&page=${currentPage}&perPage=1&search=${searchData}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setActive(res.data);
        setLoader(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setInitialPaginationCount(active?.pageCount);
  }, [currentPage, dataTypes, active]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const RestoreDeleted = async (restoreId) => {
    await axios
      .get(
        `/api/v1/admin/AdminCompanyDetails/RestoreDeletedItems?ProductId=${restoreId}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setRestoreDelete(res.data);
        AlldeleteGet();
      })
      .catch((err) => {});
  };

  const PermenentDeletedItems = async (deleteId) => {
    await axios
      .delete(
        `/api/v1/admin/AdminCompanyDetails/PermenentDeletedItems?ProductId=${deleteId}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setRestoreDelete(res.data);
        setIsDeleteModalOpen(false);
        setDeleteProductId(null);
        AlldeleteGet();
      })
      .catch((err) => {});
  };
  const handleDelete = (id) => {
    PermenentDeletedItems(id);
    handleClose();
    // AlldeleteGet();
  };

  useEffect(() => {
    AlldeleteGet();
    setLoader(true);
  }, [currentPage, dataTypes, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  const CompanyDataRender = () => {
    return (
      <>
        {active?.items?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Company Data is empty</Typography>
          </Box>
        ) : (
          <React.Fragment>
            <Grid container sx={{}}>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid
                item
                xl={11}
                lg={11}
                md={11}
                sm={11}
                xs={11}
                sx={{
                  display: "flex",
                }}
              >
                <Grid
                  item
                  xl={4.5}
                  lg={4.5}
                  md={4.5}
                  sm={4.5}
                  xs={4.5}
                  align="center"
                >
                  <Typography sx={{ ...Styles.headTableText }}>
                    Company Name
                  </Typography>
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={2} xs={2} align="center">
                  <Typography sx={Styles.headTableText}>Category</Typography>
                </Grid>

                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="center"
                >
                  <Typography sx={Styles.headTableText}>Deleted</Typography>
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="center"
                >
                  <Typography sx={{ ...Styles.headTableText }}>
                    Restore
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="center"
                >
                  <Typography sx={{ ...Styles.headTableText }}>
                    Delete
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  mt: 2,
                  mb: 2,
                  height: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {active?.items?.map((x, i) => {
                  return (
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        mt: 2,
                        mb: 2,
                        height: "50px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={numText}>{i + 1}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                {active?.items?.map((x, i) => {
                  return (
                    <Grid
                      container
                      sx={{
                        mt: 2,
                        mb: 2,
                        background: x.active !== 0 ? "#fff" : "#445FD2",
                        height: "auto",
                        minHeight: "70px",
                        border: "1px solid rgba(233, 239, 244, 1)",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Grid item xl={4.5} lg={4.5} md={4.5} sm={4.5} xs={4.5}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                          }}
                        >
                          {x?.CompanyName}
                        </Typography>
                      </Grid>

                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                            // color:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                          }}
                        >
                          {x?.Category}
                        </Typography>
                      </Grid>

                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                            // color:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                          }}
                        >
                          {new Date(x?.updatedAt).toLocaleDateString("en-GB", {
                            timeZone: "UTC",
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Button
                          sx={{
                            ...Styles.restoreBtn,
                            // backgroundColor:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                            // color:
                            //   x.active !== 0
                            //     ? ' var(--basic-colour, #4676FB)'
                            //     : '#fff',
                          }}
                          onClick={() => {
                            RestoreDeleted(x?._id);
                          }}
                        >
                          Restore
                        </Button>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Box
                          component="img"
                          src={deletimg}
                          alt=""
                          style={{
                            height: "auto",
                            width: "30px",
                            marginTop: "5px",
                          }}
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setDeleteProductId(x._id);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}

                <CommonDelete
                  open={isDeleteModalOpen}
                  onClose={handleClose}
                  onDelete={() => {
                    handleDelete(deleteProductId);
                  }}
                  text="Are you sure you want to delete this data?"
                  ButtonLabel="Delete"
                  cancelButtonLabel="Cancel"
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </>
    );
  };

  const ProductDataRender = () => {
    const CategoryName = [
      {
        id: "63e38bddcc4c02b8a0c94b8a",
        CategoryName: "Others",
      },
      {
        id: "63e38b9ccc4c02b8a0c94b6f",
        CategoryName: "Lifestyle",
      },
      {
        id: "63e38bb9cc4c02b8a0c94b7b",
        CategoryName: "FMCG",
      },
      {
        id: "63e38b91cc4c02b8a0c94b69",
        CategoryName: "Media",
      },
      {
        id: "63e38ba3cc4c02b8a0c94b72",
        CategoryName: "Hotel",
      },
      {
        id: "63e38bbfcc4c02b8a0c94b7e",
        CategoryName: "Airlines Tickets",
      },
      {
        id: "63e38bc6cc4c02b8a0c94b81",
        CategoryName: "Office Supply",
      },
      {
        id: "63e38bb3cc4c02b8a0c94b78",
        CategoryName: "Mobility",
      },
      {
        id: "63e38bcecc4c02b8a0c94b84",
        CategoryName: "Electronics",
      },
      {
        id: "63e38b96cc4c02b8a0c94b6c",
        CategoryName: "Textile",
      },
      {
        id: "64218b189fe1b6ae750c11bd",
        CategoryName: "Entertainment & Events",
      },
      {
        id: "63e38bd5cc4c02b8a0c94b87",
        CategoryName: "QSR",
      },
    ];
    return (
      <>
        {active?.items?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Product Data is empty</Typography>
          </Box>
        ) : (
          <React.Fragment>
            <Box sx={{ height: "390px", width: "100%", overflowY: "scroll" }}>
              <Grid container sx={{}}>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2} align="center">
                    <Typography sx={{ ...Styles.headTableText }}>
                      Company Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={3.5}
                    lg={3.5}
                    md={3.5}
                    sm={3.5}
                    xs={3.5}
                    align="center"
                  >
                    <Typography sx={{ ...Styles.headTableText }}>
                      Product Name
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    align="center"
                  >
                    <Typography sx={Styles.headTableText}>Category</Typography>
                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                    <Typography sx={Styles.headTableText}>Type</Typography>
                  </Grid>

                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    align="center"
                  >
                    <Typography sx={Styles.headTableText}>Deleted</Typography>
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    align="center"
                  >
                    <Typography sx={{ ...Styles.headTableText }}>
                      Restore
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                    <Typography sx={{ ...Styles.headTableText }}>
                      Delete
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={{
                    mt: 2,
                    mb: 2,
                    height: "auto",
                    minHeight: "70px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {active?.items?.map((x, i) => {
                    return (
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          mt: 2,
                          mb: 2,
                          height: "50px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={numText}>{i + 1}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                  {active?.items?.map((x, i) => {
                    return (
                      <Grid
                        container
                        sx={{
                          mt: 2,
                          mb: 2,
                          background: "#fff",
                          height: "auto",
                          minHeight: "70px",
                          border: "1px solid rgba(233, 239, 244, 1)",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={Styles.commonText}>
                            {x?.CompanyName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={3.5}
                          lg={3.5}
                          md={3.5}
                          sm={3.5}
                          xs={3.5}
                          align="center"
                        >
                          <Typography sx={Styles.commonText}>
                            {x?.ProductName}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          align="center"
                        >
                          <Typography
                            sx={{
                              ...Styles.commonText,
                            }}
                          >
                            {CategoryName?.filter(
                              (product) => product?.id === x?.Category
                            ).reduce((idx, item) => {
                              return <span>{item?.CategoryName}</span>;
                            }, "--")}
                          </Typography>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                          <Typography
                            sx={{
                              ...Styles.commonText,
                              // color:
                              //   x.active !== 0
                              //     ? '#fff'
                              //     : 'var(--basic-colour, #4676FB)',
                            }}
                          >
                            {x?.ProductType}
                          </Typography>
                        </Grid>

                        <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                          <Typography sx={Styles.commonText}>
                            {new Date(x?.updatedAt).toLocaleDateString(
                              "en-GB",
                              {
                                timeZone: "UTC",
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                          <Button
                            sx={Styles.restoreBtn}
                            onClick={() => {
                              RestoreDeleted(x?._id);
                            }}
                          >
                            {" "}
                            Restore
                          </Button>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                          <Box
                            component="img"
                            src={deletimg}
                            alt=""
                            style={{
                              height: "auto",
                              width: "30px",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsDeleteModalOpenTwo(true);
                              setDeleteProductId(x._id);
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <CommonDelete
                    open={isDeleteModalOpenTwo}
                    onClose={handleClose}
                    onDelete={() => {
                      handleDelete(deleteProductId);
                    }}
                    text="Are you sure you want to delete this data?"
                    ButtonLabel="Delete"
                    cancelButtonLabel="Cancel"
                  />
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}
      </>
    );
  };

  const NotificationDataRender = () => {
    return (
      <>
        {active?.items?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Product Data is empty</Typography>
          </Box>
        ) : (
          <React.Fragment>
            <Box sx={{ height: "390px", width: "100%", overflowY: "scroll" }}>
              <Grid container sx={{}}>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Grid item xl={7} lg={7} md={7} sm={7} xs={7} align="center">
                    <Typography sx={{ ...Styles.headTableText }}>
                      Notification
                    </Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2} align="center">
                    <Typography sx={{ ...Styles.headTableText }}>
                      Date
                    </Typography>
                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                    <Typography sx={{ ...Styles.headTableText }}>
                      Restore
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                    <Typography sx={{ ...Styles.headTableText }}>
                      Delete
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={{
                    mt: 2,
                    mb: 2,
                    height: "auto",
                    minHeight: "70px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {active?.items?.map((x, i) => {
                    return (
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          mt: 2,
                          mb: 2,
                          height: "50px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={numText}>{i + 1}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                  {active?.items?.map((x, i) => {
                    return (
                      <Grid
                        container
                        sx={{
                          mt: 2,
                          mb: 2,
                          background: "#fff",
                          height: "auto",
                          minHeight: "70px",
                          border: "1px solid rgba(233, 239, 244, 1)",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Grid
                          item
                          xl={7}
                          lg={7}
                          md={7}
                          sm={7}
                          xs={7}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={Styles.commonText}>
                            {x?.message}
                          </Typography>
                        </Grid>

                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={Styles.commonText}>
                            {new Date(x?.updatedAt).toLocaleDateString(
                              "en-GB",
                              {
                                timeZone: "UTC",
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                          <Button
                            sx={Styles.restoreBtn}
                            onClick={() => {
                              RestoreDeleted(x?._id);
                            }}
                          >
                            {" "}
                            Restore
                          </Button>
                        </Grid>
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                          <Box
                            component="img"
                            src={deletimg}
                            alt=""
                            style={{
                              height: "auto",
                              width: "30px",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsDeleteModalOpenTwo(true);
                              setDeleteProductId(x._id);
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <CommonDelete
                    open={isDeleteModalOpenTwo}
                    onClose={handleClose}
                    onDelete={() => {
                      handleDelete(deleteProductId);
                    }}
                    text="Are you sure you want to delete this data?"
                    ButtonLabel="Delete"
                    cancelButtonLabel="Cancel"
                  />
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}
      </>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        // height: '590px',
        // width: '100%',
        // overflowY: 'scroll',
      }}
    >
      <Box>
        <Typography sx={Styles.chatText}>Trash </Typography>
        <Typography sx={Styles.chatTextSub}>
          View & Manage all deleted Companies or Products.
        </Typography>
      </Box>
      {/* <Box mt={2} sx={{ display: 'flex', gap: '10px' }}>
        <Button
          variant="outlined"
          onClick={() => {
            setCompBtn(true);
            setProdBtn(false);
            setSelect(0);
            
          }}
          sx={{
            ...Styles.btnDesign,
            border: compBtn ? '0.705px solid #445FD2' : '0.705px solid #E6E9EE',
            color: compBtn ? '#445FD2' : '#E6E9EE',
          }}
        >
          Company
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setCompBtn(false);
            setProdBtn(true);
            setSelect(1);
          }}
          sx={{
            ...Styles.btnDesign,
            border: prodBtn ? '0.705px solid #445FD2' : '0.705px solid #E6E9EE',
            color: prodBtn ? '#445FD2' : '#E6E9EE',
          }}
        >
          Product
        </Button>
      </Box>
      <Box
        sx={{
          borderBottom: '0.5px solid #F0F0F0',
          width: '100%',
          mx: 'auto',
          mt: 2,
        }}
      ></Box> */}
      <Tabs
        className="page-filters-tabs"
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          mt: 2,
        }}
      >
        <Tab
          key="Active"
          onClick={() => {
            setDataTypes("Company");
            setCurrentPage(1);
          }}
          sx={{
            "&.Mui-selected": {
              color: "rgba(68, 95, 210, 1) !important",
              border: "1px solid rgba(68, 95, 210, 1) !important",
            },
            textTransform: "capitalize",
          }}
          className="inner-tab-label"
          label="Company"
        />

        <Tab
          key="Inactive"
          onClick={() => {
            setDataTypes("Product");
            setCurrentPage(1);
          }}
          sx={{
            "&.Mui-selected": {
              color: "rgba(68, 95, 210, 1) !important",
              border: "1px solid rgba(68, 95, 210, 1) !important",
            },
            textTransform: "capitalize",
          }}
          className="inner-tab-label"
          label="Product"
        />

        <Tab
          key="Inactive"
          onClick={() => {
            setDataTypes("Notification");
            setCurrentPage(1);
          }}
          sx={{
            "&.Mui-selected": {
              color: "rgba(68, 95, 210, 1) !important",
              border: "1px solid rgba(68, 95, 210, 1) !important",
            },
            textTransform: "capitalize",
          }}
          className="inner-tab-label"
          label="Notification"
        />
      </Tabs>
      <Searchbar width={"100%"} onSendData={handleChildData} />

      <Divider />
      {/* <Box sx={{ mt: 2 }}>
        <DropCategory />
      </Box> */}
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2, mt: 3 }}>
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
              width: "100%",
              // mr: '150%',
              ml: "500px",
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <>
            {activeTab === 0 && <div>{CompanyDataRender()}</div>}
            {activeTab === 1 && <div>{ProductDataRender()}</div>}
            {activeTab === 2 && <div>{NotificationDataRender()}</div>}
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        mt={0.8}
      >
        <Stack spacing={2}>
          <StyledPagination
            size="small"
            count={initialPaginationCount}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
      {/* <Box sx={{ background: '#FAFBFC', mt: 1, p: 1 }}>
        {select === 1 ? (
          <>
            <Grid container sx={{}}>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}></Grid>
              <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                <Typography sx={{ ...Styles.headTableText, ml: '80px' }}>
                  File Name
                </Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={{ ...Styles.headTableText, ml: '-10px' }}>
                  Company Name
                </Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Category</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Deleted</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Restore</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Delete</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}></Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  mt: 2,
                  mb: 2,
                  height: '50px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {[1, 2, 3, 4, 5].map((x, i) => {
                  return (
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        mt: 2,
                        mb: 2,
                        height: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Typography sx={numText}>1</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                {[1, 2, 3, 4, 5].map((x, i) => {
                  return (
                    <Grid
                      container
                      onClick={() => setActive(x)}
                      sx={{
                        mt: 2,
                        mb: 2,
                        background: x.active !== 0 ? '#fff' : '#445FD2',
                        height: '50px',
                        border: '1px solid rgba(233, 239, 244, 1)',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                        <img
                          src={docs}
                          alt=""
                          style={{
                            height: 'auto',
                            width: '20px',
                            marginTop: '5px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                        <Typography
                          sx={{
                            ...tableText,
                            // color:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                          }}
                        >
                          ipsum.pdf
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                            // color:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                          }}
                        >
                          company name
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                            // color:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                          }}
                        >
                          Media
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                            // color:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                          }}
                        >
                          2021-11-03 22:00
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Button
                          sx={{
                            ...Styles.restoreBtn,
                            // backgroundColor:
                            //   x.active !== 0
                            //     ? '#fff'
                            //     : 'var(--basic-colour, #4676FB)',
                            // color:
                            //   x.active !== 0
                            //     ? ' var(--basic-colour, #4676FB)'
                            //     : '#fff',
                          }}
                        >
                          Restore
                        </Button>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <img
                          src={deletimg}
                          alt=""
                          style={{
                            height: 'auto',
                            width: '30px',
                            marginTop: '5px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <MoreVertIcon
                          sx={{
                            color: 'rgba(208, 215, 221, 0.73)',
                            display: 'flex',
                            alignContent: 'center',
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container sx={{}}>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}></Grid>

              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={{ ...Styles.headTableText, ml: '-10px' }}>
                  Company Name
                </Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <Typography sx={Styles.headTableText}>Category</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Deleted</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Restore</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={Styles.headTableText}>Delete</Typography>
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}></Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  mt: 2,
                  mb: 2,
                  height: '50px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {[1, 2, 3, 4, 5].map((x, i) => {
                  return (
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        mt: 2,
                        mb: 2,
                        height: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Typography sx={numText}>1</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                {[1, 2, 3, 4, 5].map((x, i) => {
                  return (
                    <Grid
                      container
                      sx={{
                        mt: 2,
                        mb: 2,
                        background: '#fff',
                        height: '50px',
                        border: '1px solid rgba(233, 239, 244, 1)',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                        <img
                          src={ss}
                          alt=""
                          style={{
                            height: 'auto',
                            width: '40px',
                            marginTop: '5px',
                          }}
                        />
                      </Grid>

                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Typography sx={Styles.commonText}>
                          company name
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          sx={{
                            ...Styles.commonText,
                            textAlign: 'left',
                            ml: '30px',
                          }}
                        >
                          Media
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Typography sx={Styles.commonText}>
                          2021-11-03 22:00
                        </Typography>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Button sx={Styles.restoreBtn}>Restore</Button>
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <img
                          src={deletimg}
                          alt=""
                          style={{
                            height: 'auto',
                            width: '30px',
                            marginTop: '5px',
                          }}
                        />
                      </Grid>
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <MoreVertIcon
                          sx={{
                            color: 'rgba(208, 215, 221, 0.73)',
                            display: 'flex',
                            alignContent: 'center',
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
      </Box> */}
    </Paper>
  );
};

export default ProductTrash;

const tableText = {
  color: "var(--basic-colour, #4676FB)",
  fontFamily: "Poppins",
  fontSize: 13.433,
  fontStyle: "normal",
  fontWeight: 400,
};
const numText = {
  color: "var(--additional-colour, #ADC0F8)",
  fontFamily: "Poppins",
  fontSize: 20.667,
  fontStyle: "normal",
  fontWeight: 700,
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};
