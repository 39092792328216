import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MailIcon from "../../assets/email.png";
import PhoneIcon from "../../assets/phone.png";
import AvatarGenerator from "../Layouts/AvatarGenerator.jsx";
import * as Styles from "../../common/Styles";
import { useFetchRolesPermmission } from "../../Hooks/Roles/useGetRolesPermission";
import { useRemoveRolesPermission } from "../../Hooks/Roles/useRemoveRolesPermission";
import CommonDelete from "../CommonActions/CommonDelete";
import { useNavigate, useParams } from "react-router-dom";
const RolesAndPermissions = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ids, setIds] = useState();
  const {
    data: rolespermission,
    isLoading: rolesLoading,
    isError: rolesError,
    refetch: rolesPermissionRefetch,
  } = useFetchRolesPermmission(id);

  // delete

  const {
    data: mutateRemoveRolesPermissionData,
    mutate: removefromRolesPermission,
  } = useRemoveRolesPermission(id);

  async function handleRemoveRolesPermission(id) {
    removefromRolesPermission(id);
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [removeRolesPermission, setRemoveRolesPermission] = useState(null);

  const handleDelete = (id) => {
    removefromRolesPermission(id, {
      onSuccess: (res) => {
        rolesPermissionRefetch();
      },
      onError: (err) => {},
    });
    setIsDeleteModalOpen(false);
    setRemoveRolesPermission(null);
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };
  const perticularUser = (ids) => {
    navigate(`user/${ids}`);
  };
  return (
    <Paper elevation={0}>
      <Box>
        <Typography sx={Styles.chatText}>Roles & Permissions </Typography>
        <Typography sx={Styles.chatTextSub}>
          Here is a list of members of the company.
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          height: "80%",
          minHeight: "530px",
          p: 0.5,
          mt: 2,
          background: "var(--Grey-Blue-99, #FAFBFC)",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            mt: 2,
            flexWrap: "wrap",
            width: "95%",
            mx: "auto",
          }}
        >
          {rolespermission &&
            rolespermission?.data !== "users Data doesnt exist" &&
            rolespermission?.data?.AllUserData?.map((item, x) => {
              return (
                <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12} sx={{}}>
                  <Card
                    variant="outlined"
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      width: "204px",
                      height: "232px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      border: "none",
                    }}
                    key={item?.id}
                  >
                    <CardContent
                      sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                          // bgcolor: "red",
                        }}
                      >
                        {item?.superAdmin ? (
                          <Box
                            sx={{
                              height: "17px",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            onClick={() => {
                              setIsDeleteModalOpen(true);
                              setRemoveRolesPermission(item?._id);
                            }}
                            sx={{
                              color: "#929EAE",
                              fontSize: "17px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                        <CommonDelete
                          open={isDeleteModalOpen}
                          onClose={handleClose}
                          onDelete={() => handleDelete(removeRolesPermission)}
                          text="Are you sure you want to delete this role?"
                          ButtonLabel="Delete"
                          cancelButtonLabel="Cancel"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // marginTop: "1.5rem",
                        }}
                      >
                        <img
                          src={
                            rolespermission?.data?.company?.CompanyAvatar?.url
                          }
                          alt="img"
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            border: "1px solid gray",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #F5F5F5",
                          width: "90%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            textAlign: "center",
                            color: "#1B212D",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxHeight: "2.5em",
                            lineHeight: "1.5em",
                            maxWidth: "100%",
                            textAlign: "center",
                            marginTop: "5px",
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          sx={{ ...rolestyle, mb: "10px", marginTop: "3px" }}
                        >
                          {item?.role ? item?.role : "Role not defined"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignContent: "center",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          mb: "10px",
                          width: "90%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                          }}
                        >
                          {/*     <Typography
                            sx={{
                              ...rolestyle,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '2px',
                            }}
                          >
                            <Box
                              component="img"
                              src={PhoneIcon}
                              sx={{
                                color: '#929EAE',
                                cursor: 'pointer',
                                height: '15px',
                                width: '15px',
                              }}
                            ></Box>
                            +91 22-49646776
                            </Typography> */}
                          <Typography
                            sx={{
                              ...rolestyle,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "2px",
                              // overflow: "hidden",
                              // textOverflow: "ellipsis",
                              // whiteSpace: "nowrap",
                            }}
                          >
                            <Box
                              component="img"
                              src={MailIcon}
                              sx={{
                                color: "#929EAE",
                                cursor: "pointer",
                                height: "15px",
                                width: "15px",
                              }}
                            />
                            {item?.email}
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        sx={{
                          background: "#445FD2",
                          borderRadius: "5px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: 10,
                          color: "white",
                          width: "100%",
                          height: "50%",
                          marginTop: "1rem",
                          border: "1px solid transparent",
                          textTransform: "none",

                          "&:hover": {
                            color: "#445FD2",
                            border: "1px solid #445FD2",
                          },
                        }}
                        onClick={() => {
                          perticularUser(item?._id);
                          setIds(item._id);
                        }}
                      >
                        View Details
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Box>
      </Box>
    </Paper>
  );
};

export default RolesAndPermissions;

const rolestyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textAlign: "center",
  color: "#929EAE",
};
