import {
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFetchApprovals } from "../../Hooks/AllRecords/useAllRecords";
import * as Styles from "../../common/Styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import PageLoader from "../CommonActions/PageLoader";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import filter from "../../assets/filter.png";
import axios from "axios";
import CloseIcon from "../../assets/CloseIcon.svg";
import acceptTic from "../../assets/AllRecords/acceptTic.png";
import pendingTic from "../../assets/AllRecords/pendingTic.png";
import Searchbar from "../../common/Searchbar";
const CategoryName = [
  {
    id: "63e38bddcc4c02b8a0c94b8a",
    CategoryName: "Others",
  },
  {
    id: "63e38b9ccc4c02b8a0c94b6f",
    CategoryName: "Lifestyle",
  },
  {
    id: "63e38bb9cc4c02b8a0c94b7b",
    CategoryName: "FMCG",
  },
  {
    id: "63e38b91cc4c02b8a0c94b69",
    CategoryName: "Media",
  },
  {
    id: "63e38ba3cc4c02b8a0c94b72",
    CategoryName: "Hotel",
  },
  {
    id: "63e38bbfcc4c02b8a0c94b7e",
    CategoryName: "Airlines Tickets",
  },
  {
    id: "63e38bc6cc4c02b8a0c94b81",
    CategoryName: "Office Supply",
  },
  {
    id: "63e38bb3cc4c02b8a0c94b78",
    CategoryName: "Mobility",
  },
  {
    id: "63e38bcecc4c02b8a0c94b84",
    CategoryName: "Electronics",
  },
  {
    id: "63e38b96cc4c02b8a0c94b6c",
    CategoryName: "Textile",
  },
  {
    id: "64218b189fe1b6ae750c11bd",
    CategoryName: "Entertainment & Events",
  },
  {
    id: "63e38bd5cc4c02b8a0c94b87",
    CategoryName: "QSR",
  },
];

function Approvals() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [approvalData, setApprovalData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [viewRequest, setViewRequest] = useState(false);
  const [status, setStatus] = useState("");
  const [searchData, setSearchData] = useState("");
  const handleViewRequestClose = () => setViewRequest(false);
  const handleViewRequestOpen = () => setViewRequest(true);

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const {
    data: getApprovalsData,
    isLoading: approvalsLoading,
    refetch: approvalsRefetch,
  } = useFetchApprovals(currentPage, status, searchData);
  const navigate = useNavigate();

  useEffect(() => {
    if (getApprovalsData && getApprovalsData?.totalPages !== undefined) {
      setInitialPaginationCount(getApprovalsData?.totalPages);
    }
  }, [getApprovalsData?.totalPages]);

  useEffect(() => {
    approvalsRefetch();
  }, [currentPage, status, searchData]);
  useEffect(() => {
    setCurrentPage(1);
  }, [status, searchData]);

  const [companyDetails, setCompanyDetails] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const [displayedCompanyIds, setDisplayedCompanyIds] = useState([]); // Store displayed company IDs

  useEffect(() => {
    if (getApprovalsData && getApprovalsData?.allRequests?.length) {
      const ids = getApprovalsData.allRequests.map((data) => data?.companyId);
      setDisplayedCompanyIds(ids);
    }
  }, [getApprovalsData]);

  const getCompanyDeatils = async () => {
    const response = await (
      await axios.get(
        `/AdminCompanyDetails/GetCompanys`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
        {
          onSucess: (res) => {},
        },
        {
          onError: (err) => {},
        }
      )
    ).data;
    setCompanyDetails(response);
  };

  useEffect(() => {
    getCompanyDeatils();
  }, []);

  const [openFilter, setOpenFilter] = React.useState(false);

  const handleFilterOpen = () => setOpenFilter(true);
  const handleFilterClose = () => setOpenFilter(false);

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon />
          </Box>
          <Box className="page-heading">
            <h5>All Approvals</h5>
            <Typography>Explore all the data by applying filters.</Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            background: "rgba(250, 251, 252, 1)",
            p: 0.5,
          }}
        >
          <Box
            className="products-filters-row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={buddge}>
              {/* {getApprovalsData?.allRequests?.requestType === 'Profile' ? (
                getApprovalsData instanceof Array ? (
                  <>Total Requests: {getApprovalsData?.allRequests}</>
                ) : (
                  <>Total Requests: 0</>
                )
              ) : getApprovalsData?.allRequests?.requestType === 'Product' ? (
                getApprovalsData instanceof Array ? (
                  <>Total Products: {getApprovalsData?.allRequests}</>
                ) : (
                  <>Total Products: 0</>
                )
              ) : getApprovalsData?.allRequests?.requestType ===
                'CreditRequest' ? (
                getApprovalsData instanceof Array ? (
                  <>Total Credit Requests: {getApprovalsData?.allRequests}</>
                ) : (
                  <>Total Credit Requests: 0</>
                )
              ) : null} */}

              <Typography sx={textBudge}>
                Total Requests: {getApprovalsData?.totalCount}
              </Typography>
            </Box>
            <Searchbar onSendData={handleChildData} />
            <Box sx={Styles.filtersDesign} onClick={handleFilterOpen}>
              <img
                src={filter}
                alt=""
                style={{ height: "auto", width: "15px" }}
              />
              Filters
            </Box>
          </Box>

          {approvalsLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                ml: "500px",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <Box
              sx={{
                background: approvalsLoading
                  ? "rgba(250, 251, 252, 1)"
                  : "#FAFBFC",
                borderRadius: 4,
                p: 2,
              }}
            >
              <Grid
                container
                sx={{
                  p: 1,
                }}
              >
                <Grid
                  item
                  xl={3.2}
                  lg={3.2}
                  md={3.2}
                  sm={3.2}
                  xs={3.2}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  Request
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={2.5}
                  md={2.5}
                  sm={2.5}
                  xs={2.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Company Name
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1.3}
                  lg={1.3}
                  md={1.3}
                  sm={1.3}
                  xs={1.3}
                  sx={Styles.textHead}
                >
                  Category
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  Location
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  Request Type
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  sx={Styles.textHead}
                >
                  Request Status
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  Date
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={0.5}
                  lg={0.5}
                  md={0.5}
                  sm={0.5}
                  xs={0.5}
                  sx={Styles.textHead}
                >
                  View
                </Grid>
              </Grid>
              <Box sx={{ height: "360px", width: "100%", overflowY: "scroll" }}>
                <Box>
                  {getApprovalsData?.allRequests?.map((data) => {
                    const formattedDate = data
                      ? formatDate(data?.createdAt)
                      : "";
                    const companyId = data?.companyId;
                    const companyData = companyDetails[companyId];

                    return (
                      <Grid
                        container
                        sx={{
                          ...Styles.containerStyles,
                          p: 2,
                        }}
                      >
                        <Grid
                          item
                          xl={3.2}
                          lg={3.2}
                          md={3.2}
                          sm={3.2}
                          xs={3.2}
                          sx={{
                            ...Styles.textHead,
                            display: "flex",
                            gap: "5px",
                            justifyContent: "flex-start",
                          }}
                        >
                          {data?.requestStatus === "Accepted" ? (
                            <img
                              src={acceptTic}
                              alt="img"
                              style={{ width: "20px", height: "20px" }}
                            />
                          ) : (
                            <img
                              src={pendingTic}
                              alt="img"
                              style={{ width: "20px", height: "20px" }}
                            />
                          )}
                          {data?.requestType === "Product" ? (
                            <>
                              {data?.productId?.ProductName
                                ? data?.productId?.ProductName
                                : "--"}
                            </>
                          ) : data?.requestType === "Profile" ? (
                            "The Company Has Requsted to Modify The Profile"
                          ) : data?.requestType === "CreditRequest" ? (
                            "The Company Has Requsted For Approval Of The Credit Request"
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xl={2.5}
                          lg={2.5}
                          md={2.5}
                          sm={2.5}
                          xs={2.5}
                          sx={{
                            ...Styles.textHead,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {data?.productId?.SellerCompanyName ? (
                            data?.productId?.SellerCompanyName
                          ) : (
                            <React.Fragment>
                              {companyDetails?.map((company) => {
                                return company?._id === data?.companyId
                                  ? company?.companyName
                                  : null;
                              })}
                            </React.Fragment>
                          )}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={1.3}
                          lg={1.3}
                          md={1.3}
                          sm={1.3}
                          xs={1.3}
                          sx={Styles.textHead}
                        >
                          {companyDetails?.map((company) => {
                            if (company?._id === data?.companyId) {
                              const category = CategoryName.find(
                                (category) =>
                                  category.id === company?.companyType
                              );
                              return category ? category.CategoryName : null;
                            }
                            return null;
                          })}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={Styles.textHead}
                        >
                          {companyDetails?.map((company) => {
                            if (company?._id === data?.companyId) {
                              const city = company?.CompanyAddress?.City;
                              return city || null;
                            }
                            return null;
                          })}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={Styles.textHead}
                        >
                          {data?.requestType}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={Styles.textHead}
                        >
                          <Box
                            sx={{
                              background:
                                data?.requestStatus === "Accepted"
                                  ? "#D9FFE9"
                                  : data?.requestStatus === "accepted"
                                  ? "#D9FFE9"
                                  : data?.requestStatus === "pending"
                                  ? "#FFF1E5"
                                  : data?.requestStatus === "failed"
                                  ? "#FFEFEF"
                                  : "#FFEFEF",
                              borderRadius: 1.5,
                              width: "70%",
                              mx: "auto",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  data?.requestStatus === "Accepted"
                                    ? "var(--Green-2, #27AE60)"
                                    : data?.requestStatus === "accepted"
                                    ? "var(--Green-2, #27AE60)"
                                    : data?.requestStatus === "pending"
                                    ? "#F2994A"
                                    : data?.requestStatus === "failed"
                                    ? "var(--Red, #EB5757)"
                                    : "var(--Red, #EB5757)",
                                fontFamily: "poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                fontStyle: "normal",
                                lineHeight: "30px",
                              }}
                            >
                              {data?.requestStatus === "Accepted"
                                ? "Accepted"
                                : data?.requestStatus === "accepted"
                                ? "Accepted"
                                : data?.requestStatus === "pending"
                                ? "pending"
                                : data?.requestStatus === "failed"
                                ? "N/A"
                                : null}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          align="center"
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={Styles.textHead}
                        >
                          {formattedDate}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={0.5}
                          lg={0.5}
                          md={0.5}
                          sm={0.5}
                          xs={0.5}
                          sx={Styles.textHead}
                        >
                          <Visibility
                            onClick={() => {
                              handleViewRequestOpen();
                              setApprovalData(data);
                            }}
                            sx={{
                              cursor: "pointer",
                              color: "#445FD2",
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={0.5}
          >
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCount}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>

          <Modal open={viewRequest} onClose={handleViewRequestClose}>
            <Box sx={RequestViewStyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "#6B7A99",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Change Request
                </Typography>

                <Typography
                  sx={{
                    color: "#868686",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                  }}
                >
                  {approvalData?.requestType === "Profile" ? (
                    <React.Fragment>{/* {approvalData?.} */}</React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {approvalData?.requestType === "Product"
                          ? "The user has requested approval for the product"
                          : approvalData?.requestType === "CreditRequest"
                          ? "The user has requested to for credits"
                          : null}
                      </Box>
                    </React.Fragment>
                  )}
                </Typography>

                <Box
                  component="img"
                  src={CloseIcon}
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "3%",
                    top: "5%",
                    height: "20px",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleViewRequestClose();
                  }}
                />
              </Box>

              {approvalData?.requestType === "Profile" ? (
                <Box
                  sx={{
                    my: 2.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "90%",
                    mx: "auto",
                  }}
                >
                  {/* <Typography sx={DataHeader}>Old Data:</Typography>
                  <Box sx={DataContentBox}>
                    <Typography sx={SubData}>
                      {approvalData?.currentData?.phone ? (
                        <>Phone no: {approvalData?.currentData?.phone}</>
                      ) : null}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.currentData?.CompanyAddress?.City ? (
                        <>
                          City:{' '}
                          {approvalData?.currentData?.CompanyAddress?.City}{' '}
                        </>
                      ) : null}{' '}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.currentData?.CompanyAddress?.Pincode ? (
                        <>
                          Pincode:{' '}
                          {approvalData?.currentData?.CompanyAddress?.Pincode}
                        </>
                      ) : null}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.currentData?.RegisteredAddress ? (
                        <>
                          Address :
                          {approvalData?.currentData?.RegisteredAddress}
                        </>
                      ) : null}{' '}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.currentData?.email ? (
                        <>Email :{approvalData?.currentData?.email}</>
                      ) : null}{' '}
                    </Typography>
                  </Box> */}

                  <Typography sx={DataHeader}>Requested Data:</Typography>
                  <Box sx={DataContentBox}>
                    <Typography sx={SubData}>
                      {approvalData?.profileChanges?.phone ? (
                        <>Phone no: {approvalData?.profileChanges?.phone}</>
                      ) : null}
                    </Typography>
                    <Typography sx={SubData}>
                      {approvalData?.profileChanges?.website ? (
                        <>website: {approvalData?.profileChanges?.website}</>
                      ) : null}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.profileChanges?.CompanyAddress?.City ? (
                        <>
                          City:{" "}
                          {approvalData?.profileChanges?.CompanyAddress?.City}{" "}
                        </>
                      ) : null}{" "}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.profileChanges?.CompanyAddress?.Pincode ? (
                        <>
                          Pincode:{" "}
                          {
                            approvalData?.profileChanges?.CompanyAddress
                              ?.Pincode
                          }
                        </>
                      ) : null}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.profileChanges?.RegisteredAddress ? (
                        <>
                          Address :
                          {approvalData?.profileChanges?.RegisteredAddress}
                        </>
                      ) : null}{" "}
                    </Typography>

                    <Typography sx={SubData}>
                      {approvalData?.profileChanges?.email ? (
                        <>Email :{approvalData?.profileChanges?.email}</>
                      ) : null}{" "}
                    </Typography>
                  </Box>
                </Box>
              ) : approvalData?.requestType === "Product" ? (
                <Box
                  sx={{
                    my: 2.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "90%",
                    mx: "auto",
                  }}
                >
                  <Box sx={DataContentBox}>
                    <Typography sx={SubData}>
                      '
                      {companyDetails?.map((company) => {
                        return company?._id === approvalData?.companyId
                          ? company?.companyName
                          : null;
                      })}
                      ' &nbsp; has requested approval for the product '
                      {approvalData?.productId?.ProductName}'{" "}
                    </Typography>
                  </Box>
                </Box>
              ) : approvalData?.requestType === "CreditRequest" ? (
                <Box
                  sx={{
                    my: 2.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "90%",
                    mx: "auto",
                  }}
                >
                  <Box sx={DataContentBox}>
                    <Typography sx={SubData}>
                      '
                      {companyDetails?.map((company) => {
                        return company?._id === approvalData?.companyId
                          ? company?.companyName
                          : null;
                      })}
                      ' &nbsp; has requested amount of{" "}
                      <b>{approvalData?.requestedUserAmount}</b> for '
                      {approvalData?.productId?.ProductName
                        ? approvalData?.productId?.ProductName
                        : "(Product Name Is Not Available)"}
                      '{" "}
                    </Typography>
                  </Box>
                </Box>
              ) : null}

              {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                    textTransform: 'none',
                  }}
                >
                  <Box
                    component="button"
                    variant="contained"
                    onClick={() => {
                      {
                        approvalData?.requestType === 'Profile'
                          ? updateProfileStatusOnClick(false)
                          : updateStatusOnClick(false);
                      }
                    }}
                    sx={{
                      ...ButtonStyle,
                      textTransform: 'none',
                      color: '#F15B5B',
                      background: '#fff',
                      border: '1px solid #F15B5B',
                    }}
                  >
                    Reject
                  </Box>
                  <Box
                    component="button"
                    variant="contained"
                    onClick={() => {
                      {
                        approvalData?.requestType === 'Profile'
                          ? updateProfileStatusOnClick(true)
                          : updateStatusOnClick(true);
                      }
                    }}
                    sx={{
                      ...ButtonStyle,
                      textTransform: 'none',
                      color: '#fff',
                      background: '#42C157',
                    }}
                  >
                    Approve
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Modal>
        </Box>
      </Paper>
      <Modal open={openFilter} onClose={handleFilterClose}>
        <Box sx={FilterStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Filters
            </Typography>
            <Box
              component="img"
              src={CloseIcon}
              sx={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
              }}
              onClick={() => {
                handleFilterClose();
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={""}
              value={status}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 1,
                mt: 1,
              }}
              onChange={(e) => {
                setStatus(e.target.value);
                handleFilterClose();
              }}
            >
              <FormControlLabel
                style={RadioTextStyle}
                value="Product"
                control={<Radio />}
                label="Product"
              />
              <FormControlLabel
                style={RadioTextStyle}
                value="Profile"
                control={<Radio />}
                label="Profile"
              />
              <FormControlLabel
                style={RadioTextStyle}
                value="CreditRequest"
                control={<Radio />}
                label="Credit Request"
              />
              <FormControlLabel
                style={RadioTextStyle}
                value=""
                control={<Radio />}
                label="All"
              />
            </RadioGroup>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default Approvals;

const RequestViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ButtonStyle = {
  width: "100%",
  height: "40px",
  border: "none",
  borderRadius: 1,
  "&:hover": {
    cursor: "pointer",
    boxShadow: "1px 1px 5px 1px #ccc",
  },
  borderRadius: 2,
  fontFamily: "poppins",
  fontWeight: 400,
};

const DataHeader = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  textAlign: "left",
};

const DataContentBox = {
  my: 1,
  textAlign: "center",
};

const SubData = {
  color: "#868686",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "cenetr",
};

const TotalReqStyle = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
  position: "absolute",
  left: 0,
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};
const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};
