import React, { useEffect, useState } from "react";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeriesCanvas,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import "/node_modules/react-vis/dist/style.css";
import { Box } from "@material-ui/core";

const CustomDiscreteColorLegend = ({ items }) => {
  return (
    <div
      style={{
        margin: "10px 0",
        display: "flex",
        width: "700",
        justifyContent: "space-around",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: item.color,
              marginRight: "5px",
              borderRadius: "50%",
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

const CreditLineIssue = () => {
  const BarSeries = VerticalBarSeries;

  const [hoveredBar, setHoveredBar] = useState(null);
  const [label, setLabel] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [optionFilter, setOptionFilter] = useState("Issue");
  const [isLoading, setIsLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [Legends, setLegends] = useState([]);
  const [Count, setCount] = useState([]);

  const handleBarHover = (value) => {
    setHoveredBar(value);
  };

  useEffect(() => {
    let newLegends = [];

    if (optionFilter == "Issue")
      newLegends.push({ title: "Issue", color: "#1D4ED8" });
    if (optionFilter == "Recovered")
      newLegends.push({ title: "Recovered", color: "#33BFFF" });
    if (optionFilter == "Defaulted")
      newLegends.push({ title: "Defaulted", color: "#33BFFF" });

    setLegends(newLegends);
  }, [optionFilter]);

  const Wallet_Url = "https://wallet-api.production-bxi.unada.in";
  const fetchData = async () => {
    setIsLoading(true);
    let apiUrl = "";

    if (optionFilter == "Issue" && filter == "last-week")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeek`;
    if (optionFilter == "Issue" && filter == "last-month")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeekMonth`;
    if (optionFilter == "Issue" && filter == "current-month")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedCurrentMonth`;
    if (optionFilter == "Issue" && filter == "last-three-months")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeek3Month`;
    if (optionFilter == "Issue" && filter == "last-six-months")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeek6Month`;
    if (optionFilter == "Issue" && filter == "last-year")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeek12Month`;
    if (optionFilter == "Issue" && filter == "current-year")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeekCurrent12Month`;
    if (optionFilter == "Issue" && filter == "last-five-years")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineIssuedWeekMonth5Years`;

    if (optionFilter == "Recovered" && filter == "last-week")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeek`;
    if (optionFilter == "Recovered" && filter == "last-month")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeekMonth`;
    if (optionFilter == "Recovered" && filter == "current-month")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryCurrentMonth`;
    if (optionFilter == "Recovered" && filter == "last-three-months")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeek3Month`;
    if (optionFilter == "Recovered" && filter == "last-six-months")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeek6Month`;
    if (optionFilter == "Recovered" && filter == "last-year")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeek12Month`;
    if (optionFilter == "Recovered" && filter == "current-year")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeekCurrent12Month`;
    if (optionFilter == "Recovered" && filter == "last-five-years")
      apiUrl = `${Wallet_Url}/api/v1/chart/CreditLineRecoveryWeekMonth5Years`;

    if (optionFilter == "Defaulted" && filter == "last-week")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteWeek`;
    if (optionFilter == "Defaulted" && filter == "last-month")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteWeekMonth`;
    if (optionFilter == "Defaulted" && filter == "current-month")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteCurrentMonth`;
    if (optionFilter == "Defaulted" && filter == "last-three-months")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteWeek3Month`;
    if (optionFilter == "Defaulted" && filter == "last-six-months")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteWeek6Month`;
    if (optionFilter == "Defaulted" && filter == "last-year")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteWeek12Month`;
    if (optionFilter == "Defaulted" && filter == "current-year")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteCurrent12Month`;
    if (optionFilter == "Defaulted" && filter == "last-five-years")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/CreditLineDefaulteWeekMonth5Years`;

    try {
      const res = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });

      setCount(res.data.Count);
      setLabel(res.data.label);
      setData(res.data.TotalCreditIshuAmount);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, optionFilter]);

  const DataArray = Data?.map((item, index) => ({
    x: index + 1,
    y: parseFloat(item),
    z: Count ? (Count.length > 0 ? Count[index] : Count) : null,
    color: Legends[0]?.color,
  }));

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">Credit Line Issued</h3>

        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setOptionFilter(e.target.value);
              }}
              value={optionFilter}
            >
              <option value="Issue">Credit Issued</option>
              <option value="Recovered">Recovered</option>
              <option value="Defaulted">Defaulted</option>
            </select>
          </Box>
        </Box>
        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XYPlot
            height={350}
            width={700}
            stackBy="y"
            margin={{ left: 70, right: 10, top: 10, bottom: 30 }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              tickValues={label?.map((item, index) => index + 1)}
              tickFormat={(v) => label[v - 1]}
            />

            <YAxis tickFormat={(v) => `${v}`} />

            <BarSeries
              barWidth={0.2}
              data={DataArray}
              color={Legends[0]?.color}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            {hoveredBar && (
              <Hint value={hoveredBar}>
                <div
                  className="grapharrow"
                  style={{
                    background: `${hoveredBar.color}`,
                    color: "white",
                    width: "80px",
                  }}
                >
                  <p>{hoveredBar.label}</p>
                  <p>{`${hoveredBar.y}₹ - ${hoveredBar.z}`}</p>
                </div>
              </Hint>
            )}
            <CustomDiscreteColorLegend items={Legends} />
          </XYPlot>
        </div>
      )}
    </div>
  );
};

export default CreditLineIssue;
