import React, { useState, useEffect } from "react";
import { Box, Modal, Typography, Button, Input } from "@mui/material";
import { useUpateRolesAndPermission } from "../../Hooks/Roles/useGetRolesPermission";
import axios from "axios";
import { useQuery } from "react-query";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

function RolesandPermissionUpdate(props) {
  const {
    open,
    onClose,
    onSave,
    fieldOne,
    fieldTwo,
    fieldThree,
    fieldFour,
    userRole,
    initialProductData,
    refetch,
  } = props;

  const {
    mutate: updateRolesPermissionData,
    isLoading: loadingRolesPermission,
    isSuccess: successRolesPermission,
    refetch: refetchRolesPermission,
  } = useUpateRolesAndPermission();

  const [inputValues, setInputValues] = useState({
    fieldTwo: initialProductData?.email,
    fieldOne: initialProductData?.productRights,
    fieldThree: initialProductData?.phone,
    fieldFour: initialProductData?.name,
    fieldFive: initialProductData?.tokenlimit,
    fieldSix: initialProductData?.roleAndPermission,
    fieldSeven: initialProductData?.roleName,
  });

  const handleInputChange = (field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };
  const handleSaveClick = async () => {
    onSave({
      ...initialProductData,
      PurchaseOrderData: {
        ...initialProductData.PurchaseOrderData,
        ProductData: [
          {
            name: inputValues.fieldFour,
            email: inputValues.fieldTwo,
            productRights: inputValues.fieldOne,
            phone: inputValues.fieldThree,
            tokenlimit: inputValues.fieldFive,
            roleAndPermission: inputValues.fieldSix,
            roleName: inputValues.fieldSeven,
          },
        ],
      },
    });
    const updateRolesPermission = {
      id: initialProductData?._id,
      name: inputValues.fieldFour,
      email: inputValues.fieldTwo,
      productRights: inputValues.fieldOne,
      phone: inputValues.fieldThree,
      tokenlimit: inputValues.fieldFive,
      roleAndPermission: inputValues.fieldSix,
      roleName: inputValues.fieldSeven,
    };

    await updateRolesPermissionData(updateRolesPermission, {
      onSuccess: (response) => {
        refetch();
      },
      onError: (err) => {
        console.error("API error:", err);
      },
    });
  };

  const handleCheckboxChange = (fieldName) => {
    // Define your handleCheckboxChange logic here
  };

  // Get All Roles
  const useGetAllRoles = () =>
    useQuery("all-roles", async () => {
      return (await axios.get("/api/v1/roles/all-roles")).data;
    });

  const [tokenlimit, setTokenLimit] = useState("");

  const TokenLimitChange = (event) => {
    if (event.target.value === "custom") {
      setTokenLimit("custom");
    } else {
      setTokenLimit(event.target.value);
    }
  };

  // Get Roles
  const { data: roledata } = useGetAllRoles();

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(250, 251, 252, 1)",
            boxShadow: 24,
            padding: "30px 30px 0 30px",
            minWidth: 300,
            maxWidth: "80%",
            height: 600,
            maxHeight: "80%",
            textAlign: "center",
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{ background: "rgba(250, 251, 252, 1)", p: "0 20px 0 20px " }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                my: 3,
              }}
            >
              <Box>
                <Typography sx={{ ...CommonHeader }}>
                  Name & E-mail Address of the User
                </Typography>
                <Typography
                  sx={{
                    ...SubHeading,
                  }}
                >
                  Type A name & Email for login in to the account.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexFirection: "column",
                  width: "100%",
                  gap: 2,
                  justifyContent: "space-between",
                  mt: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={CommonBoxForFields}>
                    <label style={CommonHeader} align="left">
                      Member Name{" "}
                    </label>
                    <input
                      className="input-field"
                      type="text"
                      style={{
                        ...InputStyle,
                      }}
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      defaultValue={initialProductData?.name}
                      value={inputValues.fieldFour}
                      onChange={(e) =>
                        handleInputChange("fieldFour", e.target.value)
                      }
                    />
                  </Box>
                  <Box sx={CommonBoxForFields}>
                    <label style={CommonHeader} align="left">
                      Member Email{" "}
                    </label>
                    <input
                      className="input-field"
                      type="text"
                      style={InputStyle}
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      defaultValue={initialProductData?.email}
                      value={inputValues.fieldTwo}
                      onChange={(e) =>
                        handleInputChange("fieldTwo", e.target.value)
                      }
                    />
                  </Box>
                  <Box sx={CommonBoxForFields}>
                    <label style={CommonHeader} align="left">
                      Member Phone no.{" "}
                    </label>
                    <input
                      className="input-field"
                      type="number"
                      style={{
                        ...InputStyle,
                      }}
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      defaultValue={initialProductData?.phone}
                      value={inputValues.fieldThree}
                      onChange={(e) =>
                        handleInputChange("fieldThree", e.target.value)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                my: 3,
              }}
            >
              <Box>
                <Typography sx={{ ...CommonHeader }}>
                  Assign and Edit the Roles & Rights for the above user.
                </Typography>
                <Typography
                  sx={{
                    ...SubHeading,
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Vestibulum condimentum
                  integer metus amet dignissim maecenas praesent euismod ac.{" "}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    ...MainRoleHaadingStyle,
                    textAlign: "left",
                    mt: 2,
                  }}
                >
                  Role of the User
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexFirection: "column",
                    width: "100%",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={CommonBoxForFields}>
                    <select
                      className="select-dropdown"
                      style={{ ...InputStyle, width: "220px" }}
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      defaultValue={initialProductData?.roleName}
                      value={inputValues.fieldSeven}
                      onChange={(e) =>
                        handleInputChange("fieldSeven", e.target.value)
                      }
                    >
                      {roledata?.roles?.map((role) => (
                        <option key={role._id} value={role.RoleName}>
                          {role.RoleName}
                        </option>
                      ))}
                    </select>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    ...MainRoleHaadingStyle,
                    textAlign: "left",
                    mt: 2,
                  }}
                >
                  Rights of the User
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ ...CommonBoxForFields }}>
                    <Box
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        display: "flex",
                        width: "80%",
                        gap: 4,
                      }}
                    >
                      <label
                        style={{
                          ...MainRoleHaadingStyle,
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <input
                            type="checkbox"
                            name="permission"
                            value="Purchase"
                            // checked={inputValues.fieldOne}
                            // onChange={() => handleCheckboxChange("fieldOne")}
                          />
                          Purchase
                          <Typography
                            sx={{
                              color: "#ADB8CC",
                              fontFamily: "Poppins",
                              fontSize: 12,
                              fontStyle: "normal",
                              fontWeight: 400,
                              textAlign: "left",
                              ml: "20px",
                            }}
                          >
                            ( Can Burn the tokens )
                          </Typography>
                        </Box>
                      </label>
                      <label
                        style={{
                          ...MainRoleHaadingStyle,
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <input
                            type="checkbox"
                            name="permission"
                            value="Finance"
                            // checked={inputValues.fieldTwo}
                            // onChange={() => handleCheckboxChange("fieldTwo")}
                          />{" "}
                          Finance
                          <Typography
                            sx={{
                              color: "#ADB8CC",
                              fontFamily: "Poppins",
                              fontSize: 12,
                              fontStyle: "normal",
                              fontWeight: 400,
                              textAlign: "left",
                              ml: "20px",
                            }}
                          >
                            ( Can see the summary and statements ){" "}
                          </Typography>
                        </Box>
                      </label>
                      <label
                        style={{
                          ...MainRoleHaadingStyle,
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <input
                            type="checkbox"
                            name="permission"
                            value="Edit Only"
                            // checked={inputValues.fieldThree}
                            // onChange={() => handleCheckboxChange("fieldThree")}
                          />{" "}
                          Sales
                          <Typography
                            sx={{
                              color: "#ADB8CC",
                              fontFamily: "Poppins",
                              fontSize: 12,
                              fontStyle: "normal",
                              fontWeight: 400,
                              textAlign: "left",
                              ml: "20px",
                            }}
                          >
                            ( Can earn the tokens ){" "}
                          </Typography>
                        </Box>
                      </label>
                      <label
                        style={{
                          ...MainRoleHaadingStyle,
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <input
                            type="checkbox"
                            name="permission"
                            value="No Access"
                            // checked={inputValues.fieldFour}
                            // onChange={() => handleCheckboxChange("fieldFour")}
                          />{" "}
                          Custom Permission
                          <Typography
                            sx={{
                              color: "#ADB8CC",
                              fontFamily: "Poppins",
                              fontSize: 12,
                              fontStyle: "normal",
                              fontWeight: 400,
                              textAlign: "left",
                              ml: "20px",
                            }}
                          >
                            ( Super admin can select the permissions to be given
                            ){" "}
                          </Typography>
                        </Box>
                      </label>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    ...MainRoleHaadingStyle,
                    textAlign: "left",
                    mt: 2,
                  }}
                >
                  Editing rights of the User
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={inputValues.fieldOne}
                          onChange={(e) =>
                            handleInputChange("fieldOne", e.target.value)
                          }
                        >
                          <FormControlLabel
                            value="View & Edit"
                            control={<Radio sx={RadioDes} />}
                            label={
                              <label
                                style={{
                                  ...MainRoleHaadingStyle,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                View & Edit
                              </label>
                            }
                          />

                          <FormControlLabel
                            value="View Only"
                            sx={{
                              ml: 5,
                            }}
                            control={<Radio sx={RadioDes} />}
                            label={
                              <label
                                style={{
                                  ...MainRoleHaadingStyle,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                View Only
                              </label>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    ...MainRoleHaadingStyle,
                    textAlign: "left",
                    mt: 2,
                  }}
                >
                  Tokens Details
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: 2,
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ ...SubHeading }}>
                    Add Token Limits
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tokenlimit}
                    onChange={TokenLimitChange}
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio sx={RadioDes} />}
                      label={
                        <label
                          style={{
                            ...MainRoleHaadingStyle,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Select Token Limit
                        </label>
                      }
                    />

                    <FormControlLabel
                      value="custom"
                      control={<Radio sx={RadioDes} />}
                      label={
                        <label
                          style={{
                            ...MainRoleHaadingStyle,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Custom Token Limit
                        </label>
                      }
                    />
                  </RadioGroup>
                  {tokenlimit !== "custom" ? (
                    <select
                      className="gradient-dropwon"
                      style={{
                        ...InputStyle,
                        width: "150px",
                        color: "#445FD2",
                      }}
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      defaultValue={initialProductData?.tokenlimit}
                      value={inputValues.fieldFive}
                      onChange={(e) =>
                        handleInputChange("fieldFive", e.target.value)
                      }
                    >
                      <option value="50000">
                        Burn Upton 50000 Barter Coins
                      </option>
                      <option value="100000">
                        Burn Upton 100000 Barter Coins{" "}
                      </option>
                      <option value="250000">
                        Burn Upton 250000 Barter Coins
                      </option>
                      <option value="500000">
                        Burn Upton 500000 Barter Coins
                      </option>
                    </select>
                  ) : (
                    <input
                      type="number"
                      style={{
                        ...InputStyle,
                        width: "200px",
                        border: "2px solid #e6e9ee",
                        background: "#ffffff",
                        borderRadius: "4px",
                        padding: "5px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#6b7a99",
                      }}
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder="customTokenLimit"
                      value={inputValues.fieldFive}
                      onChange={(e) =>
                        handleInputChange("fieldFive", e.target.value)
                      }
                      min={0}
                      disabled={tokenlimit !== "custom"}
                    />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    ...MainRoleHaadingStyle,
                    textAlign: "left",
                    mt: 2,
                  }}
                >
                  User can edit the member role & Permissions of other user?{" "}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 4,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={inputValues.fieldSix}
                        onChange={(e) =>
                          handleInputChange("fieldSix", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio sx={RadioDes} />}
                          label={
                            <label
                              style={{
                                ...MainRoleHaadingStyle,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Yes
                            </label>
                          }
                        />

                        <FormControlLabel
                          value="No"
                          sx={{
                            ml: 5,
                          }}
                          control={<Radio sx={RadioDes} />}
                          label={
                            <label
                              style={{
                                ...MainRoleHaadingStyle,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              No
                            </label>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    ...MainRoleHaadingStyle,
                    textAlign: "left",
                    mt: 2,
                  }}
                >
                  Password Details{" "}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 4,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={CommonBoxForFields}>
                      <label style={SubHeading} align="left">
                        Enter Password{" "}
                      </label>
                      <input
                        className="input-field"
                        type="text"
                        style={{
                          ...InputStyle,
                        }}
                        variant="contained"
                        disableUnderline
                        onWheel={numberInputOnWheelPreventChange}
                        // defaultValue={initialProductData?.email}
                        // value={inputValues.fieldFour}
                        // onChange={(e) =>
                        //   handleInputChange("fieldFour", e.target.value)
                        // }
                      />
                    </Box>{" "}
                    <Box sx={CommonBoxForFields}>
                      <label style={SubHeading} align="left">
                        Confirm Password{" "}
                      </label>
                      <input
                        className="input-field"
                        type="text"
                        style={{
                          ...InputStyle,
                        }}
                        variant="contained"
                        disableUnderline
                        onWheel={numberInputOnWheelPreventChange}
                        // defaultValue={initialProductData?.email}
                        // value={inputValues.fieldFour}
                        // onChange={(e) =>
                        //   handleInputChange("fieldFour", e.target.value)
                        // }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Box>

          <Box
            className="center-buttons"
            sx={{
              position: "sticky",
              bottom: 0,
              background: "rgba(250, 251, 252, 1)",
              height: "80px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button onClick={onClose} variant="outlined" sx={{ mt: 2 }}>
              Cancel{" "}
            </Button>
            <Button
              onClick={handleSaveClick}
              variant="contained"
              sx={{ mt: 2, mr: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default RolesandPermissionUpdate;

const SubFieldBox = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  justifyContent: "flex-start",
  alignItems: "flex-start",
  mt: 1,
};

const ButtonBox = {
  display: "flex",
  gap: 1,
  justifyContent: "space-between",
};

const InputStyle = {
  color: "#818181",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
};

const InputHeader = {
  fontFamily: "Poppins",
  fontSize: 12,
  fontWeight: 500,
  fontStyle: "normal",
  color: "#000",
};

const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const CommonHeader = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 500,
  textAlign: "left",
};

const SubHeading = {
  color: "#ADB8CC",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "left",
};

const CommonBoxForFields = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  justifyContent: "space-between",
  alignItems: "flex-start",
};

const MainRoleHaadingStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
};

const RadioDes = {
  color: "#445FD2",
};
