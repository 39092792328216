import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "../Login/Login";
import RouterErrorPage from "./RouterErrorPage";
import Website from "../Website/Website";
import React, { useContext } from "react";
import SideBar from "../Layouts/SideBar";
import TotalTransections from "../Analytics/TotalTransections";
import Landing from "../Analytics/Landing";
import CompanyListing from "../Company/CompanyListing";
import CompanyListingApprovals from "../Company/CompanyListingApprovals";
import AllProductApprovals from "../Company/AllProductApprovals";
import Marketplace from "../Marketplace/Marketplace";
import AddCouponCode from "../PrivateDeal/AddCouponCode";
import ViewProduct from "../Marketplace/ViewProduct";
import MediaProduct from "../Marketplace/MediaViewProduct/mediaProduct";
import ValueandGiftVoucher from "../Marketplace/VoucherViewProduct/ValueandGiftVoucher";
import SpacificVoucher from "../Marketplace/VoucherViewProduct/SpacificVoucher";
import Chat from "../Message/Chat";
import RecycleBin from "../Trash/RecycleBin";
import ProductTrash from "../Trash/ProductTrash";
import GenieAdmin from "../Genie/GenieAdmin";
import GenieCategory from "../Genie/GenieCategory";
import GenieNeedList from "../Genie/GenieNeedList";
import GenieActiveInavtive from "../Genie/GenieActiveInavtive";
import GenieBingoDeals from "../Genie/GenieBingoDeals";
import { checkAuthToken } from "../../common/ApiUtility";
import CompanyLanding from "../Company/CompanyLanding";
import Memberlisting from "../Memberdirectory/Memberlisting";
import Addmember from "../Adminhandles/Addmember";
import Memberdetails from "../Adminhandles/Memberdetails";
import Track from "../Stepper/Track";
import Chats from "../AllRecords/Chats";
import RolesAndPermissionPerticular from "../Company/RolesAndPermissionPerticular";
import AllExternalApi from "../ExternalAPIs/AllExternalApi";
import SignzyData from "../ExternalAPIs/SignzyData";
import PineLabsData from "../ExternalAPIs/PineLabsData";
import EscrowData from "../ExternalAPIs/EscrowData";
import PickrrData from "../ExternalAPIs/PickrrData";
import AllRecords from "../AllRecords/AllRecords.jsx";
import Invoices from "../AllRecords/Invoices";
import AdminTrails from "../Adminhandles/AdminTrails.jsx";
import CompanyInvoiceDetail from "../AllRecords/CompanyInvoiceDetail";
import InvoiceDetail from "../AllRecords/InvoicesDetail";
import Delivery from "../AllRecords/Delivery";
import Creditline from "../AllRecords/Creditline";
import Wishlist from "../AllRecords/Wishlist";
import Orders from "../AllRecords/ProductOrders";
import Approvals from "../AllRecords/Approvals";
import Communications from "../../View/Communications";
import AllOrdersList from "../AllRecords/AllOrdersList";
import Dashboard from "../../components/Dashboard/Dashboard";
import Crediterms from "../../../src/components/Company/Crediterms";
import ReleaseView from "../AllRecords/ReleaseView.jsx";
import MembershipList from "../../View/MembershipList.jsx";
import ViewInvoice from "../../View/ViewInvoice.jsx";
import CommissionList from "../../View/CommisionList.jsx";
import MemAmountDebitStatus from "../../View/MemAmountDebitStatus.jsx";
import Tds_gst from "../TDS_GST/Tds_gst.jsx";
import UploadBanner from "../Dashboard/UploadBanner.jsx";
import BXICommissionList from "../AllRecords/Allinvoicedetail/BXICommissionList.jsx";
const routesData = createBrowserRouter(
  [
    {
      path: "/",
      element: <Login />,
      errorElement: <RouterErrorPage />,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <RouterErrorPage />,
    },
    {
      path: "admindashboard",
      element: <SideBar />,
      errorElement: <RouterErrorPage />,
      loader: checkAuthToken,
      children: [
        {
          index: true,
          element: <Landing />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "analytics",
          element: <Landing />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "banner",
          element: <UploadBanner />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "tdsgst",
          element: <Tds_gst />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "userdashboard",
          element: <Dashboard />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "companies",
          element: <CompanyListing />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "marketplace",
          element: <Marketplace />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "addcouponcode",
          element: <AddCouponCode />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "marketplace/viewproduct/:id",
          element: <ViewProduct />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "marketplace/mediaviewproduct/:id",
          element: <MediaProduct />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "marketplace/valueandgiftvoucher/:id",
          element: <ValueandGiftVoucher />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "marketplace/spacificvoucher/:id",
          element: <SpacificVoucher />,
          errorElement: <RouterErrorPage />,
        },

        {
          path: "companylanding/:id",
          element: <CompanyLanding />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "approvallisting",
          element: <CompanyListingApprovals />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "allproductapprovals/:id",
          element: <AllProductApprovals />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "memberdirectory",
          element: <Memberlisting />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "adminhandles",
          element: <Addmember />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "memberdetails",
          element: <Memberdetails />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "companylanding/:companyId/companyinvoicedetail/:id",
          element: <CompanyInvoiceDetail />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "invoices/invoiceDetail/:id",
          element: <InvoiceDetail />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "companylanding/:id/user/:uid",
          element: <RolesAndPermissionPerticular />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "chat",
          element: <Chat />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "trash",
          element: <ProductTrash />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "producttrash",
          element: <ProductTrash />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "genie",
          element: <GenieAdmin />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "geniecategory",
          element: <GenieCategory />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "genieneedlist",
          element: <GenieNeedList />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "genieneactiveinactive",
          element: <GenieActiveInavtive />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "genienebingodeals",
          element: <GenieBingoDeals />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "allexternalapi",
          element: <AllExternalApi />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "signzydata",
          element: <SignzyData />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "pinelabsdata",
          element: <PineLabsData />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "escrowdata",
          element: <EscrowData />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "pickrrdata",
          element: <PickrrData />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "allrecords",
          element: <AllRecords />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "admintrails",
          element: <AdminTrails />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "invoices",
          element: <Invoices />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "membershipinvoicelist",
          element: <MembershipList />,
          errorElement: <RouterErrorPage />,
        },

        {
          path: "memamountdebitstatus",
          element: <MemAmountDebitStatus />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "commissioninvoicelist",
          element: <CommissionList />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "messages",
          element: <Chats />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "delivery",
          element: <Delivery />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "creditline",
          element: <Creditline />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "creditterms",
          element: <Crediterms />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "wishlist",
          element: <Wishlist />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "orders",
          element: <AllOrdersList />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "approvals",
          element: <Approvals />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "interests",
          element: <AllRecords />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "bxicommissionlist",
          element: <BXICommissionList />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "communications",
          element: <Communications />,
          errorElement: <RouterErrorPage />,
        },

        {
          path: "einvoiceView/:id/:type",
          element: <ViewInvoice />,
          errorElement: <RouterErrorPage />,
        },

        {
          path: "escrow_release/:id",
          element: <ReleaseView />,
          errorElement: <RouterErrorPage />,
        },
      ],
    },
  ],

  {
    basename: `/`,
  }
);

const MainRouter = ({ children }) => {
  return (
    <React.Fragment>
      <RouterProvider router={routesData}>{children}</RouterProvider>
    </React.Fragment>
  );
};

export default MainRouter;
