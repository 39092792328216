import { useQuery } from "react-query";
import { useMutation } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
// const GetRolesPermissionData = () => {
//   return axios.get(
//     `/api/v1/admin/roles-user/get_IamUsers_ByCompanyId/64748dd236043f9feac52c11`,
//     {
//       headers: {
//         authorization: `bearer ${GetTokenFunc()}`,
//       },
//     }
//   );
// };

// export const useFetchRolesPermmission = () => {
//   return useQuery(['GetRolesPermissionDataList'], GetRolesPermissionData);
// };

export const useFetchRolesPermmission = (id) => {
  return useQuery(["useFetchRolesPermmission", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/roles-user/get_IamUsers_ByCompanyId/${id}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    return response;
  });
};
export const useFetchRolesPermmissionforone = (id) => {
  return useQuery(["useFetchRolesPermmissionforone", id], async () => {
    const response = await axios.get(
      `/api/v1/admin/roles-user/get_IamUser_byId/${id}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );
    return response;
  });
};

// export const useUpateRolesAndPermission = (id) => {
//   return useQuery(['useUpateRolesAndPermission', id], async () => {
//     const response = await axios.post(
//       `/api/v1/admin/approvals/update-profile-edit-request`,
//       {
//         id: id,
//         status: 'Accept',
//       },
//       {
//         headers: {
//           authorization: `bearer ${GetTokenFunc()}`,
//         },
//       }
//     );
//     return response.data;
//   });
// };

const UpateRolesAndPermission = async (data) => {
  return await axios.put(
    `api/v1/admin/roles-user/update_iamuser/${data.id}`,
    {
      id: data.id,
      status: data.status,
      email: data.email,
      productRights: data.productRights,
      name: data.name,
      phone: data.phone,
      tokenlimit: data.tokenlimit,
      roleAndPermission: data.roleAndPermission,
      roleName: data.roleName,
    },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useUpateRolesAndPermission = () => {
  return useMutation(UpateRolesAndPermission);
};
