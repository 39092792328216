import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
  Paper,
  Box,
} from "@mui/material";
import * as Styles from "../../common/Styles";
import MailIcon from "../../assets/email.svg";
import PhoneIcon from "../../assets/phone.svg";
import tic from "../../assets/tic.svg";
import close from "../../assets/close.svg";
import bxiToken from "../../assets/BXIToken.svg";
import BackArrow from "../../assets/BackArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import RolesandPermissionUpdate from "../CommonActions/RolesandPermissionUpdate";
import {
  useFetchRolesPermmission,
  useFetchRolesPermmissionforone,
} from "../../Hooks/Roles/useGetRolesPermission";
const RolesAndPermissionPerticular = () => {
  const navigate = useNavigate();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [productEditData, setProductEditData] = useState([]);
  const handleEditClose = () => {
    setIsEditModalOpen(false);
  };
  const { uid } = useParams();

  const handleEditSave = (inputValues) => {
    //Edit logic here
    setProductEditData(inputValues);
    setIsEditModalOpen(false);
    // setStoreIdForEdit(null);
  };

  const {
    data: rolespermissionforone,
    isLoading: rolesLoading,
    isError: rolesError,
    refetch: rolesPermissionRefetch,
  } = useFetchRolesPermmissionforone(uid);

  let RolesAndPermissionData = rolespermissionforone?.data;

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box>
        <Typography sx={Styles.chatText}>Roles & Permissions </Typography>
        <Typography sx={Styles.chatTextSub}>
          Assign, view & edit all the roles and permissions for members.
        </Typography>
      </Box>
      <Box
        sx={{
          background: "#FAFBFC",
          height: "80%",
          minHeight: "530px",
          p: 0.5,
          mt: 2,
          position: "relative",
        }}
      >
        <Box
          onClick={() => {
            navigate(
              `/admindashboard/companylanding/${rolespermissionforone?.data?.companyId}`,
              { state: { activeTab: 5 } }
            );
          }}
          component="img"
          src={BackArrow}
          sx={{
            position: "absolute",
            left: "3.5%",
            top: "5%",
            cursor: "pointer",
          }}
        />

        <Grid container mt={6}>
          <Grid item xl={4} lg={4} md={4}>
            <Card variant="outlined" sx={cardDesign}>
              <CardContent sx={cardContent}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></Box>
                <Box sx={line}>
                  <Typography sx={mainText}>Member Details</Typography>
                  <Typography
                    sx={{ ...rolestyle, mb: "10px", marginTop: "3px" }}
                  ></Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "flex-start",
                    marginTop: "10px",
                    mb: "10px",
                    width: "90%",
                  }}
                >
                  <Typography sx={name}>
                    {rolespermissionforone?.data?.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "90%",
                      mt: 2,
                    }}
                  >
                    {/*   <Typography
                      sx={{
                        ...rolestyle,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Box
                        component="img"
                        src={PhoneIcon}
                        sx={{
                          color: "#929EAE",
                          cursor: "pointer",
                          height: "25px",
                          width: "25px",
                        }}
                      ></Box>
                      +91 22-49646776
                      </Typography> */}

                    <Typography
                      sx={{
                        ...rolestyle,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 2,
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      <Box
                        component="img"
                        src={PhoneIcon}
                        sx={{
                          color: "#929EAE",
                          cursor: "pointer",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                      {rolespermissionforone?.data?.phone}
                    </Typography>
                    <Typography
                      sx={{
                        ...rolestyle,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 2,
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      <Box
                        component="img"
                        src={MailIcon}
                        sx={{
                          color: "#929EAE",
                          cursor: "pointer",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                      {rolespermissionforone?.data?.email}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={4} lg={4} md={4}>
            <Card variant="outlined" sx={cardDesign}>
              <CardContent sx={cardContent}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></Box>
                <Box sx={line}>
                  <Typography sx={mainText}>Permissions</Typography>
                  <Typography
                    sx={{ ...rolestyle, mb: "10px", marginTop: "3px" }}
                  ></Typography>
                </Box>
                <Box sx={innerTextContent}>
                  <Typography sx={name}>
                    {rolespermissionforone?.data?.roleName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "90%",
                      mt: 1,
                    }}
                  >
                    <Box sx={ticDesign}>
                      <img
                        src={tic}
                        alt=""
                        style={{ height: "15px", width: "15px", mt: 0 }}
                      />
                      <Typography sx={subText}>
                        {rolespermissionforone?.data?.productRights}
                      </Typography>
                    </Box>
                    {/* <Box sx={ticDesign}>
                            <img
                              src={tic}
                              alt=""
                              style={{ height: '15px', width: '15px', mt: 0 }}
                            />
                            <Typography sx={subText}>View & Edit </Typography>
                          </Box> */}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography sx={name}>
                      Can user edit the member role & permissions of other user?
                    </Typography>
                    <Box sx={ticDesign}>
                      <img
                        src={tic}
                        alt=""
                        style={{ height: "15px", width: "15px", mt: 0 }}
                      />
                      <Typography sx={subText}>
                        {rolespermissionforone?.data?.roleAndPermission}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={4} lg={4} md={4}>
            <Card variant="outlined" sx={cardDesign}>
              <CardContent sx={cardContent}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></Box>
                <Box sx={line}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography sx={mainText}>Coins Details</Typography>
                      <Typography sx={burnSubText}>
                        You can burn up to{" "}
                        {rolespermissionforone?.data?.tokenlimit} Coins
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={burnSubText}>History</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={innerTextContent}>
                  {/* <Box>
                    <Typography sx={name}>Burned</Typography>
                    <Box sx={TokenWithAmount}>
                      <Box
                        component="img"
                        src={bxiToken}
                        sx={{
                          height: "15px",
                          width: "auto",
                        }}
                      />
                      <Typography sx={digits}>
                        {rolespermissionforone?.data?.tokenlimit}
                      </Typography>
                    </Box>
                  </Box> */}
                  <Box>
                    <Typography sx={name}>Total Available</Typography>
                    <Box sx={TokenWithAmount}>
                      <Box
                        component="img"
                        src={bxiToken}
                        sx={{
                          height: "15px",
                          width: "auto",
                        }}
                      />
                      <Typography sx={digits}>
                        {rolespermissionforone?.data?.tokenlimit}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box sx={{ mt: 2 }}>
                    <Typography sx={name}>Last Burned</Typography>
                    <Typography sx={digits}>19-01-2023</Typography>
                  </Box> */}

                  {/* <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '90%',
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        ...rolestyle,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box
                        component="img"
                        src={PhoneIcon}
                        sx={{
                          color: '#929EAE',
                          cursor: 'pointer',
                          height: '25px',
                          width: '25px',
                        }}
                      ></Box>
                      +91 22-49646776
                    </Typography>
                    <Typography
                      sx={{
                        ...rolestyle,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 2,
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      <Box
                        component="img"
                        src={MailIcon}
                        sx={{
                          color: '#929EAE',
                          cursor: 'pointer',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                      business@bxiworld.com
                    </Typography>
                  </Box> */}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Box sx={ButtonBoxStyle}>
            {/* <Button
              sx={{
                ...ButtonStyle,
                color: "#000",
                "&:hover": {
                  background: "#fff",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                  color: "#000",
                },
              }}
            >
              Delete
            </Button> */}
            <Button
              onClick={() => {
                setIsEditModalOpen(true);
                setProductEditData(RolesAndPermissionData);
              }}
              sx={{
                ...ButtonStyle,
                background: "rgba(68, 95, 210, 1)",
                color: "#fff",
                "&:hover": {
                  background: "rgba(68, 95, 210, 1)",
                  color: "#fff",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                },
              }}
            >
              Edit Profile
            </Button>
          </Box>
          <RolesandPermissionUpdate
            key={productEditData?._id}
            open={isEditModalOpen}
            onClose={handleEditClose}
            onSave={() => {
              handleEditSave(productEditData);
            }}
            fieldOne="Phone no."
            fieldTwo="E-mail"
            initialProductData={productEditData}
            refetch={rolesPermissionRefetch}
          />
        </Grid>
      </Box>
    </Paper>
  );
};

export default RolesAndPermissionPerticular;
const rolestyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  textAlign: "center",
  color: "#929EAE",
};
const name = {
  color: "var(--text-color-text-1, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  mt: 1,
};
const digits = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 400,
};
const mainText = {
  textAlign: "left",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxHeight: "2.5em",
  lineHeight: "1.5em",
  maxWidth: "100%",
  marginTop: "5px",
  color: "var(--text-color-text-1, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 600,
};
const cardDesign = {
  background: "#FFFFFF",
  borderRadius: "10px",
  width: "330px",
  height: "282px",
  marginLeft: "auto",
  marginRight: "auto",
};
const cardContent = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const line = {
  borderBottom: "1px solid #F5F5F5",
  width: "90%",
};
const innerTextContent = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "flex-start",
  marginTop: "10px",
  mb: "10px",
  width: "90%",
};
const subText = {
  color: "var(--text-color-text-2, #929EAE)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  mt: 0,
};
const ticDesign = {
  display: "flex",
  gap: "10px",
  alignContent: "center",
  alignItems: "center",
  mt: 1,
};
const burnSubText = {
  color: "var(--text-color-text-2, #929EAE)",
  fontFamily: "Poppins",
  fontSize: 8,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};
const TokenWithAmount = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  gap: 0.5,
};
const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  minHeight: "150px",
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};
