import axios from "axios";
import React, { useEffect, useState } from "react";

const RecoverBalanceComp = (props) => {
  const [Recoverybalance, setRecoverybalance] = useState();
  const GetCreditlinerecoverybalance = async (props) => {
    try {
      const response = await axios.get(
        `wallet/get_company_wallet_recovery_balance/${props?.companyId}`,
        {
          withCredentials: true,
        }
      );
      setRecoverybalance(response.data.body.totalBalance);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GetCreditlinerecoverybalance(props);
  }, [props]);
  return <div>{Recoverybalance?.toFixed(2) || 0}</div>;
};

export default RecoverBalanceComp;
