import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Box, CircularProgress } from "@material-ui/core";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";

const HeatmapGraph = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const [label, setLabel] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [isLoading, setIsLoading] = useState(true);
  const [day, setDay] = useState("Days");

  const fetchData = async (selectedFilter) => {
    setIsLoading(true);
    let apiUrl = "";
    switch (selectedFilter) {
      case "last-week":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitors";
        setDay("Days");
        break;
      case "last-month":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfMoth";
        setDay("Weeks");
        break;
      case "current-month":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfCurrentMoth";
        setDay("Weeks");
        break;
      case "last-three-months":
        apiUrl =
          "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfLastThreeMonths";
        setDay("Months");
        break;
      case "last-six-months":
        apiUrl =
          "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfLastSixMonths";
        setDay("Months");
        break;
      case "last-year":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfLastOneYear";
        setDay("Year");
        break;
      case "current-year":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfCurrentYear";
        setDay("Year");
        break;
      case "last-five-years":
        apiUrl =
          "/api/v1/admin/AdminCompanyDetails/TotalVisitorsOfLastFiveYears";
        setDay("Years");
        break;
      default:
        apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitors";
    }

    try {
      const res = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
      // if (apiUrl = "/api/v1/admin/AdminCompanyDetails/TotalVisitors") {
      //   let data = res.data.label;

      //   data.sort(function (a, b) {
      //     const dateA = new Date(a.split('/').reverse().join('/'));
      //     const dateB = new Date(b.split('/').reverse().join('/'));

      //     return dateA - dateB;
      //   });
      //   setLabel(data);

      // } else {
      //   setLabel(res.data.label);
      // }

      setLabel(res.data.label);
      setData(res.data.data);
      // setLabel(res.data.label);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  useEffect(() => {
    if (chartRef.current && !isLoading) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      chartInstanceRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: label,
          datasets: [
            {
              label: "Total Visitors",
              data: data,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: day,
              },
            },
            y: {
              title: {
                display: true,
                text: "Total Visitors",
              },
            },
          },
        },
      });
    }
  }, [filter, label, data, isLoading]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">Total Visitors</h3>
        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <canvas ref={chartRef} id="myChart"></canvas>
      )}{" "}
    </div>
  );
};

export default HeatmapGraph;
