import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import TableContainer from "@mui/material/TableContainer";
import print from "../../../../src/assets1/Images/CommonImages/PrintInvoice.svg";
import download from "../../../../src/assets1/Images/CommonImages/downloadinvoice.svg";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BxiCoin from "../../../assets/BXIToken.svg";
import NumberToWord from "../../../common/NumberToWord";
import CommaSeprator from "../../../common/CommaSeparator";

import { useReactToPrint } from "react-to-print";
import axios from "axios";
const TaxInvoice = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const downloadRef = useRef(null);

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("print.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }, 1000);
  };

  const [OrderData, setOrderData] = useState();
  const [Fulldata, setFullData] = useState();
  const [InvoiceData, setInvoiceData] = useState();
  const [MemberShipData, setMemberShipData] = useState();
  const [SellerWebsiteData, setSellerWebsiteData] = useState();

  async function getOrderDetailsByOrderSummeryId() {
    await axios
      .get(
        `order/get_order_by_orderSummaryId/${props?.InvoiceDetailData?.OrderSummeryId}`
      )
      .then((res) => {
        setFullData(res?.data);
        setOrderData(res?.data?.TaxInvoice);
        setInvoiceData(res?.data?.InvoiceData);
      });
  }

  async function getOrderDetailsById() {
    await axios.get(`order/get_order_by_id/${id}`).then((res) => {
      setFullData(res?.data);
      setOrderData(res?.data?.TaxInvoice);
      setInvoiceData(res?.data?.InvoiceData);
    });
  }

  const GetCompanyWebsiteByID = async (id) => {
    try {
      const response = await axios.get(
        `/company/get_company/${Fulldata?.SellerCompanyId}`,
        {
          withCredentials: true,
        }
      );
      setSellerWebsiteData(response?.data?.website);
    } catch (error) {}
  };

  // const { OrderSummary: InvoiceData, loading: InvoiceDataLoading } =
  //   useSelector((state) => state.OrderSummaryD);

  let storeDataIds = [];
  let TotalQuantity = 0;
  let totalAmount = 0;
  let totalPricePerUnit = 0;
  let totatlTaxableAmount = 0;
  let totalGST = 0;
  let totalAmountWithGST = 0;
  let totalAmountWithTax = 0;
  let totaltaxvalue = 0;
  let totalCGSTAmount = 0;
  let totalIGSTPercentage = 0;
  let totalSGSTAmount = 0;
  let totalSGSTPercentage = 0;
  let totalCSTPerCentage = 0;
  let TotalGSTAmount = 0;
  let TotalTokanAmount = 0;
  let Totalcount = 0;
  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;

  OrderData?.ProductData?.map((item) => {
    let TotalSec = item?.BoughtSeconds * 10;
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
    totalAmount +=
      OrderData?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalPricePerUnit += item.PricePerUnitWithoutGST;
    TotalTokanAmount +=
      OrderData?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item?.PriceWithoutGST;

    totatlTaxableAmount +=
      OrderData?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalGST += item.GST;
    totalAmountWithGST += (
      item?.DiscountedPrice *
      item?.ProductQuantity *
      (item?.GST / 100)
    )?.toFixed(2);
    totalAmountWithTax +=
      OrderData?.ProductTypeName === "Media"
        ? item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds *
            (item?.GST / 100) +
          item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds
        : item?.TotalPriceWithGSTInRupee;

    totalCGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTPercentage += item.GST / 2;
    totalCSTPerCentage += item.GST / 2;
    totalIGSTPercentage += item.GST;
    TotalGSTAmount += item?.TotalGSTInBXI;
    totaltaxvalue += item?.TotalGSTInBXI;
  });

  useEffect(() => {
    if (props?.InvoiceDetailData) {
      getOrderDetailsByOrderSummeryId();
    } else {
      getOrderDetailsById();
    }
  }, []);

  useEffect(() => {
    GetCompanyWebsiteByID();
  }, [Fulldata]);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  let AdCostInrTotal = 0;
  let AdCostBxiTotal = 0;
  let TotalAdCostGstAmount = 0;
  let AdcostTotalTaxableAmount = 0;

  OrderData?.ProductData?.map((item) => {
    item?.AdditionCost?.AdditionCostArray?.map((res) => {
      TotalAdCostGstAmount += Number(res?.GstPrice * 10);
      if (res?.currencyType === "₹") {
        AdCostInrTotal += res?.GstPrice;
      } else if (res?.currencyType === "BXITokens") {
        AdCostBxiTotal += res?.GstPrice;
      }
    });
  });

  InvoiceData?.ProductData?.map((item, index) => {
    item?.AdditionCost?.AdditionCostArray?.forEach((item, index) => {
      AdcostTotalTaxableAmount += item?.TotalTaxableAmount;
    });
  });

  let BuyerId = InvoiceData?.BuyerDetails?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [InvoiceData]);

  let BxiCommision = Fulldata?.PurchaseOrderData?.CouponData?.BXICommission
    ? Number(Fulldata?.PurchaseOrderData?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "Packaging charges",
      gst: Number(InvoiceData?.PackagingData?.packagingcostgstfee),
      hsn: "998540",
      taxableValue: Number(InvoiceData?.PackagingData?.packagingcosts),
      totalTaxAmount:
        (Number(InvoiceData?.PackagingData?.packagingcosts) *
          Number(InvoiceData?.PackagingData?.packagingcostgstfee)) /
        100,
    },
    {
      name: "Logistics Charges",
      gst:
        Fulldata?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : Number(InvoiceData?.Transportationdata?.gstFee || 0),
      hsn: "996519",
      taxableValue:
        Fulldata?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : Number(InvoiceData?.Transportationdata?.transportationfee || 0),
      totalTaxAmount:
        Fulldata?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : (Number(InvoiceData?.Transportationdata?.transportationfee || 0) *
              Number(InvoiceData?.Transportationdata?.gstFee || 0)) /
            100,
    },
    {
      name: "Insurance Cost",
      gst: Number(Fulldata?.INRDetails?.InsuranceCostGst),
      hsn: "997135",
      taxableValue: Number(Fulldata?.INRDetails?.InsuranceCost),
      totalTaxAmount:
        (Number(Fulldata?.INRDetails?.InsuranceCost) *
          Number(Fulldata?.INRDetails?.InsuranceCostGst)) /
        100,
    },
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      hsn: "996211",
      taxableValue:
        (Number(InvoiceData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(InvoiceData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let amountbeforeTax =
    ChargesTaxableValue + totatlTaxableAmount + AdcostTotalTaxableAmount;

  let totalTaxAmount =
    InvoiceData?.PITotals?.TotalGstAmount +
    InvoiceData?.PITotals?.TotalAdCostGstAmount +
    ChargesTaxableAmount;

  let invoiceTotalAmount = (totalTaxAmount + amountbeforeTax).toFixed(2);
  let InsuranceAmount = InvoiceData?.InsuranceCost
    ? InvoiceData?.InsuranceCost
    : 0;

  let TDS = Fulldata?.PurchaseOrderData?.TDS_PERCENT ?? 0.1;

  let tdsAmount = (((amountbeforeTax + InsuranceAmount) * TDS) / 100).toFixed(
    2
  );
  let totalAmountWithTDS = Number(invoiceTotalAmount);
  // let totalAmountWithTDS = Number(invoiceTotalAmount) + Number(tdsAmount);

  return (
    <Box
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          mx: "auto",
          borderRadius: "17px",
          pb: "40px",
        }}
        elevation={1}
      >
        <Box ref={componentRef}>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
            // ref={printRef}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
              ref={downloadRef}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src={OrderData?.SellerDetails?.SellerCompanyLogo}
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderData?.SellerDetails?.SellerCompanyName}
                </Typography>
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    textAlign: "center",
                    fontSize: "11px",
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderData?.SellerDetails?.Address?.AddressLine}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  Tel:{OrderData?.SellerDetails?.SellerCompanyContact}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  GSTIN:{OrderData?.SellerDetails?.GSTIN}
                </Typography>
              </Box>
              <Box
                sx={{
                  color: "#445FD2",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                  borderLeft: "1px solid #cdcdcd",
                }}
              >
                {SellerWebsiteData ? SellerWebsiteData : null}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: "#2261A2",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                {"Tax Invoice"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  Invoice Number: {OrderData?.FINumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  Invoice Date: {convertDate(OrderData?.createdAt)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
                borderTop: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  P.O. Date: {convertDate(OrderData?.createdAt)}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  P.O. Number: {OrderData?.PoNumber}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: "#2261A2",
                  borderRadius: "3px 0px 0px 3",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "10px",
                      color: "white",
                    }}
                  >
                    Bill to Party
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    Ship to Party
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody sx={{ p: 0 }}>
                      <TableRow sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer name :
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Address:{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.AddressLine}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.GSTIN
                              ? OrderData?.BuyerDetails?.Address?.GSTIN
                              : OrderData?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State: </Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            position: "relative",
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.State}
                          </Typography>
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderData?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {Fulldata?.PurchaseOrderData?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer name :
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderData?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Address:{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.AddressLine}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderData?.BuyerDetails?.Address?.GSTIN
                              ? OrderData?.BuyerDetails?.Address?.GSTIN
                              : OrderData?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State:</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            position: "relative",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderData?.BuyerDetails?.Address?.State}
                          </Typography>{" "}
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderData?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {Fulldata?.PurchaseOrderData?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                <TableHead>
                  <TableRow sx={{ height: "42px", bgcolor: "#2261A2" }}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}> Sr. No.</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>
                        Product / Service Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>
                        HSN / SAC
                        <br />
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>QTY</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Rate</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Amount</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Taxable Value</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {InvoiceData?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;
                    Totalcount += 1;
                    return (
                      <React.Fragment key={idx}>
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {Totalcount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductName}
                            </Typography>

                            {row?.TotalSec && (
                              <Typography>
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                  : `(${TotalSec} sec) (${row?.TimelineToBought} ${row?.Timeline})`}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.HSN}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.ProductQuantity} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.PricePerUnitWithoutGST}
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {" "}
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "13px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "14px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;Additional Cost
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>

                  {InvoiceData?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;

                    return (
                      <React.Fragment key={idx}>
                        {row?.AdditionCost?.AdditionCostArray?.map(
                          (res, index) => {
                            Totalcount += 1;
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                                key={index}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {Totalcount}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    // px: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      fontSize: "13px",
                                      width: "300px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    &nbsp;{res?.ReasonOfCost} {"  "}({" "}
                                    {res?.AdCostApplicableOn === "PerUnit"
                                      ? "Per Unit"
                                      : "One Time"}{" "}
                                    ){" "}
                                    {res?.currencyType === "₹" ? (
                                      "₹"
                                    ) : (
                                      <img
                                        src={BxiCoin}
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                        }}
                                      />
                                    )}{" "}
                                    ({row?.ProductName}){" "}
                                  </Typography>

                                  {row?.TotalSec && (
                                    <Typography>
                                      {row?.ProductTypeId === "MediaOffline" ||
                                      row?.ProductTypeId ===
                                        "News Papers / Magazines"
                                        ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                        : `(${TotalSec} sec) (${row?.TimelineToBought} ${row?.Timeline})`}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                    }}
                                  >
                                    {res?.AdCostHSN}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={row?.ProductQuantity}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.PriceWithoutGST}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBottomtext,
                                      color: "rgba(5, 5, 5, 1)",
                                      opacity: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}

                  {ChargesArray?.map((res, idx) => {
                    Totalcount += 1;
                    return (
                      <TableRow
                        sx={{
                          height: "25px",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography sx={TableBottomtext}>
                            {Totalcount}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontSize: "13px",
                              width: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color:
                                res?.name === "BXI Commission"
                                  ? "red"
                                  : "#000000",
                            }}
                          >
                            &nbsp;{res?.name}{" "}
                            {res?.commission ? `@ ${res?.commission}%` : ""}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            {res?.hsn}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                              textAlign: "right",
                            }}
                          >
                            <CommaSeprator Price={res?.taxableValue} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow
                    sx={{
                      height: "25px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography sx={TableBottomtext}>&nbsp;</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontSize: "14px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow
                    sx={{
                      height: "42px",
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={3}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        background: "#2261A2",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Total amount before Tax
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={TotalQuantity} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={amountbeforeTax.toFixed(2)} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        textAlign: "right",
                        px: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={amountbeforeTax.toFixed(2)} />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Terms & Conditions
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      {" "}
                      <Typography sx={TableBottomtext}>
                        {" "}
                        &nbsp;&nbsp;Add: GST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;CGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {!InvoiceData?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator
                            Price={
                              (Number(InvoiceData?.PITotals?.TotalGstAmount) +
                                Number(
                                  InvoiceData?.PITotals?.TotalAdCostGstAmount
                                ) +
                                Number(ChargesTaxableAmount)) /
                              2
                            }
                          />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Payment 100% in advance
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;SGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {!InvoiceData?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator
                            Price={
                              (Number(InvoiceData?.PITotals?.TotalGstAmount) +
                                Number(
                                  InvoiceData?.PITotals?.TotalAdCostGstAmount
                                ) +
                                Number(ChargesTaxableAmount)) /
                              2
                            }
                          />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                      }}
                    ></TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        &nbsp;&nbsp;IGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {InvoiceData?.IsIGST ? (
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator
                            Price={
                              Number(InvoiceData?.PITotals?.TotalGstAmount) +
                              Number(
                                InvoiceData?.PITotals?.TotalAdCostGstAmount
                              ) +
                              Number(ChargesTaxableAmount)
                            }
                          />
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        // borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Total amount Payable (in words)
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Total GST Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={totalTaxAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        {InvoiceData?.PITotals ? (
                          <NumberToWord number={totalAmountWithTDS.toFixed()} />
                        ) : null}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        Invoice Total Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={invoiceTotalAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#4361ee",
                        }}
                      >
                        * Our Services are subject to TDS of {TDS}% u/s 194-O of
                        the Income Tax act, 1961.
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>TDS @ {TDS}%</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                      }}
                    ></TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={tdsAmount} />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#4361ee",
                        }}
                      ></Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderLeft: "2px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableBottomtext}>
                        Total Payable (Round off)
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator Price={totalAmountWithTDS.toFixed()} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table
                sx={{
                  borderLeft: "1px solid #CDCDCD",
                }}
              >
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                      borderBottom: "2px solid #CDCDCD",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            // bgcolor: "green",
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            HSN / SAC
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            Taxable Value
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "220px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "220px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            CGST Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "220px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "220px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            SGST Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "220px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "220px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            Integrated Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TableBottomtext,
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              fontWeight: 600,
                            }}
                          >
                            Total Tax Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>

                  {InvoiceData?.ProductData?.map((res, idx) => {
                    return (
                      <TableRow
                        sx={{
                          height: "21px",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                {res?.HSN}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                              px: 0,
                            }}
                          >
                            <TableCell
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                                textAlign: "right",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={res?.TotalPriceWithoutGST}
                                />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.GST / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.TotalGSTAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.GST / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.TotalGSTAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.GST} /> %
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.TotalGSTAmount} />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>

                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "200px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "200px",
                                border: "none",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.TotalGSTAmount} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {InvoiceData?.ProductData?.map((row, idx) => {
                    return row?.AdditionCost?.AdditionCostArray?.map(
                      (res, index) => {
                        let GstOfAdCost = Number(res?.AdCostGST);
                        return (
                          <TableRow
                            sx={{
                              height: "21px",
                            }}
                          >
                            <TableCell
                              sx={{
                                p: 0,
                                maxWidth: "180px",
                                mx: "auto",
                                borderRight: "1px solid #CDCDCD",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "180px",
                                    p: 0,
                                    height: "20px",
                                    border: "none",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostHSN}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                maxWidth: "180px",
                                mx: "auto",
                                borderRight: "1px solid #CDCDCD",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "right",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "180px",
                                    p: 0,
                                    height: "20px",
                                    border: "none",
                                    textAlign: "right",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                width: "220px",
                                mx: "auto",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "220px",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    width: "110px",
                                  }}
                                >
                                  {" "}
                                  {!InvoiceData?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator
                                        Price={res?.AdCostGST / 2}
                                      />
                                      %
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    width: "110px",
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                    px: 0,
                                  }}
                                >
                                  {" "}
                                  {!InvoiceData?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            (res?.PriceWithoutGST *
                                              row?.ProductQuantity *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            (res?.PriceWithoutGST *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                width: "220px",
                                mx: "auto",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "220px",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    width: "110px",
                                  }}
                                >
                                  {" "}
                                  {!InvoiceData?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator
                                        Price={res?.AdCostGST / 2}
                                      />
                                      %
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    width: "110px",
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                    px: 0,
                                  }}
                                >
                                  {" "}
                                  {!InvoiceData?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            (res?.PriceWithoutGST *
                                              row?.ProductQuantity *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            (res?.PriceWithoutGST *
                                              (GstOfAdCost / 100)) /
                                            2
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0,
                                width: "220px",
                                mx: "auto",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "220px",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    width: "110px",
                                  }}
                                >
                                  {" "}
                                  {InvoiceData?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator Price={res?.AdCostGST} /> %
                                    </Typography>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    width: "110px",
                                    borderRight: "1px solid #CDCDCD",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                    px: 0,
                                  }}
                                >
                                  {" "}
                                  {InvoiceData?.IsIGST ? (
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            res?.PriceWithoutGST *
                                            row?.ProductQuantity *
                                            (GstOfAdCost / 100)
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            res?.PriceWithoutGST *
                                            (GstOfAdCost / 100)
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableCell>

                            <TableCell
                              sx={{
                                p: 0,
                                maxWidth: "200px",
                                mx: "auto",
                                borderRight: "1px solid #CDCDCD",
                              }}
                              align="center"
                            >
                              <TableRow
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "right",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "200px",
                                    border: "none",
                                    p: 0,
                                    height: "20px",
                                    textAlign: "right",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {res?.AdCostApplicableOn === "PerUnit" ? (
                                      <CommaSeprator
                                        Price={
                                          res?.PriceWithoutGST *
                                          row?.ProductQuantity *
                                          (GstOfAdCost / 100)
                                        }
                                      />
                                    ) : (
                                      <CommaSeprator
                                        Price={
                                          res?.PriceWithoutGST *
                                          (GstOfAdCost / 100)
                                        }
                                      />
                                    )}
                                    &nbsp;
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    );
                  })}

                  {ChargesArray?.map((res, idx) => {
                    return (
                      <TableRow
                        sx={{
                          height: "21px",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                {res?.hsn}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "180px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "180px",
                                p: 0,
                                height: "20px",
                                border: "none",
                                textAlign: "right",
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.taxableValue} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.gst / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.totalTaxAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.gst / 2} />%
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {!InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.totalTaxAmount / 2}
                                  />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            width: "220px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "220px",
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                width: "110px",
                              }}
                            >
                              {" "}
                              {InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.gst} /> %
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: "110px",
                                borderRight: "1px solid #CDCDCD",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              {" "}
                              {InvoiceData?.IsIGST ? (
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.totalTaxAmount} />
                                  &nbsp;
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableCell>

                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "200px",
                            mx: "auto",
                            borderRight: "1px solid #CDCDCD",
                          }}
                          align="center"
                        >
                          <TableRow
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{
                                width: "200px",
                                border: "none",
                                p: 0,
                                height: "20px",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={res?.totalTaxAmount} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography sx={TableBottomtext}></Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={TableBottomtext}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "220px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={TableBottomtext}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "220px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={TableBottomtext}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "220px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography
                            sx={{ ...TableBottomtext, fontWeight: 600 }}
                          >
                            Total GST Amount &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                            textAlign: "right",
                            px: 0,
                          }}
                        >
                          <Typography
                            sx={{ ...TableBottomtext, fontWeight: 600 }}
                          >
                            <CommaSeprator Price={totalTaxAmount} />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={7}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      &nbsp;
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                borderRight: "1px solid #CDCDCD",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        minWidth: "380px",
                        borderRight: "1px solid #CDCDCD",
                        bgcolor: "#156DB6",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "#ffffff" }}>
                        Bank Details :{" "}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          width: "380px",
                          textAlign: "end",
                          fontSize: "12px",
                        }}
                      >
                        For Seller Company Name
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Account No. : {OrderData?.BankDetails?.AccountNumber}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={6}
                      sx={tablecell}
                    >
                      {/* <img
                          src={BXIStamp}
                          style={{
                            maxHeight: "160px",
                            height: "auto",
                            width: "auto",
                          }}
                        /> */}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        IFSC Code : {OrderData?.BankDetails?.IFSC}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        Branch Name : {OrderData?.BankDetails?.BranchName}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Bank Name : {OrderData?.BankDetails?.IFSC?.slice(0, 4)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    rowSpan={1}
                    sx={{ ...tablecell, borderTop: "1px solid #CDCDCD" }}
                  >
                    <Typography sx={TableBottomtext}>
                      Ceritified that the particulars given above are true and
                      correct
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    rowSpan={1}
                    sx={{ ...tablecell, borderTop: "1px solid #CDCDCD" }}
                  >
                    <Typography sx={TableBottomtext}>
                      This is a System Generated Invoice by BXI WORLD LLP
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "360px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={print} sx={ButtonIconStyle} />{" "}
              &nbsp;Print
            </Button>

            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={download} sx={ButtonIconStyle} />{" "}
              &nbsp;Download
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TaxInvoice;

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const AddressInputStyle = {
  width: "186px",
  height: "35px",
  background: "#F9F9F9",
  borderRadius: "6px",
  border: "none",
  paddingLeft: "10px",
};

const AddressInputTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "10px",
  color: "#6B7A99",
};

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const AddressTextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#505050",
  opacity: 1,

  width: "300px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  opacity: 1,
  color: "#000000",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const RejectReasonBtnStyle = {
  width: "100%",
  mx: "auto",
  textTransform: "none",
  height: "40px",
  borderRadius: "6px",
  border: "1px solid #E4E7EC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ButtonGroupstyle = {
  width: "100%",
  border: "none",
};

const TextAndCheckBoxGroupStyle = {
  width: "100%",
  px: "10px",
  cursor: "pointer",
};

const TextAreaStyle = {
  width: "100%",
  mx: "auto",
  background: "#ECEFF1",
  color: "#C7C7CC",
  border: "1px solid lighgray",
  height: "100px",
  "& fieldset": { border: "none" },
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  my: 1,
};

const InputPropsStyle = {
  // disableUnderline: true,
  sx: {
    background: "#ECEFF1",
    fontFamily: "Poppins",
    color: "#445FD2",
    borderRadius: "10px",
    fontSize: "14px",
    width: "100%",
  },
};

const ReasonTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#6B7A99",
};

const TextLastStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  // lineHeight: "20px",
  color: "#000000",
  opacity: 1,
};

const BanktText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "11px",
  color: "#505050",
};

const BanktbottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "9px",
  lineHeight: "11px",
  color: "#505050",
};

const btnsx = {
  width: "100%",
  height: "51px",
  maxWidth: "168px",
  borderRadius: "10px",
  borderColor: "#156DB6",
  color: "#156DB6",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
