import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { GetTokenFunc } from "../../utils/GetToken";
import { async } from "react-input-emoji";
const AddAdminMember = async (data) => {
  return await axios.post(
    "/api/v1/admin/admin-manage",
    { ...data },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useAddAdminMember = () => {
  return useMutation(AddAdminMember);
};

const FetchAdminMember = async (search) => {
  return await axios.get(`/api/v1/admin/admin-manage?search=${search}`, {
    headers: {
      authorization: `bearer ${GetTokenFunc()}`,
    },
  });
};
export const useFetchAdminMember = (search) => {
  return useQuery("getadminMember", () => FetchAdminMember(search));
};

const FetchAdminMemberById = async (id) => {
  return await axios.get(`/api/v1/admin/admin-manage/${id}`, {
    headers: {
      authorization: `bearer ${GetTokenFunc()}`,
    },
  });
};
export const useFetchAdminMemberById = (id) => {
  return useQuery("One-member", () => FetchAdminMemberById(id));
};
const DeleteAdminMember = async (id) => {
  return await axios.delete(
    `/api/v1/admin/admin-manage/delet-admin-user/${id}`,
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};

export const useDeleteAdminMembetr = () => {
  return useMutation(DeleteAdminMember);
};

const UpdateAdminMember = async (data) => {
  return await axios.put(
    `/api/v1/admin/admin-manage/update-admin-user/${data.id}`,
    {
      email: data.email,
      acess: data.acess,
      role: data.role,
    },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useUpdateAdminMember = () => {
  return useMutation(UpdateAdminMember);
};
const updateAdminMemberPassword = async (data) => {
  return await axios.put(
    `/api/v1/admin/admin-manage/update-admin-password/${data.id}`,
    {
      password: data.password,
    },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useUpdateAdminMemberPassword = () => {
  return useMutation(updateAdminMemberPassword);
};
