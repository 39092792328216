import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Button,
  Paper,
  Divider,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFetchVoucherOrders } from "../../Hooks/AllRecords/useAllRecords";
import * as Styles from "../../common/Styles";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/CloseIcon.svg";
import PageLoader from "../CommonActions/PageLoader";
import { Tab, TableContainer } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function VoucherOrders() {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const {
    data: fetchOrders,
    isLoading: invoicesLoading,
    refetch: ordersRefetch,
  } = useFetchVoucherOrders();

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const [openFilter, setOpenFilter] = React.useState(false);
  const [expanded, setExpanded] = useState(null);
  const [releaseFundData, setReleaseFundData] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const HandleReleaseModal = (data) => {
    setReleaseFundData(data);
    handleOpen();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    ordersRefetch();
  }, [currentPage]);

  useEffect(() => {
    if (fetchOrders && fetchOrders?.totalPages !== undefined) {
      setInitialPaginationCount(fetchOrders?.totalPages);
    }
  }, [fetchOrders?.totalPages]);

  const MakeEscrowPayment = async (PayData) => {
    await axios
      .post("/escrow/release-funds", {
        OrderId: PayData?._id,
        IsVoucher: true,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  const MakeEscrow_BXI_Release = async (PayData) => {
    await axios
      .post("/escrow/release-funds-bxi", {
        OrderId: PayData?._id,
        IsVoucher: true,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  async function BXICommision(OrderId) {
    let WalletRealease = await axios
      .post(`commission/create_bxi_commision_invoice`, {
        OrderId: OrderId,
        IsVoucher: false,
      })
      .then((res) => {})
      .catch((err) => {});
  }

  const Make_refund_escrow = async (OrderId) => {
    try {
      await axios
        .post("/escrow/refund-funds", { OrderId: OrderId })
        .then((res) => {});
    } catch (e) {}
  };

  const MakeEscrow_ReleaseFunds = async (OrderId) => {
    await axios
      .post("escrow/upload-relase-fund-doc", {
        OrderId: OrderId,
      })
      .then((res) => {
        MakeEscrowPayment(res?.data?.OrderData);
      });
  };

  const MakeEscrow_BXI_ReleaseFunds = async (OrderId) => {
    await axios
      .post("escrow/upload-relase-fund-doc", {
        OrderId: OrderId,
        IsVoucher: true,
      })
      .then((res) => {
        MakeEscrow_BXI_Release(res?.data?.OrderData);
      });
  };

  const MakeWalletTransaction = async (Order) => {
    await axios
      .post(`wallet/release_onhold_transaction`, {
        transaction_id: Order?.WalletData?._transaction_id,
        OrderId: Order?._id,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "590px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <React.Fragment>
        <Paper elevation={0}>
          <Box
            className="page-heading-section"
            style={{
              justifyContent: "start",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/*     <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardBackspaceIcon />
            </Box> */}
            <Box className="page-heading">
              <h5>Release Funds Immediately on Delivery of Voucher Codes</h5>
              <Typography>
                Notify Buyer Immediately as Codes are Delivered and Funds
                Released
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2, mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "97%",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Box sx={buddge}>
                <Typography sx={textBudge}>
                  {fetchOrders instanceof Object ? (
                    <Typography sx={textBudge}>
                      Total Order(s): {fetchOrders?.data?.length || 0}
                    </Typography>
                  ) : (
                    <Typography sx={textBudge}>
                      Total Order(s):{fetchOrders?.data?.length || 0}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Buyer Company</TableCell>
                    <TableCell>Seller Company</TableCell>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount to Release</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchOrders?.data?.map((data, idx) => {
                    const formattedDate = data ? formatDate(data?.PoDate) : "";
                    const formattedProductDate = data
                      ? formatDate(data?.PurchaseOrderData?.PoDate)
                      : "";
                    return (
                      <TableRow>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={Styles.MapingData}
                            align="center"
                          >
                            {
                              data?.PurchaseOrderData.BuyerDetails
                                ?.BuyerCompanyName
                            }
                            <br />
                            {data?.PurchaseOrderData.BuyerDetails?.Address
                              ?.City === "Not Found"
                              ? ""
                              : data?.PurchaseOrderData.BuyerDetails?.Address
                                  ?.City}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={Styles.MapingData}
                            align="center"
                          >
                            {
                              data?.PurchaseOrderData.SellerDetails
                                ?.SellerCompanyName
                            }
                            <br />
                            {data?.PurchaseOrderData.SellerDetails?.Address
                              ?.City === "Not Found"
                              ? ""
                              : data?.PurchaseOrderData.SellerDetails?.Address
                                  ?.City}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={Styles.MapingData}
                            align="center"
                          >
                            {data?.OrderId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={Styles.MapingData}
                            align="center"
                          >
                            {formattedDate}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={Styles.MapingData}
                            align="center"
                          >
                            {data?.INRDetails?.TotalInrToPay?.toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={Styles.MapingData}
                            align="center"
                          >
                            <Box
                              sx={{
                                background:
                                  data?.SellerOrderStatus === "completed"
                                    ? "#D9FFE9"
                                    : data?.SellerOrderStatus === "pending"
                                    ? "#FFF1E5"
                                    : data?.SellerOrderStatus === "failed"
                                    ? "#FFEFEF"
                                    : "#FFEFEF",
                                borderRadius: 1.5,
                              }}
                            >
                              <Typography
                                align="center"
                                sx={{
                                  color:
                                    data?.SellerOrderStatus === "completed"
                                      ? "var(--Green-2, #27AE60)"
                                      : data?.SellerOrderStatus === "Pending"
                                      ? "#F2994A"
                                      : data?.SellerOrderStatus === "failed"
                                      ? "var(--Red, #EB5757)"
                                      : "var(--Red, #EB5757)",

                                  fontFamily: "poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  lineHeight: "30px",
                                }}
                              >
                                {data?.SellerOrderStatus === "completed"
                                  ? "Onboarding Completed"
                                  : data?.SellerOrderStatus === "Pending"
                                  ? "pending"
                                  : data?.SellerOrderStatus === "failed"
                                  ? "N/A"
                                  : null}
                              </Typography>
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {data?.WalletData && (
                            <Button
                              onClick={() => {
                                HandleReleaseModal(data);
                              }}
                            >
                              Release
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Actions</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ display: "grid", gap: "20px" }}>
                        <Button
                          variant="contained"
                          sx={{
                            background: "#27AE60",
                            color: "#fff",
                            textTransform: "none",
                            width: "100%",
                            maxWidth: "200px",
                            height: "100%",
                            "&:hover": {
                              background: "#27AE60",
                            },
                          }}
                          onClick={() => {
                            MakeEscrow_ReleaseFunds(releaseFundData?._id);
                          }}
                        >
                          Release Seller Escrow Funds
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" align="center">
                          {(
                            releaseFundData?.INRDetails?.totalGstAmount +
                            releaseFundData?.INRDetails?.totalGstInrOfAdCost +
                            releaseFundData?.INRDetails?.totalValueInrOfAdCost
                          )?.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={Styles.MapingData}
                          align="center"
                        >
                          Pending
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{
                            background: "#27AE60",
                            color: "#fff",
                            textTransform: "none",
                            width: "100%",
                            maxWidth: "200px",
                            height: "100%",
                            "&:hover": {
                              background: "#27AE60",
                            },
                          }}
                          onClick={() => {
                            MakeEscrow_BXI_ReleaseFunds(releaseFundData?._id);
                          }}
                        >
                          Release BXI Escrow Funds
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" align="center">
                          {(
                            releaseFundData?.INRDetails?.bxiCommisionGST +
                            releaseFundData?.INRDetails?.totalBxiCommission
                          )?.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={Styles.MapingData}
                          align="center"
                        >
                          Pending
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {releaseFundData?.Wallet_transaction_status !==
                        "released" ? (
                          <Button
                            variant="contained"
                            sx={{
                              background: "#27AE60",
                              color: "#fff",
                              textTransform: "none",
                              width: "100%",
                              maxWidth: "200px",
                              height: "100%",
                              "&:hover": {
                                background: "#27AE60",
                              },
                            }}
                            onClick={() => {
                              MakeWalletTransaction(releaseFundData);
                            }}
                          >
                            Release Wallet Funds
                          </Button>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: "12px",
                            }}
                          >
                            Coins Already Released
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" align="center">
                          {releaseFundData?.WalletData?._balance?.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={Styles.MapingData}
                          align="center"
                        >
                          {releaseFundData?.Wallet_transaction_status ===
                          "released"
                            ? "Released"
                            : "Pending"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Button
                          onClick={() => BXICommision(releaseFundData?._id)}
                          sx={{
                            bgcolor: "lightgreen",
                            border: "none",
                            color: "white",
                            textDecoration: "none",
                            textTransform: "none",
                            width: "100%",
                            maxWidth: "200px",
                            ":hover": {
                              bgcolor: "lightgreen",
                            },
                          }}
                        >
                          Send Commision
                        </Button>
                      </TableCell>
                      <TableCell>
                        {releaseFundData?.SellerOrderStatus === "success" &&
                        releaseFundData?.BuyerOrderStatus === "success" ? (
                          <Button
                            onClick={() =>
                              Make_refund_escrow(releaseFundData?._id)
                            }
                            variant="outlined"
                            sx={{
                              bgcolor: "red",
                              border: "none",
                              color: "white",
                              textDecoration: "none",
                              textTransform: "none",
                              width: "100%",
                              maxWidth: "200px",
                            }}
                          >
                            Make Refund
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Modal>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                mx: "auto",
              }}
              mt={2}
            >
              <Stack>
                <StyledPagination
                  size="medium"
                  count={initialPaginationCount}
                  color="primary"
                  showFirstButton
                  showLastButton
                  page={currentPage}
                  onChange={handlePageChange}
                  strokeWidth={currentPage}
                />
              </Stack>
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    </Paper>
  );
}

export default VoucherOrders;

const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const TotalReqStyle = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
  position: "absolute",
  left: 0,
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};
