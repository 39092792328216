import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
  Divider,
  Stack,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import FigmaIcon from "../../assets/figma-icon.png";
import * as Styles from "../../common/Styles";
import Popup from "../Layouts/Popup/Popup";
import filter from "../../assets/filter.png";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { GetTokenFunc } from "../../utils/GetToken";
import Searchbar from "../../common/Searchbar";
const GenieActiveInavtive = () => {
  const navigate = useNavigate();
  const [compBtn, setCompBtn] = useState(true);
  const [prodBtn, setProdBtn] = useState(false);
  const [select, setSelect] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const [category, setCategory] = useState();
  const [dataTypes, setDataTypes] = useState("Active");
  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [active, setActive] = useState();
  const [initialPaginationCount, setInitialPaginationCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [searchData, setSearchData] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "0px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "0px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const genieneactiveinactiveDataFetch = async () => {
    await axios
      .get(
        `/api/v1/admin/AdminCompanyDetails/ActiveOrInactiveCompanys?${dataTypes}=${dataTypes}&page=${currentPage}&search=${searchData}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setActive(res.data);
        setLoader(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    genieneactiveinactiveDataFetch();
    setLoader(true);
  }, [currentPage, dataTypes, searchData]);

  useEffect(() => {
    setInitialPaginationCount(active?.totalPages);
  }, [currentPage, dataTypes, active, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const AcitiveDataRender = () => {
    return (
      <Box sx={{ background: "#FAFBFC", p: 0.2 }}>
        {active?.data?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : (
          <Box>
            <Grid
              container
              sx={{
                p: 2,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={0.6}
                lg={0.6}
                md={0.6}
                sm={0.6}
                xs={0.6}
                sx={Styles.textHead}
              >
                No
              </Grid>
              <Grid
                item
                xl={2.4}
                lg={2.4}
                md={2.4}
                sm={2.4}
                xs={2.4}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Category
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Location
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Cin No
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Website
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Membership
              </Grid>
            </Grid>

            <Divider />

            {active?.data?.map((x, i) => {
              const CategoryName = [
                {
                  id: "63e38bddcc4c02b8a0c94b8a",
                  CategoryName: "Others",
                },
                {
                  id: "63e38b9ccc4c02b8a0c94b6f",
                  CategoryName: "Lifestyle",
                },
                {
                  id: "63e38bb9cc4c02b8a0c94b7b",
                  CategoryName: "FMCG",
                },
                {
                  id: "63e38b91cc4c02b8a0c94b69",
                  CategoryName: "Media",
                },
                {
                  id: "63e38ba3cc4c02b8a0c94b72",
                  CategoryName: "Hotel",
                },
                {
                  id: "63e38bbfcc4c02b8a0c94b7e",
                  CategoryName: "Airlines Tickets",
                },
                {
                  id: "63e38bc6cc4c02b8a0c94b81",
                  CategoryName: "Office Supply",
                },
                {
                  id: "63e38bb3cc4c02b8a0c94b78",
                  CategoryName: "Mobility",
                },
                {
                  id: "63e38bcecc4c02b8a0c94b84",
                  CategoryName: "Electronics",
                },
                {
                  id: "63e38b96cc4c02b8a0c94b6c",
                  CategoryName: "Textile",
                },
                {
                  id: "64218b189fe1b6ae750c11bd",
                  CategoryName: "Entertainment & Events",
                },
                {
                  id: "63e38bd5cc4c02b8a0c94b87",
                  CategoryName: "QSR",
                },
              ];
              return (
                <Grid
                  container
                  sx={{
                    borderBottom: "1px solid rgba(245, 245, 245, 1)",
                    mt: 1,
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Grid
                    item
                    xl={0.6}
                    lg={0.6}
                    md={0.6}
                    sm={0.6}
                    xs={0.6}
                    align="left"
                    className="product-title-colum"
                  >
                    {(currentPage - 1) * 10 + i + 1}
                  </Grid>
                  <Grid
                    item
                    xl={2.4}
                    lg={2.4}
                    md={2.4}
                    sm={2.4}
                    xs={2.4}
                    align="left"
                    className="product-title-colum"
                  >
                    <img
                      src={x?.CompanyAvatar?.url}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    />
                    <Box sx={Styles.headProductText}>{x?.companyName}</Box>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {CategoryName?.filter(
                      (product) => product?.id === x?.companyType
                    ).reduce((idx, item) => {
                      return <span>{item?.CategoryName}</span>;
                    }, "--")}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.CompanyAddress?.State}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.cin}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.website || "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.memberships?.at(x?.memberships?.length - 1)
                      ?.MembershipType === "basic"
                      ? "Standard"
                      : x?.memberships?.at(x?.memberships?.length - 1)
                          ?.MembershipAmount === "advance"
                      ? "Advance"
                      : "--"}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
      </Box>
    );
  };

  const InAcitiveDataRender = () => {
    return (
      <Box sx={{ background: "#FAFBFC", p: 0.2 }}>
        {active?.data?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : (
          <Box>
            <Grid
              container
              sx={{
                p: 2,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={0.6}
                lg={0.6}
                md={0.6}
                sm={0.6}
                xs={0.6}
                sx={Styles.textHead}
              >
                No
              </Grid>
              <Grid
                item
                xl={2.4}
                lg={2.4}
                md={2.4}
                sm={2.4}
                xs={2.4}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Category
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Location
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Member Name
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                sx={Styles.textHead}
              >
                Website
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Membership
              </Grid>
            </Grid>
            <Divider />

            {active?.data?.map((x, i) => {
              const CategoryName = [
                {
                  id: "63e38bddcc4c02b8a0c94b8a",
                  CategoryName: "Others",
                },
                {
                  id: "63e38b9ccc4c02b8a0c94b6f",
                  CategoryName: "Lifestyle",
                },
                {
                  id: "63e38bb9cc4c02b8a0c94b7b",
                  CategoryName: "FMCG",
                },
                {
                  id: "63e38b91cc4c02b8a0c94b69",
                  CategoryName: "Media",
                },
                {
                  id: "63e38ba3cc4c02b8a0c94b72",
                  CategoryName: "Hotel",
                },
                {
                  id: "63e38bbfcc4c02b8a0c94b7e",
                  CategoryName: "Airlines Tickets",
                },
                {
                  id: "63e38bc6cc4c02b8a0c94b81",
                  CategoryName: "Office Supply",
                },
                {
                  id: "63e38bb3cc4c02b8a0c94b78",
                  CategoryName: "Mobility",
                },
                {
                  id: "63e38bcecc4c02b8a0c94b84",
                  CategoryName: "Electronics",
                },
                {
                  id: "63e38b96cc4c02b8a0c94b6c",
                  CategoryName: "Textile",
                },
                {
                  id: "64218b189fe1b6ae750c11bd",
                  CategoryName: "Entertainment & Events",
                },
                {
                  id: "63e38bd5cc4c02b8a0c94b87",
                  CategoryName: "QSR",
                },
              ];
              return (
                <Grid
                  container
                  sx={{
                    borderBottom: "1px solid rgba(245, 245, 245, 1)",
                    mt: 1,
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Grid
                    item
                    xl={0.6}
                    lg={0.6}
                    md={0.6}
                    sm={0.6}
                    xs={0.6}
                    align="left"
                    className="product-title-colum"
                  >
                    {i + 1}
                  </Grid>
                  <Grid
                    item
                    xl={2.4}
                    lg={2.4}
                    md={2.4}
                    sm={2.4}
                    xs={2.4}
                    align="left"
                    className="product-title-colum"
                  >
                    <img
                      src={x?.CompanyAvatar?.url}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    />
                    <Box sx={Styles.headProductText}>{x?.companyName}</Box>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {CategoryName?.filter(
                      (product) => product?.id === x?.companyType
                    ).reduce((idx, item) => {
                      return <span>{item?.CategoryName}</span>;
                    }, "--")}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.CompanyAddress?.State}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.cin}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.subTextData}
                  >
                    {x?.website || "--"}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {x?.memberships?.at(x?.memberships?.length - 1)
                      ?.MembershipType === "basic"
                      ? "Standard"
                      : x?.memberships?.at(x?.memberships?.length - 1)
                          ?.MembershipAmount === "advance"
                      ? "Advance"
                      : "--"}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
      </Box>
    );
  };
  return (
    <Paper
      elevation={0}
      sx={{
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/genie");
            }}
          >
            <KeyboardBackspaceIcon />
          </Box>

          <Box className="page-heading">
            <h5>Company</h5>
            <Typography>View all company details.</Typography>
          </Box>
        </Box>
        <Searchbar width={"45%"} onSendData={handleChildData} />

        <Tabs
          className="page-filters-tabs"
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab
            key="Active"
            onClick={() => {
              setDataTypes("Active");
              setCurrentPage(1);
            }}
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Active"
          />
          <Tab
            key="Inactive"
            onClick={() => {
              setDataTypes("Inactive");
              setCurrentPage(1);
            }}
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Inactive"
          />
        </Tabs>
      </Box>
      <Divider />

      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              textAlign: "center",
              p: 1.5,
              borderRadius: "20px",
            }}
          >
            <Typography sx={textTotal}>
              Total Query : {active?.totalItems}
            </Typography>
          </Box>
          {/* <Box
            sx={Styles.filtersDesign}
            onClick={() => {
              handleEditButtonClick();
            }}
          >
            <img
              src={filter}
              alt=""
              style={{ height: "auto", width: "15px" }}
            />
            Filters
          </Box>{" "} 
          <Popup
            openPopup={isOpen}
            popupTitle="Filters"
            popupContent={content}
            handleClose={() => {
              setIsOpen(false);
            }}
          /> */}
        </Box>
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
              width: "100%",
              // mr: '150%',
              ml: "500px",
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <Box mt={3}>
            {activeTab === 0 && <div>{AcitiveDataRender()}</div>}
            {activeTab === 1 && <div>{InAcitiveDataRender()}</div>}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        mt={0.8}
      >
        <Stack spacing={2}>
          <StyledPagination
            size="small"
            count={initialPaginationCount}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
      {/* <Box sx={{ background: '#FAFBFC', p: 0.2 }}>
        <Box>
          <Grid
            container
            sx={{
              p: 1,
              textAlign: 'center',
            }}
          >
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={2.4}
              xs={2.4}
              sx={Styles.textHead}
            >
              Company Name
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={2.4}
              xs={2.4}
              sx={Styles.textHead}
            >
              Category
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={2.4}
              xs={2.4}
              sx={Styles.textHead}
            >
              Location
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={2.4}
              xs={2.4}
              sx={Styles.textHead}
            >
              Member Name
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={2.4}
              xs={2.4}
              sx={Styles.textHead}
            >
              Website
            </Grid>
          </Grid>
          {[1, 2, 3, 4, 5, 6, 7].map((x, i) => {
            return (
              <Grid
                container
                sx={{
                  borderBottom: '1px solid rgba(245, 245, 245, 1)',
                  mt: 1,
                  p: 0.5,
                  textAlign: 'center',
                }}
              >
                <Grid
                  item
                  xl={2.4}
                  lg={2.4}
                  md={2.4}
                  sm={2.4}
                  xs={2.4}
                  align="left"
                  className="product-title-colum"
                >
                  <img src={FigmaIcon} />
                  <Box sx={Styles.headProductText}>Figma Subscription</Box>
                </Grid>
                <Grid
                  item
                  xl={2.4}
                  lg={2.4}
                  md={2.4}
                  sm={2.4}
                  xs={2.4}
                  sx={Styles.subTextData}
                >
                  Office Supply
                </Grid>
                <Grid
                  item
                  xl={2.4}
                  lg={2.4}
                  md={2.4}
                  sm={2.4}
                  xs={2.4}
                  sx={Styles.subTextData}
                >
                  Ahmedabad
                </Grid>
                <Grid
                  item
                  xl={2.4}
                  lg={2.4}
                  md={2.4}
                  sm={2.4}
                  xs={2.4}
                  sx={Styles.subTextData}
                >
                  Lorem
                </Grid>
                <Grid
                  item
                  xl={2.4}
                  lg={2.4}
                  md={2.4}
                  sm={2.4}
                  xs={2.4}
                  sx={Styles.subTextData}
                >
                  www.loremipsum.com
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box> */}
    </Paper>
  );
};

export default GenieActiveInavtive;

const btnDesign = {
  display: "flex",
  alignItems: "center",
  gap: 5.636,
  borderRadius: "10px",
  border: "0.705px solid #E6E9EE",
  color: "#B5BCCC",
  fontFamily: "Poppins",
  fontSize: 11.273,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "rgba(181, 188, 204, 1)",
  textTransform: "none",
};
const textTotal = {
  color: "var(--grey-blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};
