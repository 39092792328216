import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Plus from "../../assets1/PlusImg.svg";
import UploadtoCloud from "../../assets1/UploadtoCloud.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import { MdClose } from "react-icons/md";

const UploadBanner = () => {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const inputRef = useRef(null);
  const [files, setFiles] = useState();
  const [BannerImageArray, SetBannerImageArray] = useState([]);
  const [CheckbannerStatus, setCheckbannerStatus] = useState();
  const [FileNameErrorfordimanesion, setFileNameErrorForDimension] = useState();
  const [FileNameErrorForSize, setFileNameErrorForSize] = useState();
  const [activeButtonUrl, setActiveButtonUrl] = useState("");
  const [Displaying, setDisplaying] = useState(false);
  const [UpdateBtn, setUpdateBtn] = useState(false);
  const [OldBannerData, setOldBannerData] = useState();
  const [isActive, setIsActive] = useState(false);
  const [bannerIsactive, setbannerIsactive] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const GetBanner = async () => {
    try {
      const response = await axios.get("/api/v1/admin/GetBannerImage", {
        headers: {
          withCredentials: true,
          authorization: `bearer ${GetTokenFunc()}`,
        },
      });
      SetBannerImageArray(response?.data?.ImageUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteBanner = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this banner image?"
    );
    if (!isConfirmed) {
      return;
    }
    try {
      const response = await axios.delete(
        `/api/v1/admin/DeleteBannerImage/${id}`,
        {
          headers: {
            withCredentials: true,
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      GetBanner();
      return toast.success("Banner Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateBannerStatus = async (id, bannerIsactive, buynowIsactive) => {
    try {
      const response = await axios.post(
        `/api/v1/admin/updateBannerStatus/${id}`,
        {
          bannerIsactive,
          buynowIsactive,
        },
        {
          headers: {
            withCredentials: true,
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );

      GetBanner();
      return toast.success("Status Updated", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error updating banner status:", error);
    }
  };

  const handleChangeFiles = (event) => {
    setFiles(event.target.files);
  };

  const uploadBanner = async () => {
    if (!files || files.length === 0) {
      return toast.error("Please Add File", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const selectedFile = files[0];
    const maxFileSize = 5 * 1024 * 1024;
    const minWidth = 1300;
    const maxWidth = 1340;
    const minHeight = 230;
    const maxHeight = 270;
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
      "image/webp",
    ];

    if (selectedFile.size > maxFileSize) {
      return toast.error("File size exceeds the maximum allowed (5 MB)", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!allowedFormats.includes(selectedFile.type)) {
      return toast.error(
        "Invalid file format. Please upload a PNG, JPG, JPEG, SVG, or WEBP file.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }

    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);

    img.onload = async () => {
      if (
        img.width < minWidth ||
        img.width > maxWidth ||
        img.height < minHeight ||
        img.height > maxHeight
      ) {
        return toast.error(
          "Image dimensions must be between 1300x230 and 1340x270 pixels",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }

      try {
        const fileData = new FormData();
        fileData.append("file", selectedFile);

        if (typeof bannerIsactive !== "undefined") {
          fileData.append("bannerIsactive", bannerIsactive);
        }
        if (typeof activeButtonUrl !== "undefined") {
          fileData.append("buynowurl", activeButtonUrl);
        }
        if (typeof isActive !== "undefined") {
          fileData.append("buynowIsactive", isActive);
        }

        const response = await axios.post(
          `/api/v1/admin/bxiuploadbanner`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        );

        toast.success("File Uploaded", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        GetBanner();
        setActiveButtonUrl("");
        setIsActive(false);
        setDisplaying(false);
        setFiles([]);
      } catch (error) {
        toast.error("Error uploading file. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };

    img.onerror = () => {
      toast.error("Error loading image. Please try a different file.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };
  };

  const updateBanner = async () => {
    const fileData = new FormData();

    if (files && files.length > 0) {
      const selectedFile = files[0];
      const maxFileSize = 5 * 1024 * 1024;
      const minWidth = 1300;
      const maxWidth = 1340;
      const minHeight = 230;
      const maxHeight = 270;
      const allowedFormats = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg+xml",
        "image/webp",
      ];

      if (selectedFile.size > maxFileSize) {
        return toast.error("File size exceeds the maximum allowed (5 MB)", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (!allowedFormats.includes(selectedFile.type)) {
        return toast.error(
          "Invalid file format. Please upload a PNG, JPG, JPEG, SVG, or WEBP file.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }

      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);

      img.onload = async () => {
        if (
          img.width < minWidth ||
          img.width > maxWidth ||
          img.height < minHeight ||
          img.height > maxHeight
        ) {
          return toast.error(
            "Image dimensions must be between 1300x230 and 1340x270 pixels",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        fileData.append("file", selectedFile);
        await submitData(fileData);
      };

      img.onerror = () => {
        toast.error("Error loading image. Please try a different file.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      };

      img.src = URL.createObjectURL(selectedFile);
    } else {
      await submitData(fileData);
    }
  };

  const submitData = async (fileData) => {
    if (typeof bannerIsactive !== "undefined") {
      fileData.append("bannerIsactive", bannerIsactive);
    }
    if (typeof activeButtonUrl !== "undefined") {
      fileData.append("buynowurl", activeButtonUrl);
    }
    if (typeof isActive !== "undefined") {
      fileData.append("buynowIsactive", isActive);
    }

    try {
      const response = await axios.post(
        `/api/v1/admin/UpdateBanner/${OldBannerData?._id}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );

      toast.success("File Uploaded", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      GetBanner();
      setActiveButtonUrl("");
      setIsActive(false);
      setDisplaying(false);
      setUpdateBtn(false);
      setFiles([]);
      handleClose();
    } catch (error) {
      toast.error("Error uploading file. Please try again.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    GetBanner();
  }, []);

  useEffect(() => {
    const activeCount = BannerImageArray?.filter(
      (item) => item?.bannerIsactive
    ).length;

    const isOnlyOneActive = activeCount === 1; // true if exactly one is active
    const moreThanOneActive = activeCount > 1; // true if more than one is active

    let NewVar = isOnlyOneActive ? true : moreThanOneActive ? false : true;
    setCheckbannerStatus(NewVar);
  }, [BannerImageArray]);

  return (
    <Box sx={RecenteUsersBox}>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          mt: 2,
          flexWrap: "wrap",
          width: "95%",
          mx: "auto",
        }}
      >
        {" "}
        <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
          <Card
            variant="outlined"
            sx={{
              background: "#FFFFFF",
              borderRadius: "10px",
              width: "300px",
              height: "280px",
              marginLeft: "auto",
              marginRight: "auto",
              border: "0.5px solid #cccccc",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setDisplaying(true);
              setUpdateBtn(false);
              setOldBannerData();
              setActiveButtonUrl("");
              setIsActive(false);
              handleOpen();
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  border: "2px dashed #C4C4C4",
                  borderRadius: "40px",
                }}
                src={Plus}
              />
              <Typography mt={2} sx={{ ...substyle }}>
                Add New Banner
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {BannerImageArray?.map((item, idx) => {
          return (
            <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12} sx={{}}>
              <Card
                variant="outlined"
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "10px",
                  width: "300px",
                  height: "280px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "0.5px solid #cccccc",
                }}
                key={item?._id}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    {BannerImageArray.length > 1 && (
                      <MdClose
                        onClick={() => {
                          DeleteBanner(item?._id);
                        }}
                        style={{
                          color: "#929EAE",
                          fontSize: "17px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item?.url}
                      alt="img"
                      style={{
                        width: "90%",
                        height: "auto",
                        borderRadius: "5px",
                        border: "1px solid gray",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #F5F5F5",
                      width: "90%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "flex-start",
                      marginTop: "10px",
                      mb: "10px",
                      width: "90%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          ...substyle,
                        }}
                      >
                        Banner IsActive :
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              name="BannerIsactive"
                              checked={item?.bannerIsactive}
                              onChange={() => {
                                // if (
                                //   CheckbannerStatus &&
                                //   !item?.bannerIsactive
                                // ) {
                                UpdateBannerStatus(
                                  item?._id,
                                  !item?.bannerIsactive,
                                  item?.buynowIsactive
                                );
                                // }
                              }}
                            />
                          }
                        />
                      </Typography>
                      <Typography
                        sx={{
                          ...substyle,
                        }}
                      >
                        Buy Now IsActive :{" "}
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              name="BannerIsactive"
                              checked={item?.buynowIsactive}
                              onChange={() =>
                                UpdateBannerStatus(
                                  item?._id,
                                  item?.bannerIsactive,
                                  !item?.buynowIsactive
                                )
                              }
                            />
                          }
                        />
                      </Typography>
                      <Typography
                        sx={{
                          ...substyle,
                          my: 0,
                        }}
                      >
                        BuyNow URl :
                      </Typography>
                      <Typography
                        sx={{
                          ...substyle,
                          width: "100%",
                          overflow: "scroll",
                          my: 0,
                        }}
                      >
                        {item?.buynowurl}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    sx={{
                      background: "#445FD2",
                      borderRadius: "5px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 10,
                      color: "white",
                      width: "100%",
                      height: "50%",
                      marginTop: "1rem",
                      border: "1px solid transparent",
                      textTransform: "none",

                      "&:hover": {
                        color: "#445FD2",
                        border: "1px solid #445FD2",
                      },
                    }}
                    onClick={() => {
                      setDisplaying(true);
                      setUpdateBtn(true);
                      setOldBannerData(item);
                      setActiveButtonUrl(item?.buynowurl);
                      setIsActive(item?.buynowIsactive);
                      handleOpen();
                    }}
                  >
                    Update Banner
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Box>

      {Displaying ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                ...RecenteUsersBox,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "98%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                >
                  <MdClose
                    onClick={() => {
                      setDisplaying(false);
                      handleClose();
                    }}
                    fontSize="large"
                    size={"24px"}
                    style={{
                      cursor: "pointer",
                      color: "#929EAE",
                      fontSize: "17px",
                      cursor: "pointer",
                    }}
                  />
                </Typography>
              </Box>
              <Box sx={HeadingStyle}>
                <Typography sx={TokenText}>
                  {UpdateBtn ? "Update" : "Add New"} Marketplace Banner
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "scroll",
                }}
              >
                <Box
                  onClick={() => inputRef.current.click()}
                  sx={{ ...ImageSelectBoxStyle, cursor: "pointer" }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <Box
                    sx={{
                      display: "grid",
                      width: "60%",
                      mx: "auto",
                    }}
                  >
                    <Box sx={DisplayGridStyle}>
                      <Box
                        component="img"
                        src={UploadtoCloud}
                        alt="cloud"
                        sx={ImageBox}
                      />
                      <Typography
                        sx={{
                          ...TextStyle,
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#445FD2",
                          },
                          textalign: "center",
                        }}
                      >
                        Drag & Drop upload or{" "}
                        <span
                          style={{
                            color: "#445FD2",
                            fontWeight: 500,
                            marginLeft: "3px",
                            marginRight: "3px ",
                          }}
                        >
                          {"  "}
                          browse{"  "}
                        </span>{" "}
                        to choose a file
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        ...TextStyle,
                        fontSize: "10px",
                        color: "#676767",
                      }}
                    >
                      Supported format : PNG, JPG, SVG, WEBP ( 1300 x 230 To
                      1340 x 270 )
                      <br />
                      <span>Size Limit : 5 MB</span>
                    </Typography>
                  </Box>
                  <input
                    type="file"
                    onChange={handleChangeFiles}
                    hidden
                    accept=".png,.jpg,.jpeg,.gif,.svg,.webp"
                    ref={inputRef}
                  />
                </Box>
                {files ? (
                  <>
                    <Box
                      sx={{
                        width: "40%",
                        my: "1%",
                      }}
                    >
                      {Array?.from(files)?.map((file, idx) => {
                        const hasDimensionError =
                          FileNameErrorfordimanesion?.includes(file.name);

                        const hasSizeError = FileNameErrorForSize?.includes(
                          file.name
                        );

                        return (
                          <Box
                            key={idx}
                            sx={{
                              bgcolor: "white",
                            }}
                          >
                            <Box sx={ImageMapBox}>
                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                style={{
                                  width: "auto",
                                  height: "35px",
                                  border: "0.5px solid green",
                                  borderRadius: "2px",
                                }}
                              />
                              <Typography key={idx} sx={TextStyle}>
                                {file?.name}
                              </Typography>
                              <Box
                                component="img"
                                src={DeleteIcon}
                                alt="dlt"
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setFiles();
                                }}
                              />
                            </Box>
                            {
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "7px",
                                }}
                              >
                                {FileNameErrorfordimanesion?.includes(
                                  file.name
                                ) && (
                                  <Typography sx={{ color: "black" }}>
                                    Dimension Issue:{" "}
                                    <span style={{ color: "red" }}>
                                      {file.name}
                                    </span>
                                  </Typography>
                                )}

                                {FileNameErrorForSize?.includes(file.name) && (
                                  <Typography sx={{ color: "black" }}>
                                    Size Issue:{" "}
                                    <span style={{ color: "red" }}>
                                      {file.name}
                                    </span>
                                  </Typography>
                                )}
                              </Box>
                            }
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                ) : (
                  " "
                )}

                <Box sx={HeadingStyle}>
                  <Typography sx={TokenText}>
                    Buy Now Button in Banner
                  </Typography>
                </Box>

                <Box
                  className="single-row"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80%",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Typography sx={{ color: "#6B7A99" }}>
                      Buy Now Url :
                    </Typography>

                    <input
                      type="text"
                      variant="outlined"
                      name="ActiveButtonUrl"
                      style={inputstyle}
                      value={activeButtonUrl}
                      onChange={(e) => setActiveButtonUrl(e.target.value)}
                    />

                    <Typography sx={{ color: "#6B7A99" }}>
                      Is Active :
                    </Typography>

                    <FormControlLabel
                      control={
                        <IOSSwitch
                          name="ActiveButton"
                          checked={isActive}
                          onChange={() => setIsActive(!isActive)}
                        />
                      }
                    />
                  </Box>
                  <Button
                    sx={{ ...ButtonStyleForUpload, maxWidth: "150px" }}
                    onClick={() => {
                      if (UpdateBtn) {
                        updateBanner();
                      } else {
                        uploadBanner();
                      }
                    }}
                  >
                    {UpdateBtn ? "Update Banner" : "Add New Banner"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};

export default UploadBanner;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 1,
};

const RecenteUsersBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  background: "#ffffff",
  height: "auto",
  width: "96%",
  mx: "auto",
  mt: 1,
};

const HeadingStyle = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  p: 1,
};

const TokenText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "15px", lg: "15px", md: "15px", sm: "12px", xs: "12px" },
  color: "#393D5E",
};

const ImageSelectBoxStyle = {
  border: "1px dashed #445FD2",
  borderRadius: "5px",
  background: "#fff",
  width: "95%",
  p: "1%",
  mx: "auto",
  position: "relative",
  mt: "1%",
};

const DisplayGridStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  tetxAlign: "center",
};

const ImageBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "25%",
  top: "25%",
  textalign: "center",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const ImageMapBox = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "9px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};

const ButtonStyleForUpload = {
  width: "100%",
  height: "30px",
  maxWidth: "100px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  curso0r: "pointer",
  my: 1,
  border: "1px solid #fff",
  "&:hover": {
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};

const inputstyle = {
  border: "0.7px solid #7D8FB3B2",
};

const substyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textAlign: "center",
  color: "#929EAE",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  my: 0.5,
};
