import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Modal, Paper, Box, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import EscrowReleaseHtml from "./EscrowReleaseHtml";
import axios from "axios";
import HtmlToPdf from "./HtmlToPdf";
import "./ReleaseLetterStyle.css";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import Signviral from "../../assets/SignViral.png";
import VectorArrow from "../../assets/VectorArrow.jpg";
import BXILogo from "../../assets/BXILogo.jpg";
import { useParams } from "react-router-dom";

import Success from "../../assets/success.png";
import { toast } from "react-toastify";
import { Tab } from "@material-ui/core";
// import { useGetCompanyById } from "./hooks";
import ProductOrderSummary from "./OrderSummaryPages/ProductOrderSummary";
import VoucherOrderSummary from "./OrderSummaryPages/VoucherOrderSummary";
import MediaOrderSummary from "./OrderSummaryPages/MediaOrderSummary";
const ReleaseView = (props) => {
  let paramsdata = useParams();

  const [LoaderForEscrowRelease, setLoaderForEscrowRelease] = useState(false);
  const [BxiEscrowReleaseLoader, setBxiEscrowReleaseLoader] = useState(false);
  const [releaseFundData, setreleaseFundData] = useState(null);
  const [releaseModalOpen, setReleaseModalOpen] = useState(false);
  const [releaseBXIModalOpen, setReleaseBXIModalOpen] = useState(false);
  const [StoreOrderData, setStoreOrderData] = useState(null);

  const [ReleaseUploadLoading, setReleaseUploadLoading] = useState(false);
  const [RefundUploadLoading, setRefundUploadLoading] = useState(false);
  const [IsInrRefund, setIsInrRefund] = useState(false);

  const [open, setOpen] = React.useState(false);
  const componentRef = useRef(null);
  const OrderSummaryRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const HandleReleaseModal = (data) => {
    setreleaseFundData(data);
    handleOpen();
  };
  // const {
  //   data: CompanyData,
  //   isLoading: CompanyLoading,
  //   refetch: CompanyRefetch,
  // } = useGetCompanyById(StoreOrderData?.BuyerCompanyId);
  const GetOrderData = async (Id) => {
    await axios
      .post(`admin_orders/get_order_by_id`, {
        OrderId: Id,
      })
      .then((res) => {
        setStoreOrderData(res?.data);
      })
      .catch((err) => {});
  };

  let Seller_amount = 0;
  let BXI_amount = 0;
  let Coins = 0;

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let TotalPriceWithoutGST = 0;

  StoreOrderData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    if (StoreOrderData?.IsVoucher) {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
    } else {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
    }
    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
  });

  if (StoreOrderData?.IsMedia) {
    Seller_amount =
      StoreOrderData?.INRDetails?.TotalGstAmount +
      StoreOrderData?.INRDetails?.TotalGstInrOfAdCost +
      StoreOrderData?.INRDetails?.TotalValueInrOfAdCost;

    BXI_amount =
      StoreOrderData?.INRDetails?.TotalBxiCommission +
      StoreOrderData?.INRDetails?.BxiCommisionGST -
      StoreOrderData?.INRDetails?.TDS;

    Coins = StoreOrderData?.WalletData?._balance;
  } else if (StoreOrderData?.IsVoucher) {
    Seller_amount =
      StoreOrderData?.INRDetails?.totalGstAmount +
      StoreOrderData?.INRDetails?.totalGstInrOfAdCost +
      StoreOrderData?.INRDetails?.totalValueInrOfAdCost;

    BXI_amount =
      StoreOrderData?.INRDetails?.totalBxiCommission +
      StoreOrderData?.INRDetails?.bxiCommisionGST -
      StoreOrderData?.INRDetails?.TDS;

    Coins = StoreOrderData?.WalletData?._balance;
  } else {
    Seller_amount =
      StoreOrderData?.INRDetails?.totalGstAmount +
      StoreOrderData?.INRDetails?.totalGstInrOfAdCost +
      StoreOrderData?.INRDetails?.totalValueInrOfAdCost +
      StoreOrderData?.INRDetails?.packagingCost +
      StoreOrderData?.INRDetails?.InsuranceCost +
      (StoreOrderData?.BuyerChoosedTransportation?.TransportationType ===
      "Seller"
        ? StoreOrderData?.INRDetails?.totalTransportationCost +
          StoreOrderData?.INRDetails?.totalTransportationCostGST
        : 0);

    BXI_amount =
      StoreOrderData?.INRDetails?.bxiCommisionOnTransportation +
      StoreOrderData?.INRDetails?.bxiCommisionOnTransportationGST +
      StoreOrderData?.INRDetails?.totalBxiCommission +
      StoreOrderData?.INRDetails?.bxiCommisionGST -
      StoreOrderData?.INRDetails?.TDS +
      (StoreOrderData?.BuyerChoosedTransportation?.TransportationType !==
      "Seller"
        ? StoreOrderData?.INRDetails?.totalTransportationCost +
          StoreOrderData?.INRDetails?.totalTransportationCostGST +
          StoreOrderData?.INRDetails?.bxiCommisionOnTransportation +
          StoreOrderData?.INRDetails?.bxiCommisionOnTransportationGST -
          StoreOrderData?.INRDetails?.ShippingTDS
        : 0);

    Coins = StoreOrderData?.WalletData?._balance;
  }

  const MakeEscrowPayment = async (PayData) => {
    await axios
      .post("/escrow/release-funds", {
        OrderId: PayData?._id,
      })
      .then((res) => {
        setReleaseUploadLoading(false);
        setReleaseModalOpen(false);
        GetOrderData(paramsdata.id);
        if (res?.ReleaseData?.success === true) {
          toast.success("Funds Released Successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => {});
  };

  const MakeEscrow_BXI_Release = async (PayData) => {
    // setBxiEscrowReleaseLoader(true);
    if (BXI_amount < 1) {
      toast.error("You can not release funds less than 1 INR", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      setBxiEscrowReleaseLoader(false);
    } else {
      const userConfirmed = window.confirm(
        "Are you sure you want to Release BXI Escrow Funds?"
      );
      if (userConfirmed) {
        setBxiEscrowReleaseLoader(true);

        await axios
          .post("/escrow/release-funds-bxi", {
            OrderId: PayData?._id,
          })
          .then((res) => {
            GetOrderData(paramsdata.id);
            setBxiEscrowReleaseLoader(false);
            if (res?.ReleaseData?.success === true) {
              toast.success("Funds Released Successfully", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          })
          .catch((err) => {});
      } else {
      }
    }
  };

  async function BXICommision(OrderId) {
    const userConfirmed = window.confirm(
      "Are you sure you want to Send Commission?"
    );
    if (userConfirmed) {
      let WalletRealease = await axios
        .post(`commission/create_bxi_commision_invoice`, {
          OrderId: OrderId,
          IsVoucher: false,
        })
        .then((res) => {
          GetOrderData(paramsdata.id);
        })
        .catch((err) => {});
    } else {
    }
  }

  const upload_doc_refund_escrow = async (OrderId) => {
    // setLoaderForEscrowRelease(true);

    setRefundUploadLoading(true);

    const contentDiv = componentRef.current;
    const OrderSummaryDiv = OrderSummaryRef.current;

    const canvas = await html2canvas(contentDiv);

    const pdf = new jsPDF();
    pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 290);
    pdf.addPage();
    const canvas2 = await html2canvas(OrderSummaryDiv);
    pdf.addImage(canvas2.toDataURL("image/png"), "PNG", 0, 0, 200, 280);

    const pdfBlob = pdf.output("blob");
    const formData = new FormData();
    formData.append("file", pdfBlob, "document.pdf");

    formData.append("Key", "pdf/");
    formData.append("ContentType", "application/pdf");

    await axios
      .post("upload/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        axios
          .post("escrow/upload-refund-doc", {
            OrderId: paramsdata.id,
            FileUrl: res?.data.file,
          })
          .then((docres) => {
            Make_refund_escrow(docres?.data?.OrderData);
            GetOrderData(paramsdata.id);
          })
          .catch((err) => {});
      });
  };

  const Make_refund_escrow = async (OrderId) => {
    try {
      await axios
        .post("/escrow/refund-funds", { OrderId: OrderId })
        .then((res) => {
          setRefundUploadLoading(false);
          setReleaseModalOpen(false);
          GetOrderData(paramsdata.id);
          if (res?.data?.data?.success === true) {
            toast.success("Inr Refunded Successfully", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        });
    } catch (e) {}
  };

  const MakeEscrow_ReleaseFunds = async (OrderId) => {
    try {
      if (Seller_amount < 1) {
        toast.error("You can not release funds less than 1 INR", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        setLoaderForEscrowRelease(true);

        setReleaseUploadLoading(true);

        const contentDiv = componentRef.current;
        const OrderSummaryDiv = OrderSummaryRef.current;

        const canvas = await html2canvas(contentDiv);

        const pdf = new jsPDF();
        pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 290);
        pdf.addPage();
        const canvas2 = await html2canvas(OrderSummaryDiv);
        pdf.addImage(canvas2.toDataURL("image/png"), "PNG", 0, 0, 200, 280);

        // Save or display the PDF
        // pdf.save("two-page-document.pdf");
        const pdfBlob = pdf.output("blob");
        const formData = new FormData();
        formData.append("file", pdfBlob, "document.pdf");

        formData.append("Key", "pdf/");
        formData.append("ContentType", "application/pdf");

        await axios
          .post("upload/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            axios
              .post("escrow/upload-relase-fund-doc", {
                OrderId: paramsdata.id,
                FileUrl: res?.data.file,
              })
              .then((redsgyufs) => {
                setReleaseModalOpen(false);
                setLoaderForEscrowRelease(false);
                MakeEscrowPayment(redsgyufs?.data?.OrderData);
                GetOrderData(paramsdata.id);
              })
              .catch((err) => {});
          });
      }
    } catch (e) {}
  };

  // const MakeEscrow_BXI_ReleaseFunds = async () => {
  //   setBxiEscrowReleaseLoader(true);
  //   await axios
  //     .post("escrow/upload-relase-fund-doc_bxi", {
  //       OrderId: paramsdata.id,
  //     })
  //     .then((ressss) => {
  //       MakeEscrow_BXI_Release(ressss?.data?.OrderData);
  //       setReleaseBXIModalOpen(false);
  //       setBxiEscrowReleaseLoader(false);
  //     });
  // };

  const MakeWalletTransaction = async (Order) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to Release Wallet Fund?"
    );
    if (userConfirmed) {
      await axios
        .post(`wallet/release_onhold_transaction`, {
          transaction_id: Order?.WalletData?._transaction_id,
          OrderId: Order?._id,
        })
        .then((res) => {
          GetOrderData(paramsdata.id);
        })
        .catch((err) => {});
    } else {
    }
  };

  const MakeWalletRefundTransaction = async (Order) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to refund this order?"
    );
    if (userConfirmed) {
      await axios
        .post(`wallet/refund_onhold_to_buyer_transaction`, {
          transaction_id: Order?.WalletData?._transaction_id,
          OrderId: Order?._id,
        })
        .then((res) => {
          // GetOrderData(paramsdata.id);
          alert(res?.data?.body === "success" ? "Refunded Successfully" : "");
        })
        .catch((err) => {});
    } else {
    }
  };

  const fetch_bank_details = async () => {
    try {
      await axios.post(
        `escrow/fetch_user_bank_accounts`,
        {
          OrderId: paramsdata.id,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {}
  };

  const withdraw_to_bank = async (data) => {
    try {
      await axios
        .post(`escrow/escrow_withdraw_toBank`, {
          OrderId: data._id,
        })
        .then((res) => {
          if (res?.data?.data?.success === true) {
            toast.success("Withdraw to Bank Successfull", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Withdraw to Bank Failed", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    GetOrderData(paramsdata.id);
    fetch_bank_details();
  }, []);

  const HandleReleaseModalOpen = async () => {
    setReleaseModalOpen(true);
  };
  const HandleReleaseBXIModalOpen = async () => {
    setReleaseBXIModalOpen(true);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <Box
        sx={{
          minWidth: "700px",
          maxWidth: "700px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "normal",
            textAlign: "center",
            width: "100%",
            fontWeight: 600,
            mb: 1,
          }}
        >
          Inr And Token Funds
        </Typography>

        <Box mb={1}>
          <Typography
            sx={{
              fontFamily: "Popins, sans-serif",
              fontSize: "14px",
              textAlign: "left",
              // mb: "10px",
            }}
          >
            Deal No :{" "}
            <span style={{ color: "#415BCA", fontSize: "16px" }}>
              {StoreOrderData?.escrowData?.dealId}
            </span>
          </Typography>

          <Typography
            sx={{
              fontFamily: "Popins, sans-serif",
              fontSize: "14px",
              textAlign: "left",
              // mb: "10px",
            }}
          >
            Buyer Escrow Account No :{" "}
            <span style={{ color: "#415BCA", fontSize: "16px" }}>
              {StoreOrderData?.escrowData?.buyerEaaaId}
            </span>
          </Typography>

          <Typography
            sx={{
              fontFamily: "Popins, sans-serif",
              fontSize: "14px",
              textAlign: "left",
              // mb: "10px",
            }}
          >
            Seller Escrow Account No :{" "}
            <span style={{ color: "#415BCA", fontSize: "16px" }}>
              {StoreOrderData?.escrowData?.sellerEaaaId}
            </span>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minWidth: "700px",
          maxWidth: "700px",
          height: "auto",
          bgcolor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "10px",
          mx: "auto",
          alignSelf: "center",
        }}
      >
        {" "}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "left", fontSize: "16px" }}>
                Steps
              </TableCell>
              <TableCell sx={{ textAlign: "left", fontSize: "16px" }}>
                Actions
              </TableCell>
              {/* <TableCell sx={{ textAlign: "center", fontSize: "16px" }}>
                Amount
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "16px" }}>
                Status
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* <TableRow>
              <TableCell>
                <Typography variant="body1" align="center">
                  Step 1
                </Typography>
              </TableCell>
              <TableCell sx={{ display: "grid", gap: "20px" }}>
                {StoreOrderData?.escrowSellerReleaseResponse?.success ? (
                  <>
                    <Typography>Escrow Funds Already Released</Typography>
                  </>
                ) : LoaderForEscrowRelease ? (
                  <Typography>Releasing Funds</Typography>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      background: "#27AE60",
                      color: "#fff",
                      textTransform: "none",
                      width: "100%",
                      maxWidth: "200px",
                      height: "100%",
                      "&:hover": {
                        background: "#27AE60",
                      },
                    }}
                    onClick={() => {
                      HandleReleaseModalOpen(StoreOrderData?._id);
                      setIsInrRefund(false);
                      // MakeEscrow_ReleaseFunds(StoreOrderData?._id);
                    }}
                  >
                    Release Seller Escrow Funds
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <Typography variant="body1" align="center">
                  {StoreOrderData?.INRDetails?.TotalInrToPay?.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.escrowSellerReleaseResponse?.success ? (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      color: "green",
                    }}
                  >
                    Released
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ color: "#ffbe0b" }}
                  >
                    Pending
                  </Typography>
                )}
              </TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>
                <Typography variant="body1" align="center">
                  Step 1
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.escrow_withDraw_toBank &&
                StoreOrderData?.escrow_withDraw_toBank?.txnid ? (
                  <Typography> Withdraw to Bank Already Done</Typography>
                ) : BxiEscrowReleaseLoader ? (
                  <Typography>Withdrawing Funds</Typography>
                ) : StoreOrderData?.escrowSellerReleaseResponse?.success ? (
                  <Button
                    variant="contained"
                    sx={{
                      background: "#27AE60",
                      color: "#fff",
                      textTransform: "none",
                      width: "100%",
                      maxWidth: "200px",
                      height: "100%",
                      "&:hover": {
                        background: "#27AE60",
                      },
                    }}
                    onClick={() => {
                      withdraw_to_bank(StoreOrderData);
                    }}
                  >
                    Withdraw Escrow Fund to Bank
                  </Button>
                ) : (
                  <Typography>First Release the fund</Typography>
                )}
              </TableCell>

              <TableCell>
                <Typography variant="body1" align="center">
                  {BXI_amount ? BXI_amount?.toFixed(2) : ""}
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.escrowBXIReleaseResponse &&
                StoreOrderData?.escrowBXIReleaseResponse?.status !== false ? (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: "green" }}
                  >
                    Released
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ color: "#ffbe0b" }}
                  >
                    Pending
                  </Typography>
                )}
              </TableCell>
            </TableRow> 
           <TableRow>
              <TableCell>
                <Typography variant="body1" align="center">
                  Step 2
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.escrowBXIReleaseResponse &&
                StoreOrderData?.escrowBXIReleaseResponse?.status !== false ? (
                  <Typography> BXI Fee Already Released</Typography>
                ) : BxiEscrowReleaseLoader ? (
                  <Typography>Releasing Funds</Typography>
                ) : StoreOrderData?.escrowRelease?.ReleaseFundDocId ? (
                  <Button
                    variant="contained"
                    sx={{
                      background: "#27AE60",
                      color: "#fff",
                      textTransform: "none",
                      width: "100%",
                      maxWidth: "200px",
                      height: "100%",
                      "&:hover": {
                        background: "#27AE60",
                      },
                    }}
                    onClick={() => {
                      MakeEscrow_BXI_Release(StoreOrderData);
                    }}
                  >
                    Release BXI Escrow Funds
                  </Button>
                ) : (
                  <Typography>Upload Release Letter First</Typography>
                )}
              </TableCell>

              <TableCell>
                <Typography variant="body1" align="center">
                  {BXI_amount ? BXI_amount?.toFixed(2) : ""}
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.escrowBXIReleaseResponse &&
                StoreOrderData?.escrowBXIReleaseResponse?.status !== false ? (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: "green" }}
                  >
                    Released
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ color: "#ffbe0b" }}
                  >
                    Pending
                  </Typography>
                )}
              </TableCell>
            </TableRow> 
           <TableRow>
              <TableCell>
                <Typography variant="body1" align="center">
                  Step 3
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.walletRefund === "success" ? (
                  <Typography variant="body1" align="center">
                    Wallet Balance Already Refunded to Buyer
                  </Typography>
                ) : (
                  <Box>
                    {StoreOrderData?.Wallet_transaction_status !==
                    "released" ? (
                      <Button
                        variant="contained"
                        sx={{
                          background: "#27AE60",
                          color: "#fff",
                          textTransform: "none",
                          width: "100%",
                          maxWidth: "200px",
                          height: "100%",
                          "&:hover": {
                            background: "#27AE60",
                          },
                        }}
                        onClick={() => {
                          MakeWalletTransaction(StoreOrderData);
                        }}
                      >
                        Release Wallet Funds
                      </Button>
                    ) : (
                      <Typography>Wallet Funds Already Released</Typography>
                    )}
                  </Box>
                )}
              </TableCell>
              <TableCell>
                <Typography variant="body1" align="center">
                  {(TotalAdditionalCostWtihoutGSTInBXI || 0) +
                  (TotalPriceWithoutGST || 0) -
                  StoreOrderData?.INRDetails?.TDS
                    ? (
                        (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                        (TotalPriceWithoutGST || 0) -
                        StoreOrderData?.INRDetails?.TDS
                      ).toFixed(2)
                    : ""}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" align="center">
                  {StoreOrderData?.Wallet_transaction_status === "released" ? (
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ color: "green" }}
                    >
                      Released
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ color: "#ffbe0b" }}
                    >
                      Pending
                    </Typography>
                  )}
                </Typography>
              </TableCell>
            </TableRow> 
           <TableRow>
              <TableCell>
                <Typography variant="body1" align="center">
                  Step 4
                </Typography>
              </TableCell>
              <TableCell>
                {!StoreOrderData?.IsCommisionInvoiceCreated ? (
                  <Button
                    onClick={() => BXICommision(StoreOrderData?._id)}
                    sx={{
                      bgcolor: "green",
                      border: "none",
                      color: "white",
                      textDecoration: "none",
                      textTransform: "none",
                      width: "100%",
                      maxWidth: "200px",
                      ":hover": {
                        bgcolor: "lightgreen",
                      },
                    }}
                  >
                    Send Commision
                  </Button>
                ) : (
                  <Typography>Commision Already Sent</Typography>
                )}
              </TableCell>
              <TableCell>
                <Typography variant="body1" align="center"></Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" align="center"></Typography>
              </TableCell>
            </TableRow> */}
            {/* {StoreOrderData?.escrowRefund &&
                StoreOrderData?.escrowRefund?.success === true ? (
                  <Button
                    variant="outlined"
                    sx={{
                      width: "auto",
                      p: "10px",
                      height: "40px",
                      textTransform: "none",
                    }}
                  >
                    Refund Already Initiated
                  </Button>
                ) : ( */}
            <TableRow>
              <TableCell>
                <Typography variant="body1" align="left">
                  Inr Refund
                </Typography>
              </TableCell>
              <TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    p: 0,
                  }}
                >
                  {StoreOrderData?.escrowSellerReleaseResponse?.success ? (
                    <>
                      <Typography>Escrow Funds Already Released</Typography>
                    </>
                  ) : (
                    <>
                      {StoreOrderData?.escrowRefundResponse?.success ? (
                        <Button
                          variant="outlined"
                          sx={{
                            bgcolor: "green",
                            border: "none",
                            color: "white",
                            textDecoration: "none",
                            textTransform: "none",
                            width: "100%",
                            maxWidth: "200px",
                            ":hover": {
                              bgcolor: "lightgreen",
                              border: "none",
                            },
                          }}
                        >
                          Inr Refunded Already
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setReleaseModalOpen(true);
                            setIsInrRefund(true);
                          }}
                          // onClick={() => Make_refund_escrow(StoreOrderData?._id)}
                          variant="outlined"
                          sx={{
                            bgcolor: "red",
                            border: "none",
                            color: "white",
                            textDecoration: "none",
                            textTransform: "none",
                            width: "100%",
                            maxWidth: "200px",
                            ":hover": {
                              bgcolor: "red",
                              border: "none",
                            },
                          }}
                        >
                          Make Inr Refund
                        </Button>
                      )}
                    </>
                  )}
                </TableCell>
                {/* )} */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1" align="left">
                  Refund Token To Buyer
                </Typography>
              </TableCell>
              <TableCell>
                {StoreOrderData?.walletRefund === "success" ? (
                  <Typography variant="body1" align="center">
                    Wallet Balance Refunded to Buyer
                  </Typography>
                ) : StoreOrderData?.Wallet_transaction_status !== "released" ? (
                  <Button
                    onClick={() => MakeWalletRefundTransaction(StoreOrderData)}
                    variant="outlined"
                    sx={{
                      bgcolor: "red",
                      border: "none",
                      color: "white",
                      textDecoration: "none",
                      textTransform: "none",
                      width: "100%",
                      cursor: "pointer",
                      maxWidth: "200px",
                      ":hover": {
                        bgcolor: "red",
                        border: "none",
                      },
                    }}
                  >
                    Make Refund Token
                  </Button>
                ) : (
                  <Typography variant="body1" align="left">
                    Once Wallet fund Released then can't be Refunded
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Modal
          open={releaseModalOpen}
          onClose={() => setReleaseModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{
              height: "100%",
              maxHeight: "90vh",
              width: "100%",
              maxWidth: "50vw",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
              position: "absolute",
              overflowX: "hidden",
              overflowY: "scroll",
              // bgcolor: "red",
            }}
          >
            <Box
              sx={{
                margin: "0 auto",
                height: "88%",
                overflowX: "hidden",
                overflowY: "scroll",
                width: "98%",
                maxWidth: "48vw",
                mt: "10px",
              }}
            >
              <div ref={componentRef} id="content-to-convert">
                <Box style={StyleOne}>
                  <Box style={StyleTwo}>
                    <Box component={"img"} src={BXILogo} style={StyleThree} />
                    <Box
                      component={"img"}
                      src={VectorArrow}
                      style={StyleFour}
                    />
                  </Box>
                  <Box class="paper" style={StyleFive}>
                    <Box class="box" style={StyleSix}>
                      <Box class="box" style={StyleSeven}>
                        <Typography sx={CommonFontStyle}>
                          To <br /> <b>ESCROWPAYINDIA.COM,</b>
                          <br />
                          Through <b>(Through BXI)</b>
                          <br />
                          Trust more Technologies (P) Limited
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          Tower-B, 16th Floor, Emaar Digital Greens, Unit No.
                          017-018, <br /> Golf Course Extension Road, Sector-61,
                          <br /> Gurugram, Haryana-122102
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          <b>
                            {" "}
                            Sub: Release Letter/Instructions to release “Escrow
                            Funds” from the Escrow Account Ref : Escrow Account
                            No.{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {StoreOrderData?.escrowData?.dealId}{" "}
                            </span>{" "}
                            and
                          </b>
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleEight}>
                        <Typography sx={CommonFontStyle}>
                          Relationship as per Agreement
                        </Typography>
                        <Typography
                          style={{ ...CommonFontStyle, whiteSpace: "nowrap" }}
                        >
                          Name of the Party <br />{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </span>
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          Relationship as per Agreement
                        </Typography>
                        <Typography
                          style={{ ...CommonFontStyle, whiteSpace: "nowrap" }}
                        >
                          Name of the Party <br />{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }
                          </span>
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleNine}>
                        <Typography sx={CommonFontStyle}>
                          We, the abovementioned{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </span>{" "}
                          and the{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {StoreOrderData?.escrowData?.sellerEaaaId}
                          </span>
                          , in the transaction referred to above, hereby state
                          and certify the following:
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          1. That the Parties Agreement <b> (“Agreement”)</b>{" "}
                          was entered between the{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </span>{" "}
                          and{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </span>
                          to _(Services/business/lending etc.) for provision of
                          goods in the above Escrow Account{" "}
                          <b>{StoreOrderData?.escrowData?.sellerEaaaId}</b>.
                          That as per the Escrow Account Administration
                          Agreement <b>“EAAA”</b>, BXI is authorized by the{" "}
                          <b>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </b>{" "}
                          and
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }
                          </b>{" "}
                          to do various acts, including but not limited to,
                          signing this Release Letter, authorizing Escrow pay to
                          remit the amounts to the respective parties. Pursuant
                          to the said Agreement, the funds were deposited by the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </b>{" "}
                          {500} in the above Escrow Account.
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleTen}>
                        <Typography sx={CommonFontStyle}>
                          2. The{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>{" "}
                          confirms that all payouts to the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          from the Escrow Account shall be made to the following
                          Bank Account of the{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }
                          </b>
                          :
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleEleven}>
                        <Typography textAlign="center" sx={CommonFontStyle}>
                          <b>
                            Name of the Bank
                            <br />
                            {StoreOrderData?.BankDetails?.IFSC.slice(0, 4)}
                          </b>
                        </Typography>
                        <Typography text-align="center" sx={CommonFontStyle} s>
                          <b>
                            Account No
                            <br />
                            <span style={{ borderTop: "1px solid black" }}>
                              {StoreOrderData?.BankDetails?.AccountNumber}
                            </span>
                          </b>
                        </Typography>
                        <Typography text-align="center" sx={CommonFontStyle}>
                          <b>
                            IFSC Code
                            <br />
                            <span style={{ borderTop: "1px solid black" }}>
                              {StoreOrderData?.BankDetails?.IFSC}
                            </span>
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        class="box"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography sx={CommonFontStyle}>
                          The{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>{" "}
                          confirms that the above information is true and
                          correct and in case of lapses in submitting the
                          details, Escrow pay shall be at liberty to close
                          escrow account/Virtual collection account(s) of the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          , with a prior notice of 7 days. The BXI further
                          undertakes to inform Escrow pay of any changes to the
                          above, within 7 days of the change occurring and to
                          submit revised details
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          3. That the{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          hereby irrevocably consent, authorize and instruct
                          Escrow pay, as a onetime release instruction, to remit
                          any or all Escrow-Funds to the KYC verified Bank
                          account of respective parties, as per the Agreement.
                        </Typography>

                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  sx={{ ...CommonFontStyle, fontSize: "13px" }}
                                >
                                  Payment Head
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  sx={{ ...CommonFontStyle, fontSize: "13px" }}
                                >
                                  Amount to be Paid (INR)
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  sx={{ ...CommonFontStyle, fontSize: "12px" }}
                                >
                                  Payment to Seller
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  sx={{ ...CommonFontStyle, fontSize: "12px" }}
                                >
                                  {StoreOrderData?.INRDetails?.TotalInrToPay?.toFixed(
                                    2
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Typography sx={CommonFontStyle}>
                          4. That the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          hereby certify and confirm that this Release Letter is
                          being executed by the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          , as per the terms of the EAAA and/ Master Agreement ,
                          without any coercion or undue influence. Further, the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          confirm that post remittance of the above payments,
                          the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }{" "}
                          </b>
                          waive off any and all claims on the Escrow-Funds.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box class="signatures" style={StyleTwelve}>
                    <Box class="company-signature">
                      <b>BXI </b>Signature and Stamp
                    </Box>
                  </Box>
                  <Box class="place-date" style={{ marginTop: "20px" }}>
                    <b>
                      Place: Mumbai
                      <br />
                      Date: {new Date().toDateString()}
                    </b>
                  </Box>
                  <Box class="grid" style={StyleThirteen}>
                    <Box class="signature-container" style={StyleFourteen}>
                      <img
                        src={VectorArrow}
                        alt="Arrow Image"
                        style={StyleFifteen}
                      />

                      <img
                        src={Signviral}
                        alt="Signature Image"
                        style={{
                          position: "absolute",
                          height: "180px",
                          width: "auto",
                          right: "5%",
                          top: "-40%",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </div>
              <div ref={OrderSummaryRef}>
                {StoreOrderData?.IsMedia ? (
                  <MediaOrderSummary MediaSummaryData={StoreOrderData} />
                ) : null}
                {StoreOrderData?.IsVoucher ? (
                  <VoucherOrderSummary VoucherSummaryData={StoreOrderData} />
                ) : null}
                {!StoreOrderData?.IsVoucher && !StoreOrderData?.IsMedia ? (
                  <ProductOrderSummary
                    ProductSummaryData={StoreOrderData}
                    //  CompanyData={}
                  />
                ) : null}
              </div>
            </Box>
            <Box
              sx={{
                bgcolor: "gray",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
              }}
            >
              {IsInrRefund ? (
                <Box>
                  {RefundUploadLoading ? (
                    <Button
                      sx={{
                        margin: "0 auto",
                        width: "250px",
                        height: "50px",
                        color: "white",
                        background: "var(--Green-2, #27AE60)",
                        fontWeight: 600,
                        textTransform: "none",
                        fontSize: "16px",
                        ":hover": {
                          background: "var(--Green-2, #27AE60)",
                        },
                      }}
                    >
                      Uploading
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        margin: "0 auto",
                        width: "250px",
                        height: "50px",
                        color: "white",
                        background: "var(--Green-2, #27AE60)",
                        fontWeight: 600,
                        textTransform: "none",
                        fontSize: "16px",
                        ":hover": {
                          background: "var(--Green-2, #27AE60)",
                        },
                      }}
                      onClick={() => {
                        upload_doc_refund_escrow();
                      }}
                    >
                      Make Inr Refund
                    </Button>
                  )}
                </Box>
              ) : (
                <Box>
                  {ReleaseUploadLoading ? (
                    <Button
                      sx={{
                        margin: "0 auto",
                        width: "250px",
                        height: "50px",
                        color: "white",
                        background: "var(--Green-2, #27AE60)",
                        fontWeight: 600,
                        textTransform: "none",
                        fontSize: "16px",
                        ":hover": {
                          background: "var(--Green-2, #27AE60)",
                        },
                      }}
                    >
                      Uploading
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        margin: "0 auto",
                        width: "250px",
                        height: "50px",
                        color: "white",
                        background: "var(--Green-2, #27AE60)",
                        fontWeight: 600,
                        textTransform: "none",
                        fontSize: "16px",
                        ":hover": {
                          background: "var(--Green-2, #27AE60)",
                        },
                      }}
                      // onClick={}
                      onClick={() => {
                        MakeEscrow_ReleaseFunds(StoreOrderData?._id);
                      }}
                    >
                      Release Fund
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Paper>
        </Modal>
        <Modal
          open={releaseBXIModalOpen}
          onClose={() => setReleaseBXIModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{
              height: "100%",
              maxHeight: "90vh",
              width: "100%",
              maxWidth: "50vw",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
              position: "absolute",
              overflow: "scroll",
              bgcolor: "white",
            }}
          >
            <Box
              sx={{
                margin: "0 auto",
                height: "88%",
                overflowY: "scroll",
                width: "98%",
                maxWidth: "48vw",
                mt: "10px",
              }}
            >
              <div ref={componentRef} id="content-to-convert">
                <Box style={StyleOne}>
                  <Box style={StyleTwo}>
                    <Box component={"img"} src={BXILogo} style={StyleThree} />
                    <Box
                      component={"img"}
                      src={VectorArrow}
                      style={StyleFour}
                    />
                  </Box>
                  <Box class="paper" style={StyleFive}>
                    <Box class="box" style={StyleSix}>
                      <Box class="box" style={StyleSeven}>
                        <Typography sx={CommonFontStyle}>
                          To Escrow Pay
                          <br />
                          Through{" "}
                          <b>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </b>
                          <br />
                          Trust more Technologies (P) Limited
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          Sub: Release Letter/Instructions to release “Escrow
                          Funds” from the Escrow Account Ref{" "}
                          <span>{`EPUGBXIAAFCK0769E`}</span> : Escrow Account
                          No.{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {StoreOrderData?.escrowData?.buyerEaaaId}{" "}
                          </span>{" "}
                          and
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleEight}>
                        <Typography sx={CommonFontStyle}>
                          Relationship as per Agreement
                        </Typography>
                        <Typography
                          style={{ ...CommonFontStyle, whiteSpace: "nowrap" }}
                        >
                          Name of the Party <br />{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </span>
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          Relationship as per Agreement
                        </Typography>
                        <Typography
                          style={{ ...CommonFontStyle, whiteSpace: "nowrap" }}
                        >
                          Name of the Party <br />{" "}
                          <span style={{ fontWeight: "bold" }}>
                            <b>Barter Exchange of India</b>
                          </span>
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleNine}>
                        <Typography sx={CommonFontStyle}>
                          We, the abovementioned{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </span>{" "}
                          and the{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {StoreOrderData?.escrowData?.sellerEaaaId}
                          </span>
                          , in the transaction referred to above, hereby state
                          and certify the following:
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          1. That the Parties Agreement <b> (“Agreement”)</b>{" "}
                          was entered between the{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </span>{" "}
                          and{" "}
                          <span style={{ fontWeight: "bold" }}>
                            <b>Barter Exchange of India</b>
                          </span>
                          to _(Services/business/lending etc.) for provision of
                          goods in the above Escrow Account{" "}
                          <b>{StoreOrderData?.escrowData?.sellerEaaaId}</b>.
                          That as per the Escrow Account Administration
                          Agreement <b>“EAAA”</b>, BXI is authorized by the{" "}
                          <b>
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </b>{" "}
                          and
                          <b> Barter Exchange of India</b> to do various acts,
                          including but not limited to, signing this Release
                          Letter, authorizing Escrow pay to remit the amounts to
                          the respective parties. Pursuant to the said
                          Agreement, the funds were deposited by the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </b>{" "}
                          {500} in the above Escrow Account.
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleTen}>
                        <Typography sx={CommonFontStyle}>
                          2. The{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>{" "}
                          confirms that all payouts to the
                          <b>Barter Exchange of India</b>
                          from the Escrow Account shall be made to the following
                          Bank Account of the{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </b>
                          :
                        </Typography>
                      </Box>
                      <Box class="box" style={StyleEleven}>
                        <Typography textAlign="center" sx={CommonFontStyle}>
                          <b>
                            Name of the Bank
                            <br />
                            {StoreOrderData?.BankDetails?.IFSC.slice(0, 4)}
                          </b>
                        </Typography>
                        <Typography text-align="center" sx={CommonFontStyle} s>
                          <b>
                            Account No
                            <br />
                            <span style={{ borderTop: "1px solid black" }}>
                              {StoreOrderData?.BankDetails?.AccountNumber}
                            </span>
                          </b>
                        </Typography>
                        <Typography text-align="center" sx={CommonFontStyle}>
                          <b>
                            IFSC Code
                            <br />
                            <span style={{ borderTop: "1px solid black" }}>
                              {StoreOrderData?.BankDetails?.IFSC}
                            </span>
                          </b>
                        </Typography>
                      </Box>
                      <Box
                        class="box"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography sx={CommonFontStyle}>
                          The{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>{" "}
                          confirms that the above information is true and
                          correct and in case of lapses in submitting the
                          details, Escrow pay shall be at liberty to close
                          escrow account/Virtual collection account(s) of the
                          <b>Barter Exchange of India</b>, with a prior notice
                          of 7 days. The BXI further undertakes to inform Escrow
                          pay of any changes to the above, within 7 days of the
                          change occurring and to submit revised details
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          3. That the{" "}
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>Barter Exchange of India</b>
                          hereby irrevocably consent, authorize and instruct
                          Escrow pay, as a onetime release instruction, to remit
                          any or all Escrow-Funds to the KYC verified Bank
                          account of respective parties, as per the Agreement.
                        </Typography>
                        <Typography sx={CommonFontStyle}>
                          4. That the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>Barter Exchange of India</b>
                          hereby certify and confirm that this Release Letter is
                          being executed by the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>Barter Exchange of India</b>, as per the terms of
                          the EAAA and/ Master Agreement , without any coercion
                          or undue influence. Further, the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the
                          <b>Barter Exchange of India</b>
                          confirm that post remittance of the above payments,
                          the
                          <b>
                            {" "}
                            {
                              StoreOrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }{" "}
                          </b>
                          and the <b>Barter Exchange of India</b>
                          waive off any and all claims on the Escrow-Funds.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box class="signatures" style={StyleTwelve}>
                    <Box class="company-signature">
                      <b>Buyer </b>Signature and Stamp
                      <br />
                      <span style={{ borderTop: "1px solid black" }}>
                        <b>
                          {
                            StoreOrderData?.PurchaseOrderData?.BuyerDetails
                              ?.BuyerCompanyName
                          }
                        </b>
                      </span>
                    </Box>
                    <Box class="party-signature">
                      <b>Barter Exchange of India</b>
                      <br />
                    </Box>
                  </Box>
                  <Box class="place-date" style={{ marginTop: "20px" }}>
                    <b>
                      Place: Mumbai
                      <br />
                      Date: {new Date().toDateString()}
                    </b>
                  </Box>
                  <Box class="grid" style={StyleThirteen}>
                    <Box class="signature-container" style={StyleFourteen}>
                      <img
                        src={VectorArrow}
                        alt="Arrow Image"
                        style={StyleFifteen}
                      />

                      <img
                        src={Signviral}
                        alt="Signature Image"
                        style={{
                          position: "absolute",
                          height: "180px",
                          width: "auto",
                          right: "5%",
                          top: "-40%",
                        }}
                      />
                      <Box
                        class="company-info"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          class="company-name"
                          style={{ fontWeight: "bold" }}
                        >
                          <b>BXI World LLP</b>
                        </span>
                        <span
                          class="address"
                          style={{ textAlign: "center", fontSize: "small" }}
                        >
                          501-5th Floor, Meadows Tower, Sahar Plaza Complex, Sir
                          M.V Road, Andheri (East), Mumbai - 400059
                        </span>
                        <span
                          class="contact-info"
                          style={{ textAlign: "center", fontSize: "small" }}
                        >
                          <b>Website: </b> www.bxiworld.com |<b> Email:</b>
                          business@bxiworld.com |<b> Contact: </b>022 49646776
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
            <Box
              sx={{
                bgcolor: "gray",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
              }}
            >
              <Button
                sx={{
                  margin: "0 auto",
                  width: "250px",
                  height: "50px",
                  color: "white",
                  background: "var(--Green-2, #27AE60)",
                  fontWeight: 600,
                  textTransform: "none",
                  fontSize: "16px",
                  ":hover": {
                    background: "var(--Green-2, #27AE60)",
                  },
                }}
                // onClick={generatePdf}
                onClick={() => {
                  MakeEscrow_BXI_Release(StoreOrderData);
                }}
              >
                Release BXI Fee
              </Button>
            </Box>
          </Paper>
        </Modal>
      </Box>
    </Paper>
  );
};

export default ReleaseView;

const CommonFontStyle = {
  fontFamily: "Poppins",
  fontSize: "10px",
};

const StyleOne = {
  maxWidth: "768px",
  margin: "0 auto",
  padding: "16px",
  border: "1px solid #ccc",
  fontFamily: "Poppins",
  fontSize: "small",
};

const StyleTwo = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "150px",
};

const StyleThree = { height: "100px", width: "auto" };

const StyleFour = {
  transform: "rotate(180deg)",
  height: "170px",
  opacity: "0.4",
  width: "auto",
  marginRight: "-20px",
};

const StyleFive = {
  elevation: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: "10px",
};

const StyleSix = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "80%",
};

const StyleSeven = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const StyleEight = { display: "flex", flexDirection: "row", gap: "5%" };

const StyleNine = {
  display: "flex",
  flexDirection: "column",
  gap: "10%",
};

const StyleTen = { display: "flex", flexDirection: "row", gap: "10%" };

const StyleEleven = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "10%",
};

const StyleTwelve = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  marginTop: "20px",
};

const StyleThirteen = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "space-between",
  width: "100%",
  marginTop: "5%",
};

const StyleFourteen = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  width: "100%",
  position: "relative",
};

const StyleFifteen = {
  height: "170px",
  width: "auto",
  opacity: "0.4",
  marginLeft: "-20px",
};
