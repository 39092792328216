import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";

import * as Styles from "../common/Styles";
import useGetPromotionalEmails from "../Hooks/Emails/useGetPromotionalEmails";
import axios from "axios";

const Communications = () => {
  const { data, error, isLoading } = useGetPromotionalEmails();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [submittedTemplate, setSubmittedTemplate] = useState("");
  const [StoreError, setStoreError] = useState("");

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleSubmit = async () => {
    await setSubmittedTemplate(selectedTemplate); // Wait for state update
    await axios
      .post("email_admin/send", {
        template: selectedTemplate,
      })
      .then((response) => {})
      .catch((error) => {
        setStoreError(error?.response?.data?.error);
      });
  };

  const handleSendWhatsApp = async () => {
    await axios
      .post("WhatApp/BulkSendTempletMessageOnWhatApp", {
        template: "summer_room_sales",
      })
      .then((response) => {})
      .catch((error) => {
        setStoreError(error?.response?.data?.error);
      });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Box>
          <Typography sx={Styles.chatText}>Communication</Typography>
          <Typography sx={Styles.chatTextSub}>
            Manage Email & WhatsApp communication to be sent to Members.
          </Typography>
        </Box>
        {/* AddEmail component here */}
      </Box>

      <Box sx={{ p: 0.2, background: "#FAFBFC" }}>
        <Box
          sx={{
            backgroundColor: "white",
            flexShrink: 0,
            height: "330px",
            border: "1.5px solid #E6E9EE",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            p: 5,
            mt: 2,
            width: "95%",
            mx: "auto",
          }}
        >
          <Box sx={{ p: 2, width: "500px", mx: "auto" }}>
            <Typography sx={TextStyles}>
              Individual Promotional Emails
            </Typography>
            <Typography sx={TextStyles1}>
              Select promotional template from below and send it to particular
              BXI Members.
            </Typography>
            <Typography sx={TextStyles2}>Choose Template</Typography>
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              {/* <InputLabel htmlFor="email-template">
            Select Email Template
          </InputLabel> */}
              <Select
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "5px",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "1px 1px 5px 1px #ccc",
                  },
                }}
                labelId="email-template"
                id="email-template"
                value={selectedTemplate}
                onChange={handleTemplateChange}
              >
                {data?.map((item) => (
                  <MenuItem
                    key={item.template}
                    value={item.template}
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "4px",
                      padding: "5px",
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "red",
                        boxShadow: "1px 1px 5px 1px #ccc",
                      },
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography sx={{ ...TextStyles, fontSize: "12px" }}>
              Selected Template: {selectedTemplate}
            </Typography>
            {StoreError && (
              <Typography sx={ErrorStyle}>Error: {StoreError}</Typography>
            )}
            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={ButtonStyle}
              >
                Send Email
              </Button>
            </Box>
          </Box>

          <Box sx={{ p: 2, width: "500px", mx: "auto" }}>
            <Typography sx={TextStyles}>Bulk Promotional Emails</Typography>
            <Typography sx={TextStyles1}>
              Select promotional template from below and broadcast it to any BXI
              Members.
            </Typography>
            <Typography sx={TextStyles2}>Choose Template</Typography>
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              {/* <InputLabel htmlFor="email-template">
          Select Email Template
        </InputLabel> */}
              <Select
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "5px",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "1px 1px 5px 1px #ccc",
                  },
                }}
                labelId="email-template"
                id="email-template"
                value={selectedTemplate}
                onChange={handleTemplateChange}
              >
                {data?.map((item) => (
                  <MenuItem
                    key={item.template}
                    value={item.template}
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "4px",
                      padding: "5px",
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "red",
                        boxShadow: "1px 1px 5px 1px #ccc",
                      },
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography sx={{ ...TextStyles, fontSize: "12px" }}>
              Selected Template: {selectedTemplate}
            </Typography>
            {StoreError && (
              <Typography sx={ErrorStyle}>Error: {StoreError}</Typography>
            )}
            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={ButtonStyle}
              >
                Send Email
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ background: "#FAFBFC", p: 0.2 }}>
        <Box
          sx={{
            backgroundColor: "white",
            height: "330px",
            flexShrink: 0,
            border: "1.5px solid #E6E9EE",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            p: 5,
            mt: 2,
            width: "95%",
            mx: "auto",
          }}
        >
          <Box sx={{ width: "500px", mx: "auto", p: 2 }}>
            <Typography sx={TextStyles}>
              Individual WhatsApp Message{" "}
            </Typography>
            <Typography sx={TextStyles1}>
              Select WhatsApp Message from below and send it to particular BXI
              Members.
            </Typography>
            <Typography sx={TextStyles2}>Choose Template</Typography>
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              {/* <InputLabel htmlFor="email-template">
            Select Email Template
          </InputLabel> */}
              <Select
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "5px",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "1px 1px 5px 1px #ccc",
                  },
                }}
                defaultValue={"Summer Room Sales"}
                labelId="email-template"
                id="email-template"
              >
                <MenuItem
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                    padding: "5px",
                    "&:hover": {
                      cursor: "pointer",
                      bgcolor: "red",
                      boxShadow: "1px 1px 5px 1px #ccc",
                    },
                  }}
                  value={"Summer Room Sales"}
                >
                  Summer Room Sales
                </MenuItem>
              </Select>
            </FormControl>
            <Typography sx={{ ...TextStyles, fontSize: "12px" }}>
              Selected Template: {selectedTemplate}
            </Typography>
            {StoreError && (
              <Typography sx={ErrorStyle}>Error: {StoreError}</Typography>
            )}
            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={handleSendWhatsApp}
                sx={{ ...ButtonStyle }}
              >
                Send Message
              </Button>
            </Box>
          </Box>

          <Box sx={{ width: "500px", mx: "auto", p: 2 }}>
            <Typography sx={TextStyles}>Bulk WhatsApp Message </Typography>
            <Typography sx={TextStyles1}>
              Select WhatsApp Message from below and broadcast it to any BXI
              Members.
            </Typography>
            <Typography sx={TextStyles2}>Choose Template</Typography>
            <FormControl sx={{ width: "100%", mt: "10px" }}>
              {/* <InputLabel htmlFor="email-template">
          Select Email Template
        </InputLabel> */}
              <Select
                style={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "5px",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "1px 1px 5px 1px #ccc",
                  },
                }}
                labelId="email-template"
                id="email-template"
              >
                <MenuItem
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                    padding: "5px",
                    "&:hover": {
                      cursor: "pointer",
                      bgcolor: "red",
                      boxShadow: "1px 1px 5px 1px #ccc",
                    },
                  }}
                >
                  Summer Room Sales
                </MenuItem>
              </Select>
            </FormControl>
            <Typography sx={{ ...TextStyles, fontSize: "12px" }}>
              Selected Template: {selectedTemplate}
            </Typography>
            {StoreError && (
              <Typography sx={ErrorStyle}>Error: {StoreError}</Typography>
            )}
            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={ButtonStyle}
              >
                Send Message
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* {submittedTemplate && (
        <Box sx={{ p: 2 }}>
          <Typography>Selected Email Template: {submittedTemplate}</Typography>
        </Box>
      )} */}
    </Paper>
  );
};

export default Communications;

const ErrorStyle = {
  color: "red",
  fontSize: "12px",
  fontWeight: "bold",
  mt: "5px",
};

const ButtonStyle = {
  width: "175px",
  height: "40px",
  border: "none",
  marginLeft: "-17px",
  borderRadius: 1,
  fontFamily: "Poppins",
  backgroundColor: "#445FD2",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "1px 1px 5px 1px #ccc",
  },
  textTransform: "none",
};

const TextStyles = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
};

const TextStyles1 = {
  color: "#ACACAC",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

const TextStyles2 = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  marginTop: "10px",
};
