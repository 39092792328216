import React, { useEffect, useState } from "react";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeriesCanvas,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import "/node_modules/react-vis/dist/style.css";
import { Box } from "@material-ui/core";

const barRadius = 15; // Border radius for the top of the bars

const getBarStyle = (bar) => ({
  borderRadius: `${barRadius}px ${barRadius}px 0 0`, // Apply rounded corners only at the top
});

const CustomDiscreteColorLegend = ({ items }) => {
  return (
    <div
      style={{
        margin: "10px 0",
        display: "flex",
        width: "700",
        justifyContent: "space-around",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: item.color,
              marginRight: "5px",
              borderRadius: "50%",
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

const Ordersfullfilled = () => {
  const BarSeries = VerticalBarSeries;

  const [hoveredBar, setHoveredBar] = useState(null);
  const [label, setLabel] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [isLoading, setIsLoading] = useState(true);
  const [optionFilter, setOptionFilter] = useState("Sellar");
  const [ProgressData, setProgressData] = useState([]);
  const [ReadyToShipData, setReadyToShipData] = useState([]);
  const [InTransitData, setInTransitData] = useState([]);
  const [DeliveredData, setDeliveredData] = useState([]);
  const [SellerDeliveredData, setSellerDeliveredData] = useState([]);
  const [ReturnData, setReturnData] = useState([]);
  const [GraphLabel, setGraphLabel] = useState("ReadyToShip");

  const handleBarHover = (value) => {
    setHoveredBar(value);

    if (value?.color == "#46C2CB") setGraphLabel("Progress");
    if (value?.color == "#D2691E") setGraphLabel("ReadyToShip");
    if (value?.color == "#4169E1") setGraphLabel("InTransit");
    if (value?.color == "#FFA500") setGraphLabel("Delivered");
    if (value?.color == "#712B75") setGraphLabel("SelllerDelivered");
    if (value?.color == "#90EE90") setGraphLabel("Return");
  };

  const legends = [
    { title: "Progress", color: "#46C2CB" },
    { title: "ReadyToShip", color: "#D2691E" },
    { title: "InTransit", color: "#4169E1" },
    { title: "Delivered", color: "#FFA500" },
    { title: "SellerDelivered", color: "#712B75" },
    { title: "Return", color: "#90EE90" },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    let apiUrl = "";

    // Sellar Delivery
    if (optionFilter == "Sellar" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransactions";
    if (optionFilter == "Sellar" && filter == "last-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransactionsMonth";
    if (optionFilter == "Sellar" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransactionsCurrentMonth";
    if (optionFilter == "Sellar" && filter == "last-three-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransaction3Month";
    if (optionFilter == "Sellar" && filter == "last-six-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransaction6Month";
    if (optionFilter == "Sellar" && filter == "last-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransaction12Month";
    if (optionFilter == "Sellar" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransactionCurrent12Month";
    if (optionFilter == "Sellar" && filter == "last-five-years")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticGraphTransaction5Years";

    // Pickrr Delivery
    if (optionFilter == "notransportation" && filter == "last-week")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransaction";
    if (optionFilter == "notransportation" && filter == "last-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransactionsMonth";
    if (optionFilter == "notransportation" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransactionsCurrentMonth";
    if (optionFilter == "notransportation" && filter == "last-three-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransaction3Month";
    if (optionFilter == "notransportation" && filter == "last-six-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransaction6Month";
    if (optionFilter == "notransportation" && filter == "last-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransaction12Month";
    if (optionFilter == "notransportation" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransactionCurrent12Month";
    if (optionFilter == "notransportation" && filter == "last-five-years")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/notransportationLogisticGraphTransaction5Years";

    try {
      const res = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });

      setProgressData(res.data.ProgressData);
      setReadyToShipData(res.data.ReadyToShipData);
      setInTransitData(res.data.InTransitData);
      setDeliveredData(res.data.DeliveredData);
      setSellerDeliveredData(res.data.SellerDeliveredData);
      setReturnData(res.data.ReturnData);
      setLabel(res.data.label);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter, optionFilter]);

  const ProgressDataArray = ProgressData?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#46C2CB",
  }));

  const ReadyToShipDataArray = ReadyToShipData?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#D2691E",
  }));

  const InTransitDataArray = InTransitData?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#4169E1",
  }));
  const DeliveredDataArray = DeliveredData?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#FFA500",
  }));
  const SellerDeliveredDataArray = SellerDeliveredData?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#712B75",
  }));
  const ReturnDataArray = ReturnData?.map((item, index) => ({
    x: index + 1,
    y: parseInt(item),
    color: "#90EE90",
  }));

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">Logistic Delivery Status</h3>
        <Box
          className="marketplace-filters"
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setOptionFilter(e.target.value);
              }}
              value={optionFilter}
            >
              <option value="Sellar">Seller Delivery</option>
              <option value="notransportation">Buyer-Self Pickup</option>
            </select>
          </Box>
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XYPlot
            height={350}
            width={700}
            stackBy="y"
            margin={{ left: 70, right: 10, top: 10, bottom: 30 }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              tickValues={label.map((item, index) => index + 1)}
              tickFormat={(v) => label[v - 1]}
            />

            <YAxis tickFormat={(v) => `${v}`} />

            <BarSeries
              barWidth={0.2}
              data={ProgressDataArray}
              color={"#46C2CB"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            <BarSeries
              barWidth={0.2}
              data={ReadyToShipDataArray}
              color={"#D2691E"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            <BarSeries
              data={InTransitDataArray}
              color={"#4169E1"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
              barWidth={0.2}
            />

            <BarSeries
              data={DeliveredDataArray}
              color={"#FFA500"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
              barWidth={0.2}
            />

            <BarSeries
              data={SellerDeliveredDataArray}
              color={"#712B75"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
              barWidth={0.2}
            />

            <BarSeries
              data={ReturnDataArray}
              color={"#90EE90"}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
              barWidth={0.2}
            />

            {hoveredBar && (
              <Hint value={hoveredBar}>
                <div
                  className="grapharrow"
                  style={{
                    background: `${hoveredBar.color}`,
                    color: "white",
                    width: "auto",
                  }}
                >
                  <p>{hoveredBar.label}</p>
                  <p>{`${GraphLabel}: ${
                    hoveredBar.y - (hoveredBar.y0 || 0)
                  }`}</p>
                </div>
              </Hint>
            )}
            <CustomDiscreteColorLegend items={legends} />
          </XYPlot>
        </div>
      )}
    </div>
  );
};

export default Ordersfullfilled;
