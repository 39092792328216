import {
  Box,
  Button,
  Input,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete, Edit, Close } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function Tds_gst() {
  const [value, setValue] = useState("");
  const [valueForGst, setValueForGst] = useState("");
  const [error, setError] = useState("");
  const [errorforGst, setErrorforGst] = useState("");
  const [storeTDS, setStoreTDS] = useState("");
  const [storeUpdatedTDS, setStoreUpdatedTDS] = useState("");
  const [storeGST, setStoreGST] = useState();
  const [UpdateGSTId, setUpdateGSTId] = useState("");
  const [UpdateGSTflag, setUpdateGSTflag] = useState(false);

  const handleInputChangeForTDS = (e, flag) => {
    const inputValue = e.target.value;
    const isValidInteger = (value) => {
      const num = Number(value);
      return Number.isInteger(num) && !isNaN(num);
    };
    if (flag) {
      inputValue === "0" ? setError("Cannot enter 0") : setError("");
      setStoreTDS(inputValue);
      setValue(inputValue);
    } else {
      const numericValue = Number(inputValue);
      if (!isValidInteger(inputValue)) {
        setErrorforGst("Please enter a valid integer");
      } else if (numericValue > 100) {
        setErrorforGst("Cannot enter more than 100");
      } else {
        setErrorforGst("");
        setValueForGst(inputValue);
      }
    }
  };

  const handleKeyPressForTDS = (e, flag) => {
    if (e.key === "-" || e.key === "e" || e.key === "E") e.preventDefault();
    (flag && e.key === "-") || e.key === "+"
      ? setError("Please enter a valid integer")
      : setError("");
  };

  const Update_TDS = async () => {
    try {
      const res = await axios.post(`Update_TDS_GST/update_tds`, {
        tds: storeTDS,
      });
      if (res?.data?.updatedTDS?.TDS) {
        toast.success(`TDS update to ${res?.data?.updatedTDS?.TDS}%`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const Add_GST = async () => {
    try {
      const res = await axios.post(`Update_TDS_GST/add_gst`, {
        gst: valueForGst,
      });
      if (res?.data?.data?.GST) {
        toast.success(`${res?.data?.data?.GST}% GST Added successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
        setValueForGst("");
        setUpdateGSTflag(false);
        await fetch_GST();
      }
    } catch (error) {
      if (error?.response?.data?.message?.includes("exists")) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    }
  };

  const Update_GST = async () => {
    try {
      const res = await axios.post(
        `Update_TDS_GST/update_gst_by_id/${UpdateGSTId}`,
        {
          gst: valueForGst,
        }
      );
      if (res?.data?.data?.GST) {
        toast.success(`GST update to ${res?.data?.data?.GST}%`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
        await fetch_GST();
        setUpdateGSTflag(false);
        setValueForGst("");
      }
    } catch (error) {
      if (error?.response?.data?.message?.includes("exists")) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    }
  };

  const Delete_GST = async (id) => {
    try {
      let alert = window.confirm("Are you sure you want to delete this GST?");
      if (alert) {
        const res = await axios.post(`Update_TDS_GST/delete_gst_by_id/${id}`);
        if (res?.data?.data?.GST) {
          toast.success(`GST Deleted`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
          await fetch_GST();
          setUpdateGSTflag(false);
        }
      }
    } catch (error) {}
  };

  const fetch_GST = async () => {
    try {
      const response = await axios.get(`Update_TDS_GST/get_all_gst`);
      setStoreGST(response?.data?.data);
    } catch (error) {}
  };
  const fetch_tds = async () => {
    try {
      const response = await axios.get(`Update_TDS_GST/get_admin_tds`);
      setStoreUpdatedTDS(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetch_tds();
    fetch_GST();
  }, []);

  const handlePaste = (e) => e.preventDefault();

  return (
    <Box sx={mainBox}>
      <Box sx={sectionBox}>
        <Typography sx={sectionTitle}>TDS</Typography>
        <Input
          sx={inputField}
          disableUnderline
          placeholder="Enter TDS Amount"
          type="number"
          value={value}
          onChange={(e) => handleInputChangeForTDS(e, true)}
          onKeyDown={(e) => handleKeyPressForTDS(e, true)}
          inputProps={{ min: 0.1 }}
          onPaste={handlePaste}
        />
        {error && <Typography sx={errorText}>{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          sx={actionButton}
          onClick={Update_TDS}
        >
          Update TDS
        </Button>
        <Divider sx={dividerStyle} />
        <Box sx={listStyle}>
          <Box sx={listItem}>
            <Typography>TDS: {storeUpdatedTDS?.TDSData?.TDS}%</Typography>
            <Typography>
              Last Update On:{" "}
              {new Date(storeUpdatedTDS?.TDSData?.updatedAt).toDateString()} at{" "}
              {new Date(
                storeUpdatedTDS?.TDSData?.updatedAt
              ).toLocaleTimeString()}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem sx={dividerStyle} />

      <Box sx={sectionBox}>
        <Typography sx={sectionTitle}>
          {!UpdateGSTflag ? "GST" : "Update GST"}
          {UpdateGSTflag ? (
            <IconButton edge="end" color="inherit" aria-label="close">
              <Close
                onClick={() => {
                  setUpdateGSTflag(false);
                  setValueForGst("");
                }}
              />
            </IconButton>
          ) : null}
        </Typography>{" "}
        <Input
          sx={inputField}
          disableUnderline
          placeholder="Enter GST Amount"
          type="number"
          value={valueForGst}
          onChange={(e) => handleInputChangeForTDS(e, false)}
          onKeyDown={(e) => handleKeyPressForTDS(e, false)}
          inputProps={{ min: 0 }}
          onPaste={handlePaste}
        />
        {errorforGst && <Typography sx={errorText}>{errorforGst}</Typography>}
        <Button
          variant="contained"
          color="primary"
          sx={actionButton}
          onClick={() => {
            if (!UpdateGSTflag) {
              Add_GST();
            } else {
              Update_GST();
            }
          }}
        >
          {!UpdateGSTflag ? "Add GST" : "Update GST"}
        </Button>
        <Divider sx={dividerStyle} />
        <Box sx={listStyle}>
          {storeGST?.map((data, ind) => (
            <Box key={ind} sx={listItem}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                {ind + 1}. GST: {data?.GST}%
                <IconButton
                  aria-label="delete"
                  style={{ color: "rgba(68, 95, 210, 1)" }}
                >
                  <Delete
                    onClick={() => {
                      Delete_GST(data?._id);
                    }}
                  />
                </IconButton>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Last Created: {new Date(data?.createdAt).toDateString()} at{" "}
                {new Date(data?.createdAt).toLocaleTimeString()}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const mainBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "row",
  height: "auto",

  width: "100%",
  padding: "20px",
  backgroundColor: "#f9f9f9",
  gap: "20px",
};

const sectionBox = {
  width: "40%",
  backgroundColor: "#fff",
  borderRadius: "12px",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

const sectionTitle = {
  fontSize: "24px",
  fontWeight: "600",
  fontFamily: "Poppins",
  color: "#333",
  textAlign: "center",
};

const inputField = {
  borderRadius: "8px",
  padding: "10px",
  width: "100%",
  backgroundColor: "#f0f0f0",
  fontFamily: "Poppins",
  fontSize: "14px",
};

const actionButton = {
  textTransform: "none",
  width: "100%",
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "#1976d2",
  ":hover": {
    backgroundColor: "#1565c0",
  },
};

const errorText = {
  color: "red",
  fontSize: "12px",
  textAlign: "center",
  marginTop: "-10px",
};

const listStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const listItem = {
  display: "grid",
  gap: "10px",
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "#555",
};

const dividerStyle = {
  backgroundColor: "#e0e0e0",
  margin: "20px 0",
};
